import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const StyledTab = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: ${({ counter }) => (counter ? '0 12px' : '0 16px')};
  margin: 0;
  border: none;
  background: none;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme, isLight }) =>
    isLight
      ? rgba(theme.colors.blackText, 0.75)
      : rgba(theme.colors.secondary, 0.75)};
  transition: background-color 0.16s linear, color 0.16s linear;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme, isLight }) =>
        isLight
          ? rgba(theme.colors.blackText, 0.05)
          : rgba(theme.colors.secondary, 0.1)};
      color: ${({ theme, isLight }) =>
        isLight ? theme.colors.blackText : theme.colors.secondary};
    `}

  ${({ iconTab }) =>
    iconTab &&
    css`
      padding: 0;
      width: 32px;
    `}

  @media(hover:hover) {
    &:hover {
      color: ${({ theme, isLight }) =>
        isLight ? theme.colors.blackText : theme.colors.secondary};
    }

    &:active,
    &:focus {
      color: ${({ theme, isLight }) =>
        isLight
          ? rgba(theme.colors.blackText, 0.75)
          : rgba(theme.colors.secondary, 0.75)};
    }

    &:disabled {
      color: ${({ theme, isLight }) =>
        isLight
          ? rgba(theme.colors.blackText, 0.25)
          : rgba(theme.colors.secondary, 0.25)};
      cursor: default;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
`

const Counter = styled.span`
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  line-height: 14px;
  font-size: 10px;
  margin-left: 8px;
  padding: 0 4px;
  border: 1px solid ${({ theme }) => theme.colors.red};

  button:disabled > & {
    background-color: transparent;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
  }
`

export const Tab = ({
  label,
  isActive,
  counter,
  disabled,
  onClick,
  ariaLabel,
  iconTab,
  isLight,
  ...rest
}) => (
  <StyledTab
    isActive={isActive}
    onClick={onClick}
    counter={counter}
    disabled={disabled}
    ariaLabel={ariaLabel}
    iconTab={iconTab}
    isLight={isLight}
    {...rest}
  >
    {label}
    {counter && <Counter>{counter}</Counter>}
  </StyledTab>
)

Tab.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isActive: PropTypes.bool,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  iconTab: PropTypes.bool,
  isLight: PropTypes.bool
}

Tab.defaultProps = {
  label: '',
  isActive: false,
  counter: '',
  disabled: false,
  onClick: () => {},
  ariaLabel: '',
  iconTab: false,
  isLight: false
}
