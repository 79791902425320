import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Icon, DeviceIcon } from '../Icon'

const getButtonVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blue,
        color: theme.colors.white,
        borderColor: theme.colors.blue
      }

    case 'secondary':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: rgba(theme.colors.secondary, 0.25)
      }

    case 'secondary-warning':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.yellow, 0.75),
        borderColor: rgba(theme.colors.yellow, 0.25)
      }

    case 'secondary-danger':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.red, 0.75),
        borderColor: rgba(theme.colors.red, 0.25)
      }

    case 'secondary-success':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.green, 0.75),
        borderColor: rgba(theme.colors.green, 0.25)
      }

    case 'secondary-fill':
      return {
        backgroundColor: rgba(theme.colors.secondary, 0.1),
        color: theme.colors.secondary,
        borderColor: 'transparent'
      }

    case 'secondary-on-white':
      return {
        backgroundColor: 'transparent',
        color: theme.colors.blackText,
        borderColor: rgba(theme.colors.blackText, 0.25)
      }

    case 'ghost':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: 'transparent'
      }

    case 'ghost-primary':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.blue, 0.75),
        borderColor: 'transparent'
      }

    case 'ghost-danger':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.red, 0.75),
        borderColor: 'transparent'
      }

    case 'ghost-link':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.secondary, 0.5),
        borderColor: 'transparent',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: rgba(theme.colors.secondary, 0.25)
      }
  }
}

const getButtonFontSize = (theme, elSize) => {
  switch (elSize) {
    case 'md':
      return {
        fontSize: '14px'
      }

    case 'lg':
      return {
        fontSize: '16px'
      }
  }
}

const getButtonVariantHover = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blueAlt,
        color: theme.colors.white,
        borderColor: theme.colors.blueAlt
      }

    case 'secondary':
      return {
        color: theme.colors.secondary,
        borderColor: rgba(theme.colors.secondary, 0.5)
      }

    case 'secondary-warning':
      return {
        color: theme.colors.yellow,
        borderColor: rgba(theme.colors.yellow, 0.5)
      }

    case 'secondary-danger':
      return {
        color: theme.colors.red,
        borderColor: rgba(theme.colors.red, 0.5)
      }

    case 'secondary-success':
      return {
        color: theme.colors.green,
        borderColor: rgba(theme.colors.green, 0.5)
      }

    case 'secondary-fill':
      return {
        backgroundColor: rgba(theme.colors.secondary, 0.05),
        color: theme.colors.secondary
      }

    case 'secondary-on-white':
      return {
        color: theme.colors.blackText,
        borderColor: rgba(theme.colors.blackText, 0.5)
      }

    case 'ghost':
      return {
        color: theme.colors.secondary
      }

    case 'ghost-primary':
      return {
        color: theme.colors.blue
      }

    case 'ghost-danger':
      return {
        color: theme.colors.red
      }

    case 'ghost-link':
      return {
        color: theme.colors.secondary
      }
  }
}

const getButtonVariantFocus = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blueAlt,
        color: theme.colors.white,
        borderColor: theme.colors.blueAlt
      }

    case 'secondary':
      return {
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: rgba(theme.colors.secondary, 0.25)
      }

    case 'secondary-warning':
      return {
        color: rgba(theme.colors.yellow, 0.75),
        borderColor: rgba(theme.colors.yellow, 0.25)
      }

    case 'secondary-danger':
      return {
        color: rgba(theme.colors.red, 0.75),
        borderColor: rgba(theme.colors.red, 0.25)
      }

    case 'secondary-success':
      return {
        color: rgba(theme.colors.green, 0.75),
        borderColor: rgba(theme.colors.green, 0.25)
      }

    case 'secondary-fill':
      return {
        backgroundColor: rgba(theme.colors.secondary, 0.05),
        color: theme.colors.secondary
      }

    case 'secondary-on-white':
      return {
        color: rgba(theme.colors.blackText, 0.75),
        borderColor: rgba(theme.colors.blackText, 0.25)
      }

    case 'ghost':
      return {
        color: rgba(theme.colors.secondary, 0.75)
      }

    case 'ghost-primary':
      return {
        color: rgba(theme.colors.blue, 0.75)
      }

    case 'ghost-danger':
      return {
        color: rgba(theme.colors.red, 0.75)
      }

    case 'ghost-link':
      return {
        color: rgba(theme.colors.secondary, 0.75)
      }
  }
}

const getButtonVariantDisabled = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: rgba(theme.colors.white, 0.1),
        color: rgba(theme.colors.white, 0.5),
        borderColor: 'transparent'
      }

    case 'secondary':
      return {
        color: rgba(theme.colors.secondary, 0.25),
        borderColor: rgba(theme.colors.secondary, 0.1)
      }

    case 'secondary-warning':
      return {
        color: rgba(theme.colors.yellow, 0.25),
        borderColor: rgba(theme.colors.yellow, 0.1)
      }

    case 'secondary-danger':
      return {
        color: rgba(theme.colors.red, 0.25),
        borderColor: rgba(theme.colors.red, 0.1)
      }

    case 'secondary-success':
      return {
        color: rgba(theme.colors.green, 0.25),
        borderColor: rgba(theme.colors.green, 0.1)
      }

    case 'secondary-fill':
      return {
        backgroundColor: rgba(theme.colors.secondary, 0.08),
        color: rgba(theme.colors.secondary, 0.25)
      }

    case 'secondary-on-white':
      return {
        color: rgba(theme.colors.blackText, 0.25),
        borderColor: rgba(theme.colors.blackText, 0.1)
      }

    case 'ghost':
      return {
        color: rgba(theme.colors.secondary, 0.25)
      }

    case 'ghost-primary':
      return {
        color: rgba(theme.colors.blue, 0.25)
      }

    case 'ghost-danger':
      return {
        color: rgba(theme.colors.red, 0.25)
      }

    case 'ghost-link':
      return {
        color: rgba(theme.colors.secondary, 0.25)
      }
  }
}

const getLabelAlign = (theme, labelAlign) => {
  switch (labelAlign) {
    case 'left':
      return 'flex-start'

    case 'center':
      return 'center'

    case 'right':
      return 'flex-end'
  }
}

const StyledButton = styled.button`
  ${({ theme, variant }) => getButtonVariant(theme, variant)};
  display: inline-flex;
  justify-content: ${({ theme, labelAlign }) =>
    getLabelAlign(theme, labelAlign)};
  align-items: center;
  white-space: nowrap;

  ${({ hasIcon, hasLabel }) =>
    hasIcon &&
    hasLabel &&
    css`
      padding: 0 12px;
    `};

  ${({ hasIcon, hasLabel }) =>
    !hasIcon &&
    hasLabel &&
    css`
      padding: 0 16px;
    `};

  ${({ hasIcon, hasLabel }) =>
    hasIcon &&
    !hasLabel &&
    css`
      padding: 0 8px;
    `};
  margin: 0;
  border-width: 1px;
  border-style: solid;
  font-family: ${({ theme }) => theme.fonts.medium};
  ${({ theme, elSize }) => getButtonFontSize(theme, elSize)};
  line-height: 30px;
  height: 32px;
  text-align: center;
  cursor: pointer;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      min-width: 100%;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
      height: 16px;
    `}

  transition: background-color 0.16s linear, color 0.16s linear,
    border-color 0.16s linear;

  & i + span,
  & span + i {
    margin-left: 8px;
  }

  @media (hover: hover) {
    &:hover {
      ${({ theme, variant }) => getButtonVariantHover(theme, variant)};
    }

    &:active,
    &:focus {
      ${({ theme, variant }) => getButtonVariantFocus(theme, variant)};
      outline: none;
    }
  }

  &:disabled {
    cursor: default;
    ${({ theme, variant }) => getButtonVariantDisabled(theme, variant)};
  }
`

export const Button = ({
  variant,
  label,
  icon,
  deviceIcon,
  isIconAfter,
  onClick,
  type,
  width,
  fullWidth,
  disabled,
  ariaLabel,
  noPadding,
  elSize,
  labelAlign,
  ...rest
}) => (
  <StyledButton
    type={type}
    variant={variant}
    hasIcon={icon || deviceIcon}
    isIconAfter={isIconAfter}
    hasLabel={label}
    onClick={onClick}
    width={width}
    fullWidth={fullWidth}
    disabled={disabled}
    ariaLabel={ariaLabel}
    noPadding={noPadding}
    elSize={elSize}
    labelAlign={labelAlign}
    {...rest}
  >
    {icon && !isIconAfter && <Icon icon={icon} />}
    {deviceIcon && !isIconAfter && <DeviceIcon icon={deviceIcon} />}
    {label && <span>{label}</span>}
    {icon && isIconAfter && <Icon icon={icon} />}
    {deviceIcon && isIconAfter && <DeviceIcon icon={deviceIcon} />}
  </StyledButton>
)

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondary-warning',
    'secondary-danger',
    'secondary-success',
    'secondary-fill',
    'secondary-on-white',
    'ghost',
    'ghost-primary',
    'ghost-danger',
    'ghost-link'
  ]),
  label: PropTypes.string,
  icon: PropTypes.string,
  deviceIcon: PropTypes.string,
  isIconAfter: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  noPadding: PropTypes.bool,
  elSize: PropTypes.oneOf(['md', 'lg']),
  labelAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

Button.defaultProps = {
  variant: 'primary',
  label: '',
  icon: null,
  deviceIcon: null,
  isIconAfter: false,
  onClick: () => {},
  type: null,
  width: null,
  fullWidth: false,
  disabled: false,
  ariaLabel: null,
  noPadding: false,
  elSize: 'md',
  labelAlign: 'center'
}
