import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

const Svg = styled.svg`
  & path {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  }
`

export const Logo = () => (
  <Svg
    width='199'
    height='30'
    viewBox='0 0 199 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M75.1447 20.7294C77.2198 20.7294 78.8161 19.0866 78.8161 16.7387C78.8161 14.3909 77.2198 12.748 75.1447 12.748C73.0695 12.748 71.4665 14.3909 71.4665 16.7387C71.4665 19.0866 73.0628 20.7294 75.1447 20.7294V20.7294ZM69.7173 6.99475H71.4466V13.054L71.6661 12.7281C72.1097 12.1864 72.6758 11.7579 73.3176 11.478C73.9595 11.1982 74.6587 11.0749 75.3575 11.1185C78.1909 11.1185 80.5521 13.4996 80.5521 16.7454C80.5521 19.9912 78.1909 22.3656 75.3575 22.3656C74.6614 22.4039 73.9665 22.2733 73.3318 21.9849C72.6972 21.6965 72.1416 21.2589 71.7126 20.7095L71.4931 20.4102V22.1395H69.7173V6.99475Z'
      fill='currentcolor'
    />
    <path
      d='M83.1458 11.3248L85.5269 20.5167H87.1498L89.9566 11.3248H91.799L87.2562 26.25H85.4138L86.7175 22.1396H84.2233L81.3101 11.3248H83.1458Z'
      fill='currentcolor'
    />
    <path
      d='M98.5034 6.99475H100.452V13.5927H108.56V6.99475H110.509V22.1395H108.56V15.4351H100.452V22.1395H98.5034V6.99475Z'
      fill='currentcolor'
    />
    <path
      d='M121.756 15.7744C121.622 14.9058 121.174 14.1169 120.495 13.5586C119.816 13.0003 118.956 12.7117 118.078 12.7481C117.195 12.717 116.33 13.0062 115.643 13.5623C114.956 14.1184 114.493 14.904 114.34 15.7744H121.756ZM118.078 11.1185C118.807 11.1031 119.531 11.2354 120.208 11.5074C120.884 11.7795 121.498 12.1856 122.013 12.7015C122.529 13.2173 122.934 13.8321 123.205 14.5088C123.477 15.1855 123.608 15.9101 123.592 16.639V17.3041H114.28C114.363 18.2497 114.798 19.1297 115.499 19.7701C116.2 20.4105 117.115 20.7647 118.064 20.7627C119.077 20.785 120.061 20.4293 120.825 19.7651C121.15 19.4876 121.428 19.1598 121.649 18.794H123.379C122.976 19.87 122.246 20.7926 121.292 21.4321C120.337 22.0716 119.206 22.3958 118.058 22.359C117.321 22.3712 116.59 22.2334 115.909 21.9539C115.227 21.6744 114.61 21.2591 114.094 20.7333C113.578 20.2075 113.174 19.5821 112.908 18.8954C112.642 18.2087 112.518 17.4749 112.544 16.7388C112.519 16.0012 112.644 15.2661 112.912 14.5785C113.18 13.8908 113.585 13.2649 114.103 12.739C114.62 12.2131 115.24 11.7982 115.923 11.5196C116.607 11.241 117.34 11.1045 118.078 11.1185'
      fill='currentcolor'
    />
    <path
      d='M130.19 20.7295C132.271 20.7295 133.868 19.0867 133.868 16.7388C133.868 14.3909 132.271 12.7481 130.19 12.7481C128.108 12.7481 126.518 14.3909 126.518 16.7388C126.518 19.0867 128.114 20.7295 130.19 20.7295ZM129.977 11.1186C130.675 11.0906 131.37 11.2243 132.008 11.5093C132.646 11.7943 133.21 12.2228 133.655 12.7614L133.848 13.0873V11.3247H135.577V22.1396H133.848V20.4302L133.655 20.7295C133.215 21.2746 132.653 21.7077 132.013 21.9932C131.374 22.2788 130.676 22.4087 129.977 22.3724C127.143 22.3724 124.782 19.9979 124.782 16.7521C124.782 13.5063 127.143 11.1186 129.977 11.1186Z'
      fill='currentcolor'
    />
    <path
      d='M142.88 20.7294C144.955 20.7294 146.558 19.0866 146.558 16.7387C146.558 14.3909 144.955 12.748 142.88 12.748C140.805 12.748 139.202 14.3909 139.202 16.7387C139.202 19.0866 140.805 20.7294 142.88 20.7294ZM142.627 11.1185C143.324 11.0886 144.018 11.2195 144.656 11.5009C145.294 11.7823 145.858 12.2068 146.305 12.7414L146.505 13.0673V6.99475H148.234V22.1395H146.505V20.4301L146.305 20.7294C145.867 21.2757 145.305 21.7097 144.665 21.9954C144.025 22.2811 143.327 22.4103 142.627 22.3723C139.794 22.3723 137.439 19.9978 137.439 16.752C137.439 13.5063 139.794 11.1251 142.627 11.1251'
      fill='currentcolor'
    />
    <path
      d='M157.34 15.1092C159.741 15.1092 161.018 13.779 161.018 11.9698C161.018 10.1607 159.741 8.83713 157.34 8.83713H152.903V15.1092H157.34ZM150.955 6.99475H157.34C160.798 6.99475 162.96 9.13643 162.96 11.9698C162.96 14.8032 160.798 16.9449 157.34 16.9449H152.903V22.1395H150.955V6.99475Z'
      fill='currentcolor'
    />
    <path
      d='M169.345 20.7296C169.868 20.7431 170.389 20.6484 170.873 20.4513C171.358 20.2542 171.797 19.959 172.162 19.5843C172.528 19.2096 172.812 18.7634 172.997 18.2738C173.181 17.7841 173.263 17.2616 173.236 16.7389C173.258 16.214 173.173 15.69 172.987 15.1987C172.801 14.7073 172.518 14.2585 172.154 13.8794C171.79 13.5003 171.354 13.1987 170.87 12.9926C170.387 12.7866 169.867 12.6803 169.342 12.6803C168.816 12.6803 168.296 12.7866 167.813 12.9926C167.33 13.1987 166.893 13.5003 166.529 13.8794C166.166 14.2585 165.882 14.7073 165.696 15.1987C165.511 15.69 165.426 16.214 165.448 16.7389C165.422 17.2619 165.504 17.7846 165.69 18.2743C165.875 18.7639 166.16 19.21 166.526 19.5846C166.892 19.9592 167.331 20.2543 167.816 20.4513C168.301 20.6483 168.822 20.7431 169.345 20.7296V20.7296ZM169.345 11.1053C170.458 11.1053 171.546 11.4354 172.471 12.0537C173.397 12.6719 174.118 13.5507 174.544 14.5789C174.97 15.6071 175.081 16.7385 174.864 17.83C174.647 18.9215 174.111 19.9241 173.324 20.7111C172.537 21.498 171.534 22.0339 170.443 22.251C169.351 22.4681 168.22 22.3567 167.192 21.9308C166.164 21.5049 165.285 20.7837 164.667 19.8584C164.048 18.9331 163.718 17.8451 163.718 16.7322C163.696 15.9879 163.827 15.2469 164.102 14.5549C164.377 13.863 164.791 13.2346 165.318 12.7087C165.845 12.1828 166.474 11.7704 167.167 11.4969C167.86 11.2234 168.601 11.0947 169.345 11.1186'
      fill='currentcolor'
    />
    <path
      d='M176.801 11.3247H178.53V22.1395H176.801V11.3247ZM177.666 6.88836C177.901 6.88836 178.131 6.95818 178.327 7.089C178.523 7.21982 178.676 7.40576 178.766 7.62331C178.856 7.84086 178.879 8.08024 178.834 8.31119C178.788 8.54213 178.674 8.75427 178.508 8.92077C178.341 9.08728 178.129 9.20066 177.898 9.2466C177.667 9.29254 177.428 9.26896 177.21 9.17885C176.993 9.08874 176.807 8.93614 176.676 8.74036C176.545 8.54457 176.475 8.31439 176.475 8.07892C176.473 7.92183 176.502 7.7658 176.56 7.62013C176.619 7.47447 176.707 7.34214 176.818 7.23105C176.929 7.11995 177.061 7.03236 177.207 6.97348C177.353 6.91461 177.509 6.88566 177.666 6.88836'
      fill='currentcolor'
    />
    <path
      d='M181.024 11.3246H182.787V12.9475C183.011 12.5948 183.297 12.286 183.632 12.0363C184.439 11.4192 185.429 11.0913 186.445 11.1052C189.039 11.1052 190.768 12.8345 190.768 15.761V22.1461H189.039V15.8674C189.039 13.7058 187.855 12.728 186.119 12.728C184.124 12.728 182.794 14.1381 182.794 16.2997V22.1395H181.064L181.024 11.3246Z'
      fill='currentcolor'
    />
    <path
      d='M192.165 11.3248H193.568V8.94366H195.298V11.3248H198.763V12.9477H195.298V20.5167H198.869V22.1396H193.568V12.9477H192.165V11.3248Z'
      fill='currentcolor'
    />
    <path
      d='M28.1403 19.5456C27.4874 19.5629 26.838 19.4451 26.2328 19.1998C25.6276 18.9544 25.0796 18.5866 24.6231 18.1195C24.1667 17.6524 23.8116 17.0961 23.5802 16.4854C23.3488 15.8747 23.246 15.2228 23.2783 14.5705C23.2541 13.9165 23.362 13.2642 23.5956 12.6528C23.8292 12.0414 24.1837 11.4834 24.6379 11.0121C25.0921 10.5409 25.6366 10.166 26.239 9.90995C26.8413 9.65391 27.4891 9.52194 28.1436 9.52194C28.7981 9.52194 29.4459 9.65391 30.0482 9.90995C30.6506 10.166 31.1951 10.5409 31.6493 11.0121C32.1035 11.4834 32.458 12.0414 32.6916 12.6528C32.9252 13.2642 33.0331 13.9165 33.0089 14.5705C33.0413 15.2234 32.9383 15.8759 32.7065 16.487C32.4747 17.0982 32.119 17.6548 31.6618 18.122C31.2046 18.5892 30.6558 18.9568 30.0498 19.2018C29.4438 19.4468 28.7937 19.5638 28.1403 19.5456V19.5456ZM28.1403 6.5625C23.3847 6.5625 19.813 10.1342 19.813 14.5439C19.813 18.9537 23.3847 22.5253 28.1403 22.5253C32.8959 22.5253 36.4609 19.0002 36.4609 14.5705C36.4609 10.1408 32.8892 6.5891 28.127 6.5891'
      fill='currentcolor'
    />
    <path
      d='M44.9481 10.7793C43.979 10.7653 43.0364 11.0952 42.2876 11.7105C41.965 11.9596 41.694 12.2691 41.4895 12.6217H41.3764V11.1185H38.3501V22.1395H41.3764V16.2998C41.3493 15.936 41.3957 15.5705 41.5128 15.225C41.6298 14.8795 41.8152 14.561 42.0578 14.2886C42.3004 14.0162 42.5954 13.7954 42.9251 13.6393C43.2548 13.4832 43.6125 13.395 43.977 13.3799C45.3804 13.3799 46.245 14.2246 46.245 15.8675V22.1395H49.2713V15.4351C49.2713 12.3822 47.6485 10.7793 44.9481 10.7793'
      fill='currentcolor'
    />
    <path
      d='M53.6015 15.4351C53.7636 14.8436 54.1158 14.3219 54.6038 13.9505C55.0919 13.579 55.6886 13.3785 56.3019 13.3799C56.907 13.3652 57.4983 13.5626 57.9733 13.9379C58.4482 14.3133 58.7769 14.8429 58.9025 15.4351H53.6015ZM62.1416 16.619C62.1617 15.8466 62.0244 15.0783 61.7381 14.3606C61.4517 13.643 61.0223 12.9912 60.476 12.4449C59.9296 11.8986 59.2778 11.4691 58.5602 11.1828C57.8426 10.8964 57.0742 10.7591 56.3019 10.7793C55.518 10.7528 54.7367 10.8844 54.0047 11.1661C53.2727 11.4478 52.6049 11.874 52.041 12.4192C51.4771 12.9644 51.0287 13.6175 50.7224 14.3395C50.4161 15.0616 50.2583 15.838 50.2583 16.6223C50.2583 17.4067 50.4161 18.183 50.7224 18.9051C51.0287 19.6272 51.4771 20.2803 52.041 20.8255C52.6049 21.3707 53.2727 21.7968 54.0047 22.0786C54.7367 22.3603 55.518 22.4918 56.3019 22.4654C57.5568 22.5562 58.8056 22.2208 59.8462 21.5134C60.8867 20.806 61.658 19.7681 62.0352 18.5678H58.7894C58.6573 18.8185 58.4816 19.0438 58.2706 19.2329C57.7097 19.692 56.9995 19.9287 56.2753 19.898C55.5792 19.8582 54.9186 19.5777 54.4066 19.1045C53.8946 18.6313 53.5629 17.9948 53.4685 17.3041H62.115L62.1416 16.619Z'
      fill='currentcolor'
    />
    <path
      d='M5.12078 7.9458H1.875V22.1394H5.12078V7.9458Z'
      fill='currentcolor'
    />
    <path
      d='M13.7738 10.7794C12.8046 10.7642 11.8616 11.0942 11.1133 11.7105C10.7905 11.9613 10.5175 12.2704 10.3086 12.6217H10.2021V11.1186H7.1958V22.1396H10.2221V16.2999C10.195 15.9366 10.2412 15.5716 10.3578 15.2265C10.4745 14.8815 10.6593 14.5633 10.9012 14.291C11.1431 14.0187 11.4373 13.7977 11.7662 13.6412C12.0951 13.4847 12.4521 13.3959 12.8161 13.38C14.2261 13.38 15.0908 14.2247 15.0908 15.8675V22.1396H18.117V15.4352C18.117 12.3823 16.4942 10.7794 13.7938 10.7794'
      fill='currentcolor'
    />
  </Svg>
)

const SignSvg = styled.svg`
  & path {
    color: ${(({ theme }) => theme.colors.secondary, 0.75)};
  }
`

export const Sign = () => (
  <SignSvg
    width='180'
    height='180'
    viewBox='0 0 180 180'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M72.0545 95.9436C70.8103 95.9767 69.5728 95.7514 68.4194 95.2818C67.266 94.8123 66.2217 94.1085 65.3518 93.2148C64.4819 92.321 63.8053 91.2565 63.3642 90.0879C62.9232 88.9193 62.7273 87.6718 62.7889 86.4236C62.7428 85.1721 62.9485 83.924 63.3937 82.7541C63.8389 81.5842 64.5144 80.5164 65.3799 79.6146C66.2455 78.7127 67.2832 77.9954 68.4311 77.5055C69.579 77.0155 70.8135 76.763 72.0608 76.763C73.3081 76.763 74.5426 77.0155 75.6905 77.5055C76.8384 77.9954 77.8761 78.7127 78.7416 79.6146C79.6072 80.5164 80.2827 81.5842 80.7279 82.7541C81.1731 83.924 81.3788 85.1721 81.3327 86.4236C81.3944 87.6729 81.1981 88.9215 80.7563 90.091C80.3145 91.2604 79.6368 92.3256 78.7655 93.2195C77.8942 94.1135 76.8483 94.8169 75.6935 95.2857C74.5386 95.7545 73.2997 95.9785 72.0545 95.9436ZM72.0545 71.1C62.9917 71.1 56.1851 77.9346 56.1851 86.3727C56.1851 94.8109 62.9917 101.645 72.0545 101.645C81.1172 101.645 87.9112 94.9 87.9112 86.4236C87.9112 77.9473 81.1046 71.1509 72.0291 71.1509'
      fill='currentcolor'
    />
    <path
      d='M104.084 79.1691C102.237 79.1422 100.441 79.7736 99.014 80.9509C98.3993 81.4277 97.8827 82.0199 97.493 82.6945H97.2775V79.8182H91.5103V100.907H97.2775V89.7327C97.2259 89.0366 97.3143 88.3372 97.5374 87.676C97.7605 87.0149 98.1137 86.4056 98.5761 85.8843C99.0385 85.3631 99.6005 84.9405 100.229 84.6418C100.857 84.343 101.539 84.1743 102.234 84.1455C104.908 84.1455 106.556 85.7618 106.556 88.9055V100.907H112.323V88.0782C112.323 82.2364 109.23 79.1691 104.084 79.1691Z'
      fill='currentcolor'
    />
    <path
      d='M120.575 88.0782C120.884 86.9463 121.555 85.948 122.485 85.2373C123.415 84.5265 124.553 84.1428 125.721 84.1454C126.875 84.1173 128.001 84.495 128.907 85.2133C129.812 85.9315 130.438 86.945 130.677 88.0782H120.575ZM136.85 90.3436C136.889 88.8657 136.627 87.3954 136.081 86.0222C135.536 84.649 134.717 83.4018 133.676 82.3563C132.635 81.3109 131.393 80.4892 130.025 79.9413C128.658 79.3933 127.193 79.1306 125.721 79.1691C124.227 79.1185 122.739 79.3702 121.344 79.9093C119.949 80.4484 118.676 81.2639 117.601 82.3071C116.527 83.3504 115.672 84.6001 115.089 85.9818C114.505 87.3636 114.204 88.8491 114.204 90.35C114.204 91.8509 114.505 93.3364 115.089 94.7182C115.672 96.0999 116.527 97.3496 117.601 98.3929C118.676 99.4361 119.949 100.252 121.344 100.791C122.739 101.33 124.227 101.582 125.721 101.531C128.113 101.705 130.493 101.063 132.476 99.7092C134.459 98.3557 135.929 96.3696 136.647 94.0727H130.462C130.21 94.5525 129.875 94.9835 129.473 95.3455C128.404 96.2239 127.051 96.6769 125.671 96.6182C124.344 96.5419 123.085 96.0051 122.11 95.0997C121.134 94.1942 120.502 92.9763 120.322 91.6545H136.8L136.85 90.3436Z'
      fill='currentcolor'
    />
    <path
      d='M28.1855 73.7473H22V100.907H28.1855V73.7473Z'
      fill='currentcolor'
    />
    <path
      d='M44.6759 79.1691C42.8289 79.14 41.0317 79.7716 39.6058 80.9509C38.9906 81.4308 38.4704 82.0223 38.0721 82.6946H37.8693V79.8182H32.1401V100.907H37.9074V89.7328C37.8558 89.0377 37.9438 88.3392 38.1661 87.6789C38.3884 87.0186 38.7405 86.4098 39.2015 85.8887C39.6626 85.3677 40.2231 84.9448 40.85 84.6454C41.4768 84.3459 42.1572 84.1759 42.8507 84.1455C45.5379 84.1455 47.1856 85.7618 47.1856 88.9055V100.907H52.9529V88.0782C52.9529 82.2364 49.8601 79.1691 44.714 79.1691'
      fill='currentcolor'
    />
    <path
      d='M144.874 50.9782C137.776 40.3043 127.879 31.8074 116.274 26.4258C104.67 21.0441 91.8106 18.987 79.1154 20.4814C66.4201 21.9759 54.3831 26.9639 44.3333 34.8948C34.2835 42.8256 26.6117 53.3907 22.165 65.4236H29.4279C33.767 54.9881 40.8298 45.9214 49.8718 39.1796C58.9138 32.4377 69.5999 28.2705 80.8034 27.1172C92.007 25.9639 103.313 27.8674 113.529 32.6268C123.745 37.3863 132.493 44.8255 138.851 54.1599C145.208 63.4944 148.94 74.3784 149.652 85.6643C150.364 96.9502 148.03 108.22 142.896 118.285C137.762 128.35 130.019 136.838 120.482 142.854C110.945 148.87 99.969 152.19 88.71 152.465V159.249C101.133 158.977 113.256 155.357 123.809 148.769C134.362 142.182 142.959 132.868 148.699 121.801C154.44 110.735 157.113 98.3232 156.44 85.864C155.767 73.4049 151.773 61.3562 144.874 50.9782Z'
      fill='#0D66FF'
    />
  </SignSvg>
)

export const LogoSign = () => (
  <SignSvg
    width='201'
    height='71'
    viewBox='0 0 201 71'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M80.8445 39.8771C82.8598 39.8771 84.41 38.2742 84.41 35.9835C84.41 33.6927 82.8598 32.0899 80.8445 32.0899C78.8292 32.0899 77.2725 33.6927 77.2725 35.9835C77.2725 38.2742 78.8228 39.8771 80.8445 39.8771ZM75.5737 26.4766H77.2531V32.3884L77.4663 32.0704C77.8971 31.5419 78.4469 31.1238 79.0702 30.8508C79.6935 30.5777 80.3725 30.4575 81.0512 30.5C83.8029 30.5 86.0959 32.8231 86.0959 35.99C86.0959 39.1568 83.8029 41.4735 81.0512 41.4735C80.3752 41.5108 79.7003 41.3833 79.084 41.102C78.4676 40.8206 77.9281 40.3937 77.5115 39.8576L77.2984 39.5656V41.2528H75.5737V26.4766Z'
      fill='currentcolor'
    />
    <path
      d='M88.6148 30.7011L90.9272 39.6694H92.5033L95.2291 30.7011H97.0183L92.6066 45.2632H90.8174L92.0834 41.2528H89.6612L86.832 30.7011H88.6148Z'
      fill='currentcolor'
    />
    <path
      d='M103.529 26.4766H105.422V32.914H113.296V26.4766H115.188V41.2528H113.296V34.7116H105.422V41.2528H103.529V26.4766Z'
      fill='currentcolor'
    />
    <path
      d='M126.111 35.0425C125.982 34.1951 125.546 33.4254 124.887 32.8807C124.228 32.3359 123.392 32.0544 122.539 32.0899C121.681 32.0595 120.842 32.3417 120.175 32.8842C119.507 33.4268 119.058 34.1933 118.909 35.0425H126.111ZM122.539 30.5C123.247 30.4849 123.951 30.614 124.607 30.8794C125.264 31.1448 125.861 31.5411 126.361 32.0444C126.862 32.5477 127.255 33.1475 127.519 33.8077C127.782 34.468 127.91 35.175 127.894 35.8861V36.5351H118.851C118.932 37.4577 119.354 38.3162 120.035 38.9411C120.715 39.5659 121.604 39.9115 122.526 39.9095C123.509 39.9312 124.465 39.5842 125.207 38.9361C125.523 38.6654 125.793 38.3455 126.008 37.9887H127.687C127.296 39.0385 126.587 39.9387 125.66 40.5626C124.733 41.1865 123.635 41.5028 122.52 41.467C121.804 41.4789 121.094 41.3444 120.432 41.0717C119.771 40.799 119.171 40.3938 118.67 39.8808C118.169 39.3678 117.777 38.7577 117.518 38.0876C117.26 37.4176 117.139 36.7017 117.165 35.9835C117.14 35.2638 117.262 34.5467 117.522 33.8757C117.782 33.2048 118.176 32.5941 118.679 32.081C119.182 31.5679 119.783 31.1631 120.447 30.8913C121.111 30.6195 121.822 30.4863 122.539 30.5Z'
      fill='currentcolor'
    />
    <path
      d='M134.301 39.8771C136.323 39.8771 137.873 38.2742 137.873 35.9834C137.873 33.6927 136.323 32.0898 134.301 32.0898C132.279 32.0898 130.736 33.6927 130.736 35.9834C130.736 38.2742 132.286 39.8771 134.301 39.8771ZM134.095 30.4999C134.773 30.4727 135.448 30.6031 136.067 30.8812C136.687 31.1592 137.234 31.5773 137.667 32.1028L137.854 32.4208V30.7011H139.533V41.2528H137.854V39.585L137.667 39.8771C137.24 40.4089 136.693 40.8314 136.072 41.11C135.451 41.3886 134.774 41.5154 134.095 41.4799C131.343 41.4799 129.05 39.1632 129.05 35.9964C129.05 32.8296 131.343 30.4999 134.095 30.4999Z'
      fill='currentcolor'
    />
    <path
      d='M146.626 39.8771C148.641 39.8771 150.198 38.2742 150.198 35.9835C150.198 33.6927 148.641 32.0899 146.626 32.0899C144.611 32.0899 143.054 33.6927 143.054 35.9835C143.054 38.2742 144.611 39.8771 146.626 39.8771ZM146.381 30.5C147.057 30.4708 147.731 30.5985 148.35 30.8731C148.97 31.1477 149.518 31.5618 149.953 32.0834L150.146 32.4013V26.4766H151.826V41.2528H150.146V39.5851L149.953 39.8771C149.527 40.4101 148.981 40.8335 148.359 41.1122C147.738 41.391 147.06 41.517 146.381 41.4799C143.629 41.4799 141.342 39.1632 141.342 35.9964C141.342 32.8296 143.629 30.5064 146.381 30.5064'
      fill='currentcolor'
    />
    <path
      d='M160.668 34.3936C163 34.3936 164.24 33.0957 164.24 31.3306C164.24 29.5655 163 28.2741 160.668 28.2741H156.36V34.3936H160.668ZM154.467 26.4766H160.668C164.027 26.4766 166.126 28.5661 166.126 31.3306C166.126 34.0951 164.027 36.1846 160.668 36.1846H156.36V41.2528H154.467V26.4766Z'
      fill='currentcolor'
    />
    <path
      d='M172.327 39.8771C172.835 39.8902 173.341 39.7978 173.811 39.6055C174.282 39.4132 174.708 39.1252 175.063 38.7596C175.418 38.394 175.694 37.9587 175.873 37.481C176.053 37.0032 176.132 36.4934 176.106 35.9835C176.127 35.4713 176.045 34.9601 175.864 34.4807C175.684 34.0012 175.409 33.5634 175.055 33.1935C174.702 32.8237 174.278 32.5294 173.809 32.3283C173.339 32.1273 172.834 32.0236 172.324 32.0236C171.814 32.0236 171.309 32.1273 170.839 32.3283C170.37 32.5294 169.946 32.8237 169.593 33.1935C169.24 33.5634 168.964 34.0012 168.784 34.4807C168.603 34.9601 168.521 35.4713 168.542 35.9835C168.517 36.4937 168.597 37.0037 168.777 37.4814C168.957 37.9592 169.234 38.3944 169.589 38.7599C169.944 39.1254 170.371 39.4133 170.842 39.6055C171.313 39.7977 171.819 39.8902 172.327 39.8771ZM172.327 30.487C173.408 30.487 174.465 30.809 175.363 31.4122C176.262 32.0154 176.962 32.8729 177.376 33.876C177.789 34.8792 177.898 35.9831 177.687 37.048C177.476 38.113 176.956 39.0912 176.191 39.859C175.427 40.6268 174.453 41.1496 173.393 41.3615C172.333 41.5733 171.235 41.4646 170.236 41.0491C169.238 40.6335 168.384 39.9299 167.784 39.027C167.183 38.1242 166.863 37.0628 166.863 35.977C166.841 35.2507 166.968 34.5278 167.235 33.8526C167.502 33.1775 167.904 32.5644 168.416 32.0513C168.928 31.5382 169.539 31.1358 170.212 30.869C170.885 30.6022 171.604 30.4766 172.327 30.4999'
      fill='currentcolor'
    />
    <path
      d='M179.568 30.7011H181.248V41.2528H179.568V30.7011ZM180.408 26.3727C180.637 26.3727 180.86 26.4409 181.05 26.5685C181.24 26.6961 181.389 26.8775 181.476 27.0898C181.564 27.3021 181.586 27.5356 181.542 27.7609C181.497 27.9863 181.387 28.1932 181.225 28.3557C181.064 28.5181 180.858 28.6288 180.633 28.6736C180.409 28.7184 180.177 28.6954 179.965 28.6075C179.754 28.5196 179.574 28.3707 179.446 28.1797C179.319 27.9886 179.252 27.7641 179.252 27.5343C179.249 27.3811 179.277 27.2288 179.334 27.0867C179.391 26.9446 179.477 26.8155 179.584 26.7071C179.692 26.5987 179.821 26.5132 179.962 26.4558C180.104 26.3983 180.255 26.3701 180.408 26.3727Z'
      fill='currentcolor'
    />
    <path
      d='M183.67 30.7011H185.382V32.2845C185.599 31.9404 185.877 31.6391 186.202 31.3955C186.986 30.7934 187.948 30.4735 188.934 30.487C191.453 30.487 193.133 32.1742 193.133 35.0295V41.2593H191.453V35.1334C191.453 33.0243 190.304 32.0704 188.618 32.0704C186.68 32.0704 185.388 33.4461 185.388 35.5552V41.2528H183.709L183.67 30.7011Z'
      fill='currentcolor'
    />
    <path
      d='M194.489 30.7011H195.852V28.3779H197.532V30.7011H200.897V32.2845H197.532V39.6694H201V41.2528H195.852V32.2845H194.489V30.7011Z'
      fill='currentcolor'
    />
    <path
      d='M25.5074 38.722C24.8734 38.7388 24.2428 38.624 23.655 38.3845C23.0673 38.1451 22.5351 37.7863 22.0918 37.3306C21.6485 36.8749 21.3037 36.3321 21.0789 35.7362C20.8542 35.1404 20.7543 34.5043 20.7857 33.8679C20.7622 33.2298 20.867 32.5934 21.0939 31.9969C21.3208 31.4004 21.665 30.8559 22.1061 30.3961C22.5472 29.9363 23.076 29.5706 23.661 29.3208C24.2459 29.0709 24.875 28.9422 25.5107 28.9422C26.1463 28.9422 26.7754 29.0709 27.3604 29.3208C27.9453 29.5706 28.4741 29.9363 28.9152 30.3961C29.3563 30.8559 29.7006 31.4004 29.9274 31.9969C30.1543 32.5934 30.2591 33.2298 30.2356 33.8679C30.267 34.5049 30.167 35.1415 29.9419 35.7378C29.7168 36.3341 29.3714 36.8772 28.9274 37.333C28.4834 37.7888 27.9504 38.1475 27.3619 38.3865C26.7733 38.6255 26.142 38.7397 25.5074 38.722ZM25.5074 26.0547C20.889 26.0547 17.4204 29.5395 17.4204 33.842C17.4204 38.1444 20.889 41.6292 25.5074 41.6292C30.1258 41.6292 33.588 38.1898 33.588 33.8679C33.588 29.546 30.1194 26.0807 25.4945 26.0807'
      fill='currentcolor'
    />
    <path
      d='M41.8305 30.169C40.8894 30.1553 39.9739 30.4772 39.2467 31.0775C38.9335 31.3206 38.6702 31.6226 38.4716 31.9665H38.3618V30.5H35.4229V41.2528H38.3618V35.5552C38.3355 35.2002 38.3806 34.8436 38.4943 34.5065C38.6079 34.1694 38.788 33.8587 39.0236 33.5929C39.2592 33.3272 39.5456 33.1117 39.8658 32.9594C40.186 32.8071 40.5334 32.721 40.8874 32.7063C42.2503 32.7063 43.09 33.5305 43.09 35.1333V41.2528H46.029V34.7115C46.029 31.7329 44.4529 30.169 41.8305 30.169Z'
      fill='currentcolor'
    />
    <path
      d='M50.234 34.7116C50.3914 34.1345 50.7335 33.6254 51.2075 33.263C51.6814 32.9006 52.2609 32.705 52.8565 32.7064C53.4442 32.692 54.0184 32.8846 54.4797 33.2508C54.9409 33.617 55.2601 34.1338 55.3821 34.7116H50.234ZM58.5277 35.8667C58.5473 35.1131 58.414 34.3634 58.1359 33.6633C57.8578 32.9631 57.4407 32.3272 56.9102 31.7941C56.3796 31.2611 55.7466 30.8421 55.0497 30.5627C54.3528 30.2833 53.6066 30.1494 52.8565 30.169C52.0952 30.1432 51.3365 30.2716 50.6256 30.5464C49.9147 30.8213 49.2662 31.2371 48.7185 31.769C48.1709 32.301 47.7354 32.9381 47.438 33.6427C47.1406 34.3472 46.9873 35.1046 46.9873 35.8699C46.9873 36.6352 47.1406 37.3926 47.438 38.0972C47.7354 38.8017 48.1709 39.4389 48.7185 39.9708C49.2662 40.5027 49.9147 40.9185 50.6256 41.1934C51.3365 41.4683 52.0952 41.5966 52.8565 41.5708C54.0752 41.6594 55.288 41.3321 56.2985 40.642C57.3091 39.9518 58.0581 38.9392 58.4244 37.768H55.2723C55.144 38.0127 54.9734 38.2324 54.7684 38.417C54.2237 38.8649 53.534 39.0959 52.8307 39.0659C52.1547 39.027 51.5132 38.7533 51.0159 38.2917C50.5187 37.83 50.1966 37.209 50.1048 36.5351H58.5019L58.5277 35.8667Z'
      fill='currentcolor'
    />
    <path d='M3.15213 27.4045H0V41.2528H3.15213V27.4045Z' fill='currentcolor' />
    <path
      d='M11.5557 30.169C10.6144 30.1542 9.69862 30.4762 8.972 31.0775C8.65849 31.3222 8.39339 31.6238 8.19043 31.9666H8.08708V30.5H5.16748V41.2528H8.10646V35.5552C8.08017 35.2008 8.125 34.8446 8.23829 34.508C8.35158 34.1713 8.53102 33.8609 8.76596 33.5952C9.00091 33.3295 9.28657 33.1139 9.60601 32.9612C9.92545 32.8085 10.2722 32.7219 10.6256 32.7063C11.9949 32.7063 12.8347 33.5305 12.8347 35.1334V41.2528H15.7736V34.7116C15.7736 31.7329 14.1976 30.169 11.5751 30.169'
      fill='currentcolor'
    />
    <path
      d='M62.6163 15.7951C58.9992 10.3527 53.9555 6.02034 48.042 3.27635C42.1285 0.532362 35.5753 -0.51653 29.1058 0.245481C22.6363 1.00749 16.5023 3.55076 11.3809 7.5945C6.25952 11.6382 2.35 17.0252 0.0839844 23.1605H3.78515C5.99631 17.8396 9.59552 13.2168 14.2033 9.77924C18.8111 6.34171 24.2567 4.21693 29.966 3.62891C35.6754 3.04088 41.4368 4.0114 46.643 6.43815C51.8492 8.86489 56.3072 12.6579 59.547 17.4174C62.7867 22.1768 64.6882 27.7263 65.051 33.4808C65.4138 39.2352 64.2244 44.9814 61.6082 50.1134C58.992 55.2455 55.046 59.5733 50.1862 62.6405C45.3263 65.7078 39.7328 67.4008 33.9952 67.5412V71C40.3262 70.8611 46.5037 69.0156 51.8817 65.6567C57.2596 62.2977 61.6403 57.5486 64.5657 51.9062C67.491 46.2637 68.8535 39.9352 68.5105 33.5826C68.1676 27.23 66.1319 21.0866 62.6163 15.7951Z'
      fill='#0D66FF'
    />
  </SignSvg>
)
