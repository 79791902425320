import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const TextFieldWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: ${({ width }) => width || '168'}px;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`

const TextFieldInner = styled.div`
  position: relative;
  display: flex;
`

const TextFieldLabelWrapper = styled.div`
  display: flex;
`

const TextFieldLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const TextFieldInput = styled.input`
  min-width: 1%;
  width: 100%;
  min-height: 32px;
  background-color: ${({ theme, colorVariant }) =>
    colorVariant === 'dark' ? theme.colors.primary3 : theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.colors.red : 'transparent')};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  padding: 6px 32px;
  margin: 0;
  appearance: none;
  resize: vertical;
  -moz-appearance: textfield;
  text-align: center;

  ${({ withIcon, withUnits }) =>
    (withIcon || withUnits) &&
    css`
      padding-right: 32px;
    `}

  ${({ withPassToggle }) =>
    withPassToggle &&
    css`
      padding-right: 32px;
    `}

  transition: background-color 0.16s linear, border-color 0.16s linear;

  &::-webkit-search-decoration {
    display: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &::placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blueAlt};
    outline: none;
  }
`

const TextFieldError = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

const SteppedInputBtn = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;

  ${({ isIncrease }) =>
    isIncrease &&
    css`
      right: 0;
    `}

  ${({ isDecrease }) =>
    isDecrease &&
    css`
      left: 0;
    `}

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: currentcolor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.5;
  transition: opacity 0.16s linear;

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `};

  &:before {
    font-size: 16px;

    ${({ isIncrease }) =>
      isIncrease &&
      css`
        content: '\\e938';
      `}

    ${({ isDecrease }) =>
      isDecrease &&
      css`
        content: '\\e934';
      `}
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

export const SteppedInput = ({
  id,
  name,
  value,
  label,
  inputProps,
  onChange,
  autoFocus,
  disabled,
  error,
  width,
  fullWidth,
  readOnly,
  required,
  isInline,
  colorVariant,
  min,
  max,
  step
}) => {
  const handleChange = (val) => {
    const nextVal = val

    if (isNaN(nextVal)) return
    if ((min || min === 0) && nextVal < min) return
    if (max && nextVal > max) return

    return onChange(nextVal)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      e.preventDefault()
      e.stopPropagation()

      if (max && value === max) return

      return onChange(Number(value) + Number(step))
    } else if (e.keyCode === 40) {
      e.preventDefault()
      e.stopPropagation()

      if ((min || min === 0) && value === min) return

      return onChange(Number(value) - Number(step))
    }
  }

  return (
    <TextFieldWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
    >
      {label && (
        <TextFieldLabelWrapper>
          {label && <TextFieldLabel>{label}</TextFieldLabel>}
        </TextFieldLabelWrapper>
      )}

      <TextFieldInner>
        <SteppedInputBtn
          type='button'
          isDecrease
          onClick={() => handleChange(Number(value) - Number(step))}
          disabled={disabled || readOnly}
        >
          decrease
        </SteppedInputBtn>
        <TextFieldInput
          id={id}
          name={name}
          type='text'
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          required={required}
          colorVariant={colorVariant}
          disabled={disabled}
          readOnly={readOnly}
          hasError={error}
          {...inputProps}
        />
        <SteppedInputBtn
          type='button'
          isIncrease
          onClick={() => handleChange(Number(value) + Number(step))}
          disabled={disabled || readOnly}
        >
          increase
        </SteppedInputBtn>
      </TextFieldInner>
      {error && <TextFieldError>{error}</TextFieldError>}
    </TextFieldWrapper>
  )
}

SteppedInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInline: PropTypes.bool,
  colorVariant: PropTypes.oneOf(['light', 'dark']),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
}

SteppedInput.defaultProps = {
  id: '',
  name: '',
  value: '',
  label: '',
  inputProps: null,
  onChange: () => {},
  autoFocus: false,
  disabled: false,
  error: '',
  width: null,
  fullWidth: false,
  readOnly: false,
  required: false,
  isInline: false,
  colorVariant: 'dark',
  min: null,
  max: null,
  step: 1
}
