import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const getColorVariant = (theme, variant) => {
  switch (variant) {
    case 'success':
      return theme.colors.green

    case 'warning':
      return theme.colors.yellow

    case 'danger':
      return theme.colors.red

    case 'primary':
      return theme.colors.secondary

    case 'info':
      return theme.colors.blueAlt
  }
}

const StatusIndicatorStyled = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  vertical-align: middle;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme, variant }) =>
      getColorVariant(theme, variant)};
    transition: background-color 0.16s linear;
  }
`

export const StatusIndicator = ({ variant, marginLeft, marginRight }) => (
  <StatusIndicatorStyled
    variant={variant}
    marginLeft={marginLeft}
    marginRight={marginRight}
  />
)

StatusIndicator.propTypes = {
  variant: PropTypes.oneOf(['success', 'warning', 'danger', 'primary', 'info'])
}

StatusIndicator.defaultProps = {
  variant: 'success'
}
