import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import { PlanPoint, PlanCluster } from '../PlanPoint'

const PlanWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  overflow: hidden;
  cursor: grab;
  user-select: none;
`

const PlanContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;

  ${({ isMoving }) =>
    isMoving &&
    css`
      cursor: grabbing;
    `}
`

export const Plan = ({
  width,
  height,
  withZoom,
  points,
  containerForwardRef,
  bgSrc,
  bgWidth,
  bgHeight,
  onDrop
}) => {
  const [isMoving, setIsMoving] = useState(false)
  const [pos, setPos] = useState({ offsetX: 0, offsetY: 0 })

  const wrapperRef = useRef(null)
  const containerRef = useRef(null)

  const onMouseDown = (e) => {
    const el = containerRef.current
    const wrapperEl = wrapperRef.current

    const offsetX =
      e.clientX -
      el.getBoundingClientRect().left +
      wrapperEl.getBoundingClientRect().left

    const offsetY =
      e.clientY -
      el.getBoundingClientRect().top +
      wrapperEl.getBoundingClientRect().top

    setPos((initPos) => ({ ...initPos, offsetX, offsetY }))

    setIsMoving(true)
  }

  const onMouseUp = () => {
    setIsMoving(false)
  }

  const handleZoom = (factor) => {
    const containerEl = containerRef.current
    const containerRect = containerEl.getBoundingClientRect()
    const wrapperElRect = wrapperRef.current.getBoundingClientRect()

    const currentWidth = containerRect.width
    const currentHeight = containerRect.height

    const scale = factor > 0 ? 1.1 : 0.9

    const nextContainerWidth = currentWidth * scale
    const nextContainerHeight = currentHeight * scale

    if (
      nextContainerWidth >= wrapperElRect.width &&
      nextContainerHeight >= wrapperElRect.height
    ) {
      containerEl.style.width = `${nextContainerWidth}px`
      containerEl.style.height = `${nextContainerHeight}px`
    }

    if (
      containerRect.top + nextContainerHeight <
      wrapperElRect.top + wrapperElRect.height
    ) {
      let nextTop = (wrapperElRect.height - nextContainerHeight) / 2
      if (nextTop > 0) nextTop = 0

      containerEl.style.top = `${nextTop}px`
    }

    if (
      containerRect.left + nextContainerWidth <
      wrapperElRect.left + wrapperElRect.width
    ) {
      let nextLeft = (wrapperElRect.width - nextContainerWidth) / 2
      if (nextLeft > 0) nextLeft = 0

      containerEl.style.left = `${nextLeft}px`
    }
  }

  useEffect(() => {
    if (!bgSrc) return

    containerForwardRef.current = containerRef.current

    const containerEl = containerRef.current
    const wrapperElRect = wrapperRef.current.getBoundingClientRect()

    if (bgWidth <= wrapperElRect.width) {
      const nextLeft = (wrapperElRect.width - bgWidth) / 2
      containerEl.style.left = `${nextLeft}px`
    }

    if (bgHeight <= wrapperElRect.height) {
      const nextTop = (wrapperElRect.height - bgHeight) / 2

      containerEl.style.top = `${nextTop}px`
    }

    containerEl.style.width = `${bgWidth}px`
    containerEl.style.height = `${bgHeight}px`
  }, [bgSrc])

  useEffect(() => {
    const handleMove = (e) => {
      if (!isMoving) return

      const el = containerRef.current
      const elRect = el.getBoundingClientRect()
      const wrapperElRect = wrapperRef.current.getBoundingClientRect()

      let nextLeft = e.pageX - pos.offsetX
      let nextTop = e.pageY - pos.offsetY

      if (
        elRect.width >= wrapperElRect.width ||
        elRect.height >= wrapperElRect.height
      ) {
        if (nextTop + elRect.height < wrapperElRect.height)
          nextTop = wrapperElRect.height - elRect.height

        if (nextLeft + elRect.width < wrapperElRect.width)
          nextLeft = wrapperElRect.width - elRect.width
      }

      if (nextLeft >= 0) nextLeft = 0
      if (nextTop >= 0) nextTop = 0

      el.style.left = `${nextLeft}px`
      el.style.top = `${nextTop}px`
    }

    const handleStop = () => setIsMoving(false)

    if (isMoving) {
      document.body.addEventListener('mousemove', handleMove, false)
      document.body.addEventListener('mouseup', handleStop, false)
    } else {
      document.body.removeEventListener('mousemove', handleMove, false)
      document.body.removeEventListener('mouseup', handleStop, false)
    }

    return () => {
      document.body.removeEventListener('mousemove', handleMove, false)
      document.body.removeEventListener('mouseup', handleStop, false)
    }
  }, [isMoving])

  return (
    <PlanWrapper
      width={width}
      height={height}
      ref={wrapperRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onDragOver={(e) => {
        e.preventDefault()
      }}
      onDrop={onDrop}
    >
      {withZoom && <PlanZoomControl handleZoom={handleZoom} />}

      <PlanContainer isMoving={isMoving} ref={containerRef}>
        {bgSrc && <PlanBackground src={bgSrc} />}
        {points && points}
      </PlanContainer>
    </PlanWrapper>
  )
}

Plan.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  withZoom: PropTypes.bool,
  points: PropTypes.node,
  containerForwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  bgSrc: PropTypes.string
}

Plan.defaultProps = {
  width: '',
  height: '',
  withZoom: false,
  points: null,
  containerForwardRef: null,
  bgSrc: ''
}

const PlanBackground = styled.img`
  pointer-events: none;
`

const PlanZoomControlWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
`

const PlanZoomBtnIn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px 4px 0 0;
  color: #122135;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:after {
    content: '\\e938';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
`

const PlanZoomBtnOut = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0 0 4px 4px;
  color: #122135;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid #eaeaea;
  cursor: pointer;

  &:after {
    content: '\\e934';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
`

const PlanZoomControl = ({ handleZoom }) => {
  return (
    <PlanZoomControlWrapper>
      <PlanZoomBtnIn type='button' onClick={() => handleZoom(1)}>
        zoom in
      </PlanZoomBtnIn>
      <PlanZoomBtnOut type='button' onClick={() => handleZoom(-1)}>
        zoom out
      </PlanZoomBtnOut>
    </PlanZoomControlWrapper>
  )
}

export const PlanComponent = ({
  width,
  height,
  withZoom,
  bgSrc,
  bgWidth,
  bgHeight,
  planDefaultData,
  onChange
}) => {
  const containerRef = useRef(null)
  const crtRef = useRef(null)

  const [planData, setPlanData] = useState(planDefaultData)
  const [isMoving, setIsMoving] = useState(false)
  const [isMovingEl, setIsMovingEl] = useState({})

  let canDrop = false
  let pos = { x: 0, y: 0 }

  const handlePrepareDrag = (e, crtSize) => {
    e.stopPropagation()

    if (isMoving) return

    const el = e.target

    const crt = el.cloneNode(true)

    crtRef.current = crt
    crt.style.transform = 'translate(0,0)'
    crt.style.position = 'absolute'
    crt.style.top = '-100vh'
    crt.style.right = '-100vh'

    document.body.appendChild(crt)

    e.dataTransfer.setDragImage(crt, crtSize, crtSize)

    el.style.opacity = 0.2

    setIsMoving(el)
  }

  const onDragStart = (e, point, clusterId) => {
    handlePrepareDrag(e, 16)
    setIsMovingEl({ point, clusterId })
  }

  const onClusterDragStart = (e, cluster) => {
    e.stopPropagation()

    handlePrepareDrag(e, 32)
    setIsMovingEl({ cluster })
  }

  const onDrag = (e) => {
    e.stopPropagation()

    if (!isMoving) return

    pos = {
      x: e.clientX,
      y: e.clientY
    }
  }

  const onDrop = (e) => {
    e.stopPropagation()

    if (!isMoving) return

    if (containerRef.current.contains(e.target)) canDrop = true

    pos = {
      x: e.clientX,
      y: e.clientY
    }
  }

  const handleDragEnd = (e) => {
    e.stopPropagation()

    if (!isMoving) return

    const el = isMoving
    const containerRect = containerRef.current.getBoundingClientRect()

    const nextLeft = pos.x - containerRect.left
    const nextTop = pos.y - containerRect.top

    const w = containerRect.width
    const h = containerRect.height

    const pX = (nextLeft / w) * 100
    const pY = (nextTop / h) * 100

    el.style.opacity = 1
    setIsMoving()

    crtRef.current.remove()

    const nextP = { pX, pY }

    return nextP
  }

  const onDragEnd = (e) => {
    const { pX, pY } = handleDragEnd(e)

    const nextPlanData = { ...planData }
    const nextPointIndex = nextPlanData.points.findIndex(
      (p) => p.id === isMovingEl.point.id
    )

    if (!canDrop) {
      setIsMovingEl()
      return
    }

    const nextPoint = isMovingEl.point
    nextPoint.position = [`${pY}%`, `${pX}%`]
    nextPlanData.points[nextPointIndex] = nextPoint

    if (isMovingEl.clusterId) {
      nextPlanData.points.push(nextPoint)

      const prevCluster = nextPlanData.clusters.find(
        (c) => c.id === isMovingEl.clusterId
      )
      prevCluster.points = prevCluster.points.filter(
        (p) => p.id !== isMovingEl.point.id
      )

      const { points: nextPoints, clusters: nextClusters } = cleanupCluster(
        prevCluster.id,
        nextPlanData
      )
      nextPlanData.points = nextPoints
      nextPlanData.clusters = nextClusters
    }

    setPlanData(nextPlanData)
    setIsMovingEl()
  }

  const onClusterDragEnd = (e) => {
    const { pX, pY } = handleDragEnd(e)

    const nextPlanData = { ...planData }
    const nextClusterIndex = nextPlanData.clusters.findIndex(
      (c) => c.id === isMovingEl.cluster.id
    )

    if (!canDrop) {
      setIsMovingEl()
      return
    }

    const nextCluster = isMovingEl.cluster
    nextCluster.position = [`${pY}%`, `${pX}%`]
    nextPlanData.clusters[nextClusterIndex] = nextCluster

    setPlanData(nextPlanData)
    setIsMovingEl()
  }

  const handleClusterDrop = (e, clusterId) => {
    e.stopPropagation()
    e.preventDefault()

    if (!isMovingEl.point) return

    const nextPlanData = { ...planData }
    const nextCluster = nextPlanData.clusters.find((c) => c.id === clusterId)

    nextCluster.points.push(isMovingEl.point)

    nextPlanData.points = nextPlanData.points.filter(
      (p) => p.id !== isMovingEl.point.id
    )

    if (isMovingEl.clusterId) {
      const prevCluster = nextPlanData.clusters.find(
        (c) => c.id === isMovingEl.clusterId
      )
      prevCluster.points = prevCluster.points.filter(
        (p) => p.id !== isMovingEl.point.id
      )

      const { points: nextPoints, clusters: nextClusters } = cleanupCluster(
        prevCluster.id,
        nextPlanData
      )
      nextPlanData.points = nextPoints
      nextPlanData.clusters = nextClusters
    }

    setPlanData(nextPlanData)
    setIsMoving()
    setIsMovingEl()
  }

  const handlePointDrop = (e, targetPoint) => {
    e.stopPropagation()
    e.preventDefault()

    if (!isMovingEl.point || targetPoint.id === isMovingEl.point.id) return

    const targetPos = targetPoint.position

    const nextCluster = {
      id: uuid(),
      position: targetPos,
      points: [targetPoint, isMovingEl.point]
    }

    const nextPlanData = { ...planData }
    nextPlanData.clusters.push(nextCluster)
    nextPlanData.points = nextPlanData.points.filter(
      (p) => ![targetPoint.id, isMovingEl.point.id].includes(p.id)
    )

    if (isMovingEl.clusterId) {
      const prevCluster = nextPlanData.clusters.find(
        (c) => c.id === isMovingEl.clusterId
      )
      prevCluster.points = prevCluster.points.filter(
        (p) => p.id !== isMovingEl.point.id
      )

      const { points: nextPoints, clusters: nextClusters } = cleanupCluster(
        prevCluster.id,
        nextPlanData
      )
      nextPlanData.points = nextPoints
      nextPlanData.clusters = nextClusters
    }

    setPlanData(nextPlanData)
    setIsMoving()
    setIsMovingEl()
  }

  const cleanupCluster = (clusterId, data) => {
    const nextPlanData = { ...data }
    const prevCluster = nextPlanData.clusters.find((c) => c.id === clusterId)

    if (prevCluster.points.length === 1) {
      const nextSinglePoint = {
        ...prevCluster.points[0],
        position: prevCluster.position
      }

      nextPlanData.points = [...nextPlanData.points, nextSinglePoint]
      nextPlanData.clusters = nextPlanData.clusters.filter(
        (c) => c.id !== clusterId
      )
    }

    return nextPlanData
  }

  useEffect(() => {
    onChange(planData)
  }, [planData])

  return (
    <Plan
      width={width}
      height={height}
      containerForwardRef={containerRef}
      withZoom={withZoom}
      bgSrc={bgSrc}
      bgWidth={bgWidth}
      bgHeight={bgHeight}
      onDrop={onDrop}
      points={
        <React.Fragment>
          {planData.points &&
            planData.points.map((p) => (
              <PlanPoint
                key={p.id}
                icon={p.icon}
                tooltip={p.tooltip}
                position={p.position}
                bgVariant={p.bgVariant}
                colorVariant={p.colorVariant}
                eventVariant={p.eventVariant}
                isMuted={p.isMuted}
                isSelected={p.isSelected}
                onDragStart={(e) => onDragStart(e, p)}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onDrop={(e) => handlePointDrop(e, p)}
                draggable
              />
            ))}

          {planData.clusters &&
            planData.clusters.map((c) => (
              <PlanCluster
                key={c.id}
                position={c.position}
                onDrop={(e) => handleClusterDrop(e, c.id)}
                onDragStart={(e) => onClusterDragStart(e, c)}
                onDrag={onDrag}
                onDragEnd={onClusterDragEnd}
                draggable
              >
                {c.points.map((p) => (
                  <PlanPoint
                    key={p.id}
                    icon={p.icon}
                    tooltip={p.tooltip}
                    bgVariant={p.bgVariant}
                    colorVariant={p.colorVariant}
                    eventVariant={p.eventVariant}
                    isMuted={p.isMuted}
                    isSelected={p.isSelected}
                    onDragStart={(e) => onDragStart(e, p, c.id)}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    draggable
                  />
                ))}
              </PlanCluster>
            ))}
        </React.Fragment>
      }
    />
  )
}
