import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const WizardStepStyled = styled.div`
  display: flex;
  align-items: center;

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  & + & {
    margin-top: 12px;
  }
`

const getCountStyle = (theme, currentState, bgVariant) => {
  switch (currentState) {
    case 'default':
      return css`
        background-color: ${theme.colors.primary2};
        color: ${rgba(theme.colors.secondary, 0.5)};
      `

    case 'current':
      return css`
        opacity: 0.75;
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.primary1};
      `

    case 'current-active':
    case 'done-active':
      return css`
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.primary1};
      `

    case 'done':
      return css`
        background-color: ${theme.colors.green};
        color: ${theme.colors.primary1};
      `
  }
}

const WizardStepCount = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 8px;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 15px;

  ${({ theme, currentState, bgVariant }) =>
    getCountStyle(theme, currentState, bgVariant)};

  transition: background-color 0.16s linear, color 0.16s linear;

  ${({ currentState }) =>
    (currentState === 'done' || currentState === 'done-active') &&
    css`
      &:before {
        content: '\\e911';
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    `}
`

const getLabelColor = (theme, currentState) => {
  switch (currentState) {
    case 'default':
      return css`
        color: ${rgba(theme.colors.secondary, 0.5)};
      `

    case 'current':
      return css`
        color: ${rgba(theme.colors.secondary, 0.75)};
      `

    case 'current-active':
      return css`
        color: ${theme.colors.secondary};
      `

    case 'done':
      return css`
        color: ${rgba(theme.colors.secondary, 0.75)};
      `
  }
}

const WizardStepLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  ${({ theme, currentState }) => getLabelColor(theme, currentState)};
  transition: color 0.16s linear;
`

export const WizardStep = ({
  count,
  label,
  currentState,
  bgVariant,
  onClick
}) => (
  <WizardStepStyled onClick={onClick}>
    <WizardStepCount currentState={currentState} bgVariant={bgVariant}>
      {!['done', 'done-active'].includes(currentState) && count}
    </WizardStepCount>
    <WizardStepLabel currentState={currentState}>{label}</WizardStepLabel>
  </WizardStepStyled>
)

WizardStep.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  currentState: PropTypes.oneOf([
    'default',
    'current',
    'current-active',
    'done',
    'done-active'
  ]),
  bgVariant: PropTypes.oneOf(['light', 'dark']),
  onClick: PropTypes.func
}

WizardStep.defaultProps = {
  count: 1,
  label: '',
  currentState: 'default',
  bgVariant: 'dark',
  isSelected: false,
  onClick: null
}
