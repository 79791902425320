export const getIcon = (icon) => {
  switch (icon) {
    case '4k':
      return '\\e900'

    case 's-forward':
      return '\\e901'

    case 's-rewind':
      return '\\e902'

    case 'addevent':
      return '\\e903'

    case 'bottom-left':
      return '\\e904'

    case 'bottom-right':
      return '\\e905'

    case 'bottom':
      return '\\e906'

    case 'brackets-alt':
      return '\\e907'

    case 'brackets-left':
      return '\\e908'

    case 'brackets-right':
      return '\\e909'

    case 'brackets':
      return '\\e90a'

    case 'camera-alt':
      return '\\e90b'

    case 'camera':
      return '\\e90c'

    case 'control-camera':
      return '\\e90d'

    case 'cut':
      return '\\e90e'

    case 'fast-forward':
      return '\\e90f'

    case 'fast-rewind':
      return '\\e910'

    case 'fullsize':
      return '\\e911'

    case 'hq':
      return '\\e912'

    case 'left':
      return '\\e913'

    case 'lq':
      return '\\e914'

    case 'pause':
      return '\\e915'

    case 'play':
      return '\\e916'

    case 'replay':
    case 'arrow-home':
      return '\\e917'

    case 'right':
      return '\\e918'

    case 'save':
      return '\\e919'

    case 'screenfullsize':
      return '\\e91a'

    case 'screenshot':
      return '\\e91b'

    case 'settings':
      return '\\e91c'

    case 'smallsize':
      return '\\e91d'

    case 'top-left':
      return '\\e91f'

    case 'top-right':
      return '\\e920'

    case 'top':
      return '\\e921'

    case 'trash':
      return '\\e922'

    case 'volume-down':
      return '\\e923'

    case 'volume-mute':
      return '\\e924'

    case 'volume-off':
      return '\\e925'

    case 'volume-up':
      return '\\e926'

    case 'zoom-in':
      return '\\e928'

    case 'zoom-out':
      return '\\e929'

    case 'arrow-down-left':
      return '\\e91e'

    case 'arrow-down-right':
      return '\\e927'

    case 'arrow-down':
      return '\\e92a'

    case 'arrow-left':
      return '\\e92b'

    case 'arrow-right':
      return '\\e92c'

    case 'arrow-up-left':
      return '\\e92d'

    case 'arrow-up-right':
      return '\\e92e'

    case 'arrow-up':
      return '\\e92f'

    case 'refresh':
      return '\\e930'

    default:
      return ''
  }
}

export const iconStyle = `
  font-family: 'player-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const getHorizontalPosition = (align) => {
  switch (align) {
    case 'left':
      return `
        left: 4px;
      `

    case 'right':
      return `
        right: 4px;
      `
  }
}

export const getOpenState = `
  visibility: visible;
  opacity: 1;
  transform: translateY(-8px);
  transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0s;
`

export const getMarkAlign = (align) => {
  switch (align) {
    case 'left':
      return `
        margin-left: -10px;

        &:after {
          left: 8px;
        }
      `

    case 'right':
      return `
        margin-left: -224px;

        &:after {
          right: 8px;
        }
      `

    case 'center':
      return `
        margin-left: -120px;

        &:after {
          left: 112px;
        }
      `
  }
}
