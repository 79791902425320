import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const LabelGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const LabelGroup = ({ children }) => (
  <LabelGroupWrapper>{children}</LabelGroupWrapper>
)

LabelGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

LabelGroup.defaultProps = {
  children: null
}

const StyledLabel = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary3};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 8px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  border: none;
  margin: 0;
  max-width: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
    `}

  transition: background-color 0.16s linear, color 0.16s linear;

  ${({ as, theme, isActive }) =>
    ['button', 'a'].includes(as) &&
    css`
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: ${isActive
            ? rgba(theme.colors.white, 0.75)
            : rgba(theme.colors.secondary, 0.75)};
        }
      }

      &:active,
      &:focus {
        outline: none;
      }
    `}

  ${LabelGroupWrapper} > & {
    border-radius: 0;
  }

  ${LabelGroupWrapper} > &:first-child {
    border-radius: 10px 0 0 10px;
  }

  ${LabelGroupWrapper} > &:last-child {
    border-radius: 0 10px 10px 0;
  }

  ${LabelGroupWrapper} > & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`

export const Label = ({ label, isActive, asComponent, ...rest }) => (
  <StyledLabel isActive={isActive} as={asComponent} {...rest}>
    {label}
  </StyledLabel>
)

Label.propTypes = {
  label: PropTypes.any,
  isActive: PropTypes.bool,
  asComponent: PropTypes.oneOf(['span', 'button', 'a', 'div'])
}

Label.defaultProps = {
  label: '',
  isActive: false,
  asComponent: 'span'
}
