import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { TooltipContextConsumer } from '../TooltipContext/TooltipContext'

const getTextVariant = (theme, variant) => {
  switch (variant) {
    case 'title-lg':
      return {
        fontFamily: theme.fonts.regular,
        fontSize: '40px',
        lineHeight: '48px'
      }

    case 'title':
      return {
        fontFamily: theme.fonts.medium,
        fontSize: '20px',
        lineHeight: '24px'
      }

    case 'body':
      return {
        fontFamily: theme.fonts.medium,
        fontSize: '16px',
        lineHeight: '20px'
      }

    case 'body-sm':
      return {
        fontFamily: theme.fonts.medium,
        fontSize: '14px',
        lineHeight: '16px'
      }

    case 'caps':
      return {
        fontFamily: theme.fonts.medium,
        fontSize: '12px',
        lineHeight: '14px',
        textTransform: 'uppercase'
      }

    case 'description':
      return {
        fontFamily: theme.fonts.regular,
        fontSize: '12px',
        lineHeight: '14px'
      }
  }
}

const getTextColor = (theme, color) => {
  switch (color) {
    case 'primary':
      return {
        color: theme.colors.secondary
      }

    case 'secondary':
      return {
        color: rgba(theme.colors.secondary, 0.5)
      }

    case 'info':
      return {
        color: theme.colors.blueAlt
      }

    case 'danger':
      return {
        color: theme.colors.red
      }

    case 'warning':
      return {
        color: theme.colors.yellow
      }

    case 'success':
      return {
        color: theme.colors.green
      }

    case 'black':
      return {
        color: theme.colors.blackText
      }

    case 'white':
      return {
        color: theme.colors.white
      }

    case 'grey':
      return {
        color: rgba(13, 22, 34, 0.5)
      }

    case 'muted':
      return {
        color: rgba(theme.colors.secondary, 0.25)
      }

    case 'light':
      return {
        color: rgba(theme.colors.secondary, 0.75)
      }

    case 'currentcolor':
      return {
        color: 'currentcolor'
      }
  }
}

const StyledText = styled.span`
  ${({ theme, variant }) => getTextVariant(theme, variant)};

  font-weight: normal;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;

  ${({ theme, color }) => getTextColor(theme, color)};

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  & a {
    color: inherit;
    border-bottom: 1px solid
      ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    outline: none;
    transition: border-color 0.16s linear;
  }

  @media (hover: hover) {
    & a:hover {
      border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
    }
  }
`

const StyledScrollText = styled.span`
  ${({ theme, variant }) => getTextVariant(theme, variant)};

  font-weight: normal;
  padding: 0;
  margin: 0;
  white-space: pre-line;

  display: inline-block;
  overflow-y: auto;
  max-height: 50px;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  word-wrap: break-word;

  ${({ theme, color }) => getTextColor(theme, color)};

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  & a {
    color: inherit;
    border-bottom: 1px solid
      ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    outline: none;
    transition: border-color 0.16s linear;
  }

  @media (hover: hover) {
    & a:hover {
      border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
    }
  }
`

export const Text = ({
  children,
  variant,
  component,
  color,
  align,
  scroll
}) => {
  const textRef = useRef(null)
  const [allowTooltip, setAllowTooltip] = useState(false)

  useEffect(() => {
    if (
      !allowTooltip &&
      textRef?.current?.offsetWidth < textRef?.current?.scrollWidth
    ) {
      setAllowTooltip(true)
    }
  }, [textRef?.current, children])

  if (scroll) {
    return (
      <StyledScrollText
        ref={textRef}
        as={component}
        variant={variant}
        color={color}
        align={align}
      >
        {children}
      </StyledScrollText>
    )
  }

  return (
    <TooltipContextConsumer>
      {({ showTooltip }) => (
        <StyledText
          ref={textRef}
          as={component}
          variant={variant}
          color={color}
          align={align}
          onMouseEnter={(event) => {
            if (allowTooltip) {
              showTooltip(true, children, event.clientX, event.clientY)
            }
          }}
          onMouseLeave={() => showTooltip(false)}
        >
          {children}
        </StyledText>
      )}
    </TooltipContextConsumer>
  )
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  variant: PropTypes.oneOf([
    'title-lg',
    'title',
    'body',
    'body-sm',
    'caps',
    'description'
  ]),
  component: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'p',
    'span',
    'div'
  ]),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'danger',
    'warning',
    'success',
    'black',
    'white',
    'grey',
    'muted',
    'light',
    'currentcolor'
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  scroll: PropTypes.oneOf([true, false])
}

Text.defaultProps = {
  children: null,
  variant: 'body',
  component: 'span',
  color: 'primary',
  align: 'left',
  scroll: false
}
