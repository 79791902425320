import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const InfoBoxWrapper = styled.span`
  position: relative;
  cursor: help;
`

const InfoBoxRoot = styled.div`
  display: flex;
  position: ${({ positionFixed }) => (positionFixed ? 'fixed' : 'absolute')};
  z-index: 10;
  transform: translateY(0);
  width: 240px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    border-width: 8px;
    border-style: solid;
    border-color: transparent;
  }

  ${({ verticalAlignment }) => getVerticalPosition(verticalAlignment)};

  ${({ horizontalAlignment }) => getHorizontalPosition(horizontalAlignment)};

  ${({ isOpen, verticalAlignment }) => isOpen && openState(verticalAlignment)};
`

const openState = (verticalAlignment) => {
  return `
    visibility: visible;
    opacity: 1;
    transform: translateY(${verticalAlignment === 'top' ? '-16px' : '16px'});
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  `
}

const getVerticalPosition = (verticalAlignment) => {
  switch (verticalAlignment) {
    case 'top':
      return css`
        bottom: 100%;

        &:after {
          bottom: -16px;
          border-color: ${({ theme }) => theme.colors.white} transparent
            transparent transparent;
        }
      `

    case 'bottom':
      return css`
        top: 100%;

        &:after {
          top: -16px;
          border-color: transparent transparent
            ${({ theme }) => theme.colors.white} transparent;
        }
      `
  }
}

const getHorizontalPosition = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case 'left':
      return css`
        left: -20px;
        justify-content: flex-start;

        &:after {
          left: 20px;
        }
      `

    case 'right':
      return css`
        right: -20px;
        justify-content: flex-end;

        &:after {
          right: 20px;
        }
      `

    case 'center':
      return css`
        right: 50%;
        left: 50%;
        margin-left: -120px;
        justify-content: center;
      `
  }
}

const InfoBoxInner = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blackText};
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
`

export const InfoBox = ({
  children,
  infoText,
  verticalAlignment,
  horizontalAlignment,
  positionFixed
}) => {
  const wrapperRef = useRef()
  const boxRef = useRef()

  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)

    if (!positionFixed) return

    const anchorPoint = wrapperRef.current.getBoundingClientRect()

    const newBoxTop =
      verticalAlignment === 'bottom'
        ? anchorPoint.y + anchorPoint.height
        : anchorPoint.y - boxRef.current.getBoundingClientRect().height

    const getNewLeft = () => {
      switch (horizontalAlignment) {
        case 'left':
          return anchorPoint.x - 20

        case 'right':
          return (
            anchorPoint.x +
            anchorPoint.width -
            boxRef.current.getBoundingClientRect().width +
            20
          )

        case 'center':
          return anchorPoint.x + anchorPoint.width / 2
      }
    }

    const newBoxLeft = getNewLeft()

    boxRef.current.style.top = newBoxTop + 'px'
    boxRef.current.style.bottom = 'auto'
    boxRef.current.style.left = newBoxLeft + 'px'
  }

  return (
    <InfoBoxWrapper
      onMouseEnter={handleOpen}
      onMouseLeave={() => setOpen(false)}
      ref={wrapperRef}
    >
      {children}
      <InfoBoxRoot
        isOpen={isOpen}
        verticalAlignment={verticalAlignment}
        horizontalAlignment={horizontalAlignment}
        positionFixed={positionFixed}
        ref={boxRef}
      >
        <InfoBoxInner>{infoText}</InfoBoxInner>
      </InfoBoxRoot>
    </InfoBoxWrapper>
  )
}

InfoBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  infoText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right', 'center']),
  positionFixed: PropTypes.bool
}

InfoBox.defaultProps = {
  children: null,
  infoText: '',
  verticalAlignment: 'top',
  horizontalAlignment: 'center',
  positionFixed: false
}
