import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const getHeaderVariant = (theme, variant) => {
  switch (variant) {
    case 'dark':
      return theme.colors.primary2

    case 'light':
      return theme.colors.primary1

    case 'transparent':
      return 'transparent'
  }
}

const TableStyled = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;

  ${({ isFixed }) =>
    isFixed &&
    css`
      table-layout: fixed;
    `}

  ${({ hasHover, theme }) =>
    hasHover &&
    css`
      & tr:hover td {
        background-color: ${rgba(theme.colors.secondary, 0.05)};
      }

      & tr:hover td:after {
        background-color: transparent;
      }
    `}

  ${({ stickyHeader, bgColor, theme }) =>
    stickyHeader &&
    css`
      border-collapse: separate;

      & th {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: ${({ theme, bgColor }) =>
          getHeaderVariant(theme, bgColor)};
      }
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      & tr td:first-child,
      & tr th:first-child {
        padding-left: 0;
      }

      & tr td:last-child,
      & tr th:last-child {
        padding-right: 0;
      }
    `}
`

export const Table = ({
  children,
  hasHover,
  isFixed,
  stickyHeader,
  bgColor,
  noPadding
}) => (
  <TableStyled
    hasHover={hasHover}
    isFixed={isFixed}
    stickyHeader={stickyHeader}
    bgColor={bgColor}
    noPadding={noPadding}
  >
    {children}
  </TableStyled>
)

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hasHover: PropTypes.bool,
  isFixed: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  bgColor: PropTypes.oneOf(['light', 'dark', 'transparent']),
  noPadding: PropTypes.bool
}

Table.defaultProps = {
  children: null,
  hasHover: false,
  isFixed: false,
  stickyHeader: false,
  bgColor: 'dark',
  noPadding: false
}

const TableHeadStyled = styled.thead`
  display: table-header-group;
`

export const TableHead = ({ children }) => (
  <TableHeadStyled>{children}</TableHeadStyled>
)

TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

TableHead.defaultProps = {
  children: null
}

const TableHeadCellStyled = styled.th`
  display: table-cell;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  vertical-align: ${({ verticalAlign }) => verticalAlign || 'top'};
  white-space: pre;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ indent }) =>
    indent &&
    css`
      &,
      table tr &:first-child {
        padding-left: ${indent}px;
      }
    `}

    ${({ sort }) =>
    sort &&
    css`
      &:after {
        display: inline-block;
        font-size: 12px;
        width: 12px;
        text-align: center;
        vertical-align: middle;

        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    `}

    ${({ sort }) =>
    sort === 'asc' &&
    css`
      &:after {
        content: '\\e930';
      }
    `}

    ${({ sort }) =>
    sort === 'desc' &&
    css`
      &:after {
        content: '\\e95d';
      }
    `}
`

export const TableHeadCell = ({
  children,
  align,
  verticalAlign,
  indent,
  sort,
  ...rest
}) => (
  <TableHeadCellStyled
    align={align}
    verticalAlign={verticalAlign}
    indent={indent}
    sort={sort}
    {...rest}
  >
    {children}
  </TableHeadCellStyled>
)

TableHeadCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  indent: PropTypes.number,
  sort: PropTypes.oneOf(['', 'asc', 'desc'])
}

TableHeadCell.defaultProps = {
  children: null,
  align: 'left',
  verticalAlign: 'top',
  indent: null,
  sortDirection: '',
  onClick: null
}

const TableBodyStyled = styled.tbody`
  display: table-row-group;
`

export const TableBody = ({ children }) => (
  <TableBodyStyled>{children}</TableBodyStyled>
)

TableBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

TableBody.defaultProps = {
  children: null
}

const TableRowStyled = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  background-color: ${({ theme, isSelected }) =>
    isSelected && rgba(theme.colors.secondary, 0.1)};

  ${({ noBorderOnOpen, hasClosedChildren }) =>
    noBorderOnOpen &&
    !hasClosedChildren &&
    css`
      & > td:after {
        background-color: transparent;
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
  `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ isToggle, hasClosedChildren, theme }) =>
    isToggle &&
    css`
      cursor: pointer;

      ${!hasClosedChildren &&
      css`
        & td {
          color: ${theme.colors.secondary};
        }
      `}

      & > td:first-child:before {
        content: '${hasClosedChildren ? '\\25B8' : '\\25BE'}';
        margin-right: 12px;
      }
    `}

  transition: background-color 0.16s linear;

  ${({ isClosed }) =>
    isClosed &&
    css`
      & > td {
        display: none;
      }
    `}

  ${({ indent, isLast }) =>
    indent &&
    css`
      & > td:first-child {
        padding-left: ${indent * 32}px;
      }

      & > td:first-child:after {
        left: ${isLast ? (indent - 1) * 32 : indent * 32}px;
      }
    `}
`

export const TableRow = ({
  children,
  isDisabled,
  isSelected,
  isClosed,
  isToggle,
  hasClosedChildren,
  onClick,
  indent,
  forwardedRef,
  noBorderOnOpen,
  ...rest
}) => (
  <TableRowStyled
    isDisabled={isDisabled}
    isSelected={isSelected}
    isClosed={isClosed}
    isToggle={isToggle}
    hasClosedChildren={hasClosedChildren}
    onClick={onClick}
    ref={forwardedRef}
    indent={indent}
    noBorderOnOpen={noBorderOnOpen}
    {...rest}
  >
    {children}
  </TableRowStyled>
)

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isClosed: PropTypes.bool,
  isToggle: PropTypes.bool,
  hasClosedChildren: PropTypes.bool,
  onClick: PropTypes.func,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  indent: PropTypes.number
}

TableRow.defaultProps = {
  children: null,
  isDisabled: false,
  isSelected: false,
  isClosed: false,
  isToggle: false,
  hasClosedChildren: false,
  onClick: null,
  ref: () => {},
  indent: 0
}

const TableCellStyled = styled.td`
  position: relative;
  display: table-cell;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  padding: 12px;
  vertical-align: ${({ verticalAlign }) => verticalAlign || 'top'};
  white-space: pre;

  ${({ isHandle }) =>
    isHandle &&
    css`
      cursor: move;

      & * {
        pointer-events: none;
      }
    `}

  &:after {
    content: '';
    height: 1px;
    background-color: ${({ theme, noBorder }) =>
      noBorder ? 'transparent' : rgba(theme.colors.secondary, 0.1)};
    position: absolute;
    bottom: -1px;
    right: 0;
    left: 0;
    z-index: 1;
    transition: background-color 0.16s linear;
  }

  ${({ indent }) =>
    indent &&
    css`
      &,
      table tr &:first-child {
        padding-left: ${indent}px;
      }
    `}

  ${({ noVerticalSpace }) =>
    noVerticalSpace &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`

export const TableCell = ({
  children,
  align,
  verticalAlign,
  indent,
  noBorder,
  noVerticalSpace,
  isHandle,
  ...rest
}) => (
  <TableCellStyled
    align={align}
    verticalAlign={verticalAlign}
    indent={indent}
    noBorder={noBorder}
    noVerticalSpace={noVerticalSpace}
    isHandle={isHandle}
    {...rest}
  >
    {children}
  </TableCellStyled>
)

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  indent: PropTypes.number,
  noBorder: PropTypes.bool,
  noVerticalSpace: PropTypes.bool,
  isHandle: PropTypes.bool
}

TableCell.defaultProps = {
  children: null,
  align: 'left',
  verticalAlign: 'top',
  indent: null,
  noBorder: false,
  noVerticalSpace: false,
  isHandle: false
}

export const TableAccordionItem = ({
  children,
  toggle,
  forwardRef,
  isClosed,
  indent = 0,
  defaultClosed = true,
  noBorderOnOpen,
  onClick,
  ...rest
}) => {
  const [isClosedRow, setIsClosedRow] = useState(defaultClosed)

  const handleOpen = () => {
    setIsClosedRow(!isClosedRow);
    if (onClick){
      onClick()
    }
  }

  useEffect(() => {
    if (isClosed) setIsClosedRow(true)
  }, [isClosed])

  return (
    <React.Fragment>
      <TableRow
        forwardedRef={forwardRef}
        onClick={handleOpen}
        isClosed={isClosed}
        indent={indent}
        isToggle={true}
        hasClosedChildren={isClosedRow}
        noBorderOnOpen={noBorderOnOpen}
        {...rest}
      >
        {React.Children.map(toggle, (child) => {
          return React.cloneElement(child, { isClosed: isClosedRow })
        })}
      </TableRow>

      {React.Children.map(children, (child, i) => {
        const count = React.Children.toArray(children).length
        return React.cloneElement(child, {
          isClosed: isClosedRow,
          indent: indent + 1,
          isLast: i === count - 1
        })
      })}
    </React.Fragment>
  )
}

TableAccordionItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  toggle: PropTypes.node,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  isClosed: PropTypes.bool,
  indent: PropTypes.number,
  defaultClosed: PropTypes.bool,
  noBorderOnOpen: PropTypes.bool
}

TableAccordionItem.defaultProps = {
  children: null,
  toggle: {},
  forwardRef: () => {},
  isClosed: false,
  indent: 0,
  defaultClosed: true,
  noBorderOnOpen: false
}

const TableHoverContainerStyled = styled.div`
  opacity: 0;

  tr:hover & {
    opacity: 1;
  }
`

export const TableHoverContainer = ({ children }) => (
  <TableHoverContainerStyled>{children}</TableHoverContainerStyled>
)

TableHoverContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

TableHoverContainer.defaultProps = {
  children: null
}
