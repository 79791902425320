import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

export const PlanCluster = ({
  children,
  bgVariant,
  colorVariant,
  eventVariant,
  isMuted,
  position = [0, 0],
  onDrop,
  onDragStart,
  onDrag,
  onDragEnd,
  draggable,
  openCluster,
  isOpen,
  count
}) => {
  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const clusterRef = useRef()

  const handleMouseDown = (e) => {
    if (draggable) {
      e.stopPropagation()
    }
  }

  return (
    <PlanClusterWrapper
      style={{
        top: position[0],
        left: position[1]
      }}
      isMuted={isMuted}
      onDragOver={onDragOver}
      onDrop={onDrop}
      ref={clusterRef}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      draggable={draggable}
      onMouseDown={handleMouseDown}
    >
      {isOpen ? (
        <React.Fragment>
          <PlanClusterInner>
            {React.Children.map(children, (child, i) => (
              <PlanClusterPointWrapper
                key={i}
                count={children.length}
                bgVariant={bgVariant}
                hasTooltip={child.props.tooltip}
                isMuted={child.props.isMuted}
              >
                <PlanClusterPointWrapperInner>
                  {React.cloneElement(child, { position: ['50%', '50%'] })}
                </PlanClusterPointWrapperInner>
              </PlanClusterPointWrapper>
            ))}
          </PlanClusterInner>

          <PlanClusterCloseBtn
            bgVariant={bgVariant}
            onClick={() => openCluster(false)}
          />
        </React.Fragment>
      ) : (
        <PlanClusterLabel
          count={count}
          bgVariant={bgVariant}
          colorVariant={colorVariant}
          eventVariant={eventVariant}
          onClick={() => openCluster(true)}
        />
      )}
    </PlanClusterWrapper>
  )
}

PlanCluster.propTypes = {
  /**
   * Same prop structure as for PlanPoint
   **/
  bgVariant: PropTypes.oneOf(['default', 'dark']),
  colorVariant: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  eventVariant: PropTypes.oneOf(['warning', 'danger']),
  isMuted: PropTypes.bool,
  /**
   * Point position [x,y].
   **/
  position: PropTypes.array,
  onDrop: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  draggable: PropTypes.bool
}

PlanCluster.defaultProps = {
  bgVariant: 'default',
  colorVariant: 'primary',
  eventVariant: null,
  isMuted: false,
  position: [0, 0],
  onDrop: () => {},
  onDragStart: () => {},
  onDrag: () => {},
  onDragEnd: () => {},
  draggable: false,
  openCluster: () => {},
  isOpen: false,
  count: 0
}

const PlanClusterWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  margin: -36px;

  ${({ isMuted }) =>
    isMuted &&
    css`
      opacity: 0.45;
    `}
`

const PlanClusterInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const getRotations = (count) => {
  let styles = ''
  let rot = -90
  const angle = count <= 6 ? 360 / count : 60
  const maxCount = count <= 6 ? count : 6

  for (let i = 1; i <= maxCount; i++) {
    styles += `
      &:nth-of-type(${i}) {
        z-index: ${count - i};
        transform: rotate(${rot}deg) translate(36px);
      }

      &:nth-of-type(${i}) ${PlanClusterPointWrapperInner} {
        transform: rotate(${rot * -1}deg);
        transform-origin: center center;
      }
    `

    rot = rot + angle
  }

  if (count > 6) {
    for (let i = 7; i <= count; i++) {
      const offset = Math.floor((i - 1) / 6)
      const r = -90 - offset * 30 + (360 / 6) * i
      const d = 30 + 18 * (offset + 1)

      styles += `
        &:nth-of-type(${i}) {
          z-index: ${count - i};
          transform: rotate(${r}deg) translate(${d}px);
        }

        &:nth-of-type(${i}):before {
          width: ${d - 22}px;
        }

        &:nth-of-type(${i}) ${PlanClusterPointWrapperInner} {
          transform: rotate(${r * -1}deg);
          transform-origin: center center;
        }
      `
    }
  }

  return css`
    ${styles}
  `
}

const PlanClusterPointWrapper = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 32px;
  height: 32px;
  margin: -16px;

  ${({ count }) => getRotations(count)};

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${({ theme, bgVariant }) =>
      bgVariant === 'dark' ? theme.colors.blackText : theme.colors.blue};
    position: absolute;
    top: 50%;
    right: 100%;
    z-index: -1;
    margin-top: -1px;

    ${({ isMuted }) =>
      isMuted &&
      css`
        opacity: 0.5;
      `}
  }

  ${({ hasTooltip }) =>
    hasTooltip &&
    css`
      &:hover {
        z-index: 99;
      }
    `}

  ${({ theme, bgVariant, colorVariant }) =>
    getColorVariant(theme, bgVariant, colorVariant)};
`

const PlanClusterPointWrapperInner = styled.div`
  width: 32px;
  height: 32px;
`

const PlanClusterCloseBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: -6px;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme, bgVariant }) =>
    bgVariant === 'dark' ? theme.colors.blackText : theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 9px;

  &:before {
    content: '\\e913';
  }

  &:focus {
    outline: none;
  }
`

const PlanClusterLabel = ({
  count,
  bgVariant,
  eventVariant,
  colorVariant,
  onClick
}) => (
  <PlanClusterLabelStyled
    bgVariant={bgVariant}
    eventVariant={eventVariant}
    colorVariant={colorVariant}
    onClick={onClick}
  >
    {count}
  </PlanClusterLabelStyled>
)

const getColorVariant = (theme, bgVariant, colorVariant) => {
  if (bgVariant === 'dark') {
    switch (colorVariant) {
      case 'primary':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.white
        }

      case 'success':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.white
        }

      case 'danger':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.red
        }

      case 'warning':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.orange
        }
    }
  }

  switch (colorVariant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blue,
        color: theme.colors.white
      }

    case 'success':
      return {
        backgroundColor: theme.colors.green,
        color: theme.colors.white
      }

    case 'danger':
      return {
        backgroundColor: theme.colors.red,
        color: theme.colors.white
      }

    case 'warning':
      return {
        backgroundColor: theme.colors.orange,
        color: theme.colors.white
      }
  }
}

const getEventVariant = (theme, eventVariant) => {
  switch (eventVariant) {
    case 'warning':
      return css`
        animation: pulse-warning 0.8s linear infinite;

        @keyframes pulse-warning {
          0% {
            box-shadow: 0 0 0 0 ${rgba(theme.colors.orange, 0.5)},
              0 0 0 0 ${rgba(theme.colors.orange, 0.5)};
          }
          100% {
            box-shadow: 0 0 0 24px ${rgba(theme.colors.orange, 0)},
              0 0 0 0 ${rgba(theme.colors.orange, 0.85)};
          }
        }
      `

    case 'danger':
      return css`
        animation: pulse-danger 0.8s linear infinite;

        @keyframes pulse-danger {
          0% {
            box-shadow: 0 0 0 0 ${rgba(theme.colors.red, 0.4)},
              0 0 0 0 ${rgba(theme.colors.red, 0.4)};
          }
          100% {
            box-shadow: 0 0 0 24px ${rgba(theme.colors.red, 0)},
              0 0 0 0 ${rgba(theme.colors.red, 0.4)};
          }
        }
      `
  }
}

const PlanClusterLabelStyled = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 15px;
  box-shadow: 0px 0px 8px rgba(13, 22, 35, 0.25);
  ${({ theme, bgVariant, colorVariant }) =>
    getColorVariant(theme, bgVariant, colorVariant)};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    ${({ theme, eventVariant }) =>
      eventVariant && getEventVariant(theme, eventVariant)};
  }

  &:focus {
    outline: none;
  }
`
