import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

/** Temp workaround https://github.com/recharts/recharts/issues/2360#issuecomment-752951994 **/
// import { Text } from 'recharts'
import * as Recharts from 'recharts/umd/Recharts'
/****/

const Text = Recharts.Text

export const ChartWrapper = styled.div`
  user-select: none;

  & .recharts-text.recharts-cartesian-axis-tick-value,
  & .recharts-yAxis.yAxis,
  & .recharts-xAxis.xAxis {
    fill: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    font-size: 10px;
  }

  & .recharts-cartesian-axis-line,
  & .recharts-cartesian-grid-horizontal line,
  & .recharts-cartesian-grid-vertical line {
    stroke: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  }

  & .recharts-tooltip-cursor {
    stroke: #3781ff;
    stroke-width: 2;
  }

  & .recharts-dot {
    stroke-width: 0;
    stroke: transparent;
  }

  & .recharts-brush > rect {
    stroke: transparent;
    fill: ${({ theme }) => theme.colors.primary1};
  }

  & .recharts-brush > rect.recharts-brush-slide {
    fill: ${({ theme }) => theme.colors.secondary};
    fill-opacity: 0.25;
    transition: fill-opacity 0.16s linear;
  }

  & .recharts-brush > rect.recharts-brush-slide:hover {
    fill-opacity: 0.5;
  }

  & .recharts-brush-traveller rect {
    fill: ${({ theme }) => theme.colors.secondary};
    fill-opacity: 0.5;
  }

  & .recharts-brush-traveller rect {
    transition: fill-opacity 0.16s linear;
  }

  &
    .recharts-brush
    .recharts-brush-slide:hover
    ~ .recharts-brush-traveller
    rect,
  & .recharts-brush-traveller:hover rect {
    fill-opacity: 1;
  }

  & .recharts-brush-texts {
    font-size: 0;
  }

  & .recharts-brush-traveller line {
    stroke: ${({ theme }) => theme.colors.primary1};
  }
`

export const CustomizedYTick = ({ payload, y, unit, ...props }) => {
  return props.index !== 0 ? (
    <Text y={y + 12} {...props}>
      {`${payload.value}${unit || ''}`}
    </Text>
  ) : null
}

export const CustomizedXTick = ({
  payload,
  x,
  y,
  unit,
  textAnchor,
  ...props
}) => {
  const isLastVisible = props.visibleTicksCount === props.index + 1

  return (
    <Text
      x={!isLastVisible ? x + 10 : x}
      y={y - 6}
      textAnchor={!isLastVisible ? 'left' : textAnchor}
      {...props}
    >
      {`${payload.value}${unit || ''}`}
    </Text>
  )
}

export const CustomTooltipWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blackText};
  width: 200px;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
  }

  .recharts-tooltip-wrapper-left &:after {
    right: -14px;
    border-color: transparent transparent transparent
      ${({ theme }) => theme.colors.white};
  }

  .recharts-tooltip-wrapper-right &:after {
    left: -14px;
    border-color: transparent ${({ theme }) => theme.colors.white} transparent
      transparent;
  }
`

export const CustomTooltipTitle = styled.div`
  text-align: center;
  margin-bottom: 8px;
`

export const CustomTooltipRow = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`

export const CustomTooltipRowLabel = styled.div`
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
`
