import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledTabPanel = styled.div`
  display: flex;
`

export const TabPanel = ({ children }) => (
  <StyledTabPanel>{children}</StyledTabPanel>
)

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

TabPanel.defaultProps = {
  children: null
}
