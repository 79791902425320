import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const RadioWrapper = styled.label`
  position: relative;
  display: inline-block;
  padding-left: 24px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  cursor: pointer;
  transition: color 0.16s linear;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
      cursor: default;
    `};

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      @media (hover: hover) {
        &:hover {
          color: ${({ theme }) => theme.colors.secondary};
        }
      }
    `}
`

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const RadioIndicator = styled.span`
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  transition: background-color 0.16s linear, color 0.16s linear,
    border-color 0.16s linear;

  &:hover > & {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  input:checked ~ & {
    border: 2px solid ${({ theme }) => theme.colors.blueAlt};
  }

  input:disabled ~ & {
    opacity: 0.25;
  }

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.16s linear;
  }

  input:checked ~ &:before {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const RadioLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
`

export const Radio = ({
  id,
  name,
  label,
  value,
  checked,
  disabled,
  inputProps,
  onChange,
  required,
  readOnly
}) => (
  <RadioWrapper isDisabled={disabled}>
    <RadioInput
      id={id}
      name={name}
      type='radio'
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
      {...inputProps}
    />
    <RadioIndicator />
    {label && <RadioLabel>{label}</RadioLabel>}
  </RadioWrapper>
)

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool
}

Radio.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  checked: false,
  disabled: false,
  inputProps: null,
  onChange: () => {},
  required: false,
  readOnly: false
}
