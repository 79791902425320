import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const getIcon = (icon) => {
  switch (icon) {
    case 'server':
      return '\\e900'

    case 'camera':
      return '\\e92a'

    case 'cctv':
      return '\\e901'

    case 'cctv-temp':
      return '\\e902'

    case 'cctv-switch':
      return '\\e904'

    case 'phone':
      return '\\e903'

    case 'settings':
      return '\\e905'

    case 'alarm':
      return '\\e90d'

    case 'alarm-alt':
      return '\\e906'

    case 'radio':
      return '\\e908'

    case 'turnstile':
      return '\\e907'

    case 'cog':
      return '\\e909'

    case 'water-pump':
      return '\\e90a'

    case 'motor':
      return '\\e90b'

    case 'cog-rotate':
      return '\\e90c'

    case 'barcode':
      return '\\e90e'

    case 'scan':
      return '\\e90f'

    case 'bolt':
      return '\\e910'

    case 'drop':
      return '\\e911'

    case 'drop-alt':
      return '\\e913'

    case 'temperature':
      return '\\e912'

    case 'manometer':
      return '\\e914'

    case 'fire':
      return '\\e915'

    case 'shield':
      return '\\e92d'

    case 'shield-neutral':
      return '\\e916'

    case 'shield-ok':
      return '\\e919'

    case 'shield-restrict':
      return '\\e91a'

    case 'door-open':
      return '\\e917'

    case 'door':
      return '\\e918'

    case 'toggle-on':
      return '\\e91b'

    case 'toggle-off':
      return '\\e91c'

    case 'car':
      return '\\e91d'

    case 'truck':
      return '\\e91e'

    case 'person':
      return '\\e91f'

    case 'count':
      return '\\e920'

    case 'bulb':
      return '\\e921'

    case 'schedule':
      return '\\e922'

    case 'panic':
      return '\\e923'

    case 'gas':
      return '\\e924'

    case 'sos':
      return '\\e925'

    case 'smoke-detector':
      return '\\e926'

    case 'contrast':
      return '\\e927'

    case 'heart':
      return '\\e928'

    case 'building':
      return '\\e929'

    case 'controlzone':
      return '\\e92b'

    case 'stopwatch':
      return '\\e92c'

    case 'user':
      return '\\e92e'
  }
}

const getColorVariant = (theme, bgVariant, colorVariant) => {
  if (bgVariant === 'dark') {
    switch (colorVariant) {
      case 'primary':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.white
        }

      case 'success':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.green
        }

      case 'danger':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.red
        }

      case 'warning':
        return {
          backgroundColor: theme.colors.blackText,
          color: theme.colors.orange
        }
    }
  }

  switch (colorVariant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blue,
        color: theme.colors.white
      }

    case 'success':
      return {
        backgroundColor: theme.colors.green,
        color: theme.colors.white
      }

    case 'danger':
      return {
        backgroundColor: theme.colors.red,
        color: theme.colors.white
      }

    case 'warning':
      return {
        backgroundColor: theme.colors.orange,
        color: theme.colors.white
      }
  }
}

const getEventVariant = (theme, eventVariant) => {
  switch (eventVariant) {
    case 'warning':
      return css`
        animation: pulse-warning 0.8s linear infinite;

        @keyframes pulse-warning {
          0% {
            box-shadow: 0 0 0 0 ${rgba(theme.colors.orange, 0.5)},
              0 0 0 0 ${rgba(theme.colors.orange, 0.5)};
          }
          100% {
            box-shadow: 0 0 0 24px ${rgba(theme.colors.orange, 0)},
              0 0 0 0 ${rgba(theme.colors.orange, 0.85)};
          }
        }
      `

    case 'danger':
      return css`
        animation: pulse-danger 0.8s linear infinite;

        @keyframes pulse-danger {
          0% {
            box-shadow: 0 0 0 0 ${rgba(theme.colors.red, 0.4)},
              0 0 0 0 ${rgba(theme.colors.red, 0.4)};
          }
          100% {
            box-shadow: 0 0 0 24px ${rgba(theme.colors.red, 0)},
              0 0 0 0 ${rgba(theme.colors.red, 0.4)};
          }
        }
      `
  }
}

const PlanPointWrapper = styled.div`
  position: absolute;
  z-index: ${({ showTooltip }) => (showTooltip ? '99' : '1')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 20px;
  box-shadow: 0px 0px 8px rgba(13, 22, 35, 0.25);
  user-select: none;

  ${({ hasTooltip }) =>
    hasTooltip &&
    css`
      cursor: help;
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ theme, bgVariant, colorVariant }) =>
    getColorVariant(theme, bgVariant, colorVariant)};

  ${({ isMuted }) =>
    isMuted &&
    css`
      opacity: 0.45;
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      width: 40px;
      height: 40px;
      font-size: 24px;
    `}

  transform: translate(-50%, -50%);
  transition: width 0.1s linear, height 0.1s linear, font-size 0.1s linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    ${({ theme, eventVariant }) =>
      eventVariant && getEventVariant(theme, eventVariant)};
  }

  ${({ isMoving }) =>
    isMoving &&
    css`
      cursor: grabbing;
      pointer-events: none;
    `}
`

const PlanPointContainer = styled.div`
  position: relative;
  z-index: ${({ showTooltip }) => (showTooltip ? '99' : '1')};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  ${({ hasTooltip }) =>
    hasTooltip &&
    css`
      cursor: help;
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ theme, bgVariant, colorVariant }) =>
    getColorVariant(theme, bgVariant, colorVariant)};

  transition: width 0.1s linear, height 0.1s linear, font-size 0.1s linear;
`

const PlanPointIcon = styled.i`
  font-family: 'device-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: currentcolor;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  &:before {
    content: '${({ icon }) => getIcon(icon)}';
  }
`

export const PlanPoint = ({
  icon,
  bgVariant,
  colorVariant,
  eventVariant,
  isMuted,
  isSelected,
  tooltip,
  position = [0, 0],
  onClick,
  isMoving,
  draggable,
  onDragStart,
  onDrag,
  onDragEnd,
  onDrop
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const pointRef = useRef(null)

  const handleTooltip = (show) => {
    if (!tooltip || isMoving) return

    setShowTooltip(show)
  }

  const handleMouseDown = (e) => {
    if (draggable) e.stopPropagation()
    handleTooltip(false)
  }

  useEffect(() => {
    if (!isMoving) return

    setShowTooltip(false)
  }, [isMoving])

  return (
    <PlanPointContainer
      bgVariant={bgVariant}
      colorVariant={colorVariant}
      eventVariant={eventVariant}
      position={position}
      style={{
        top: position[0]
      }}
      hasTooltip={tooltip}
      showTooltip={showTooltip}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      ref={pointRef}
      isMoving={isMoving}
      draggable={draggable}
      onDragStart={(e) => onDragStart(e, pointRef)}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      onMouseOver={() => handleTooltip(true)}
      onMouseOut={() => handleTooltip(false)}
    >
      <PlanPointWrapper
        bgVariant={bgVariant}
        colorVariant={colorVariant}
        eventVariant={eventVariant}
        isMuted={isMuted}
        isSelected={isSelected}
        position={position}
        style={{
          top: position[0],
          left: position[1]
        }}
      >
        <PlanPointIcon icon={icon} />
      </PlanPointWrapper>
      {tooltip && <PlanPointTooltip tooltip={tooltip} isOpen={showTooltip} />}
    </PlanPointContainer>
  )
}

PlanPoint.propTypes = {
  icon: PropTypes.string,
  bgVariant: PropTypes.oneOf(['default', 'dark']),
  colorVariant: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  eventVariant: PropTypes.oneOf(['warning', 'danger']),
  isMuted: PropTypes.bool,
  isSelected: PropTypes.bool,
  tooltip: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string
    })
  ),
  /**
   * Point position [x,y].
   **/
  position: PropTypes.array,
  onClick: PropTypes.func,
  isMoving: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  draggable: PropTypes.bool
}

PlanPoint.defaultProps = {
  icon: '',
  bgVariant: 'default',
  colorVariant: 'primary',
  eventVariant: null,
  isMuted: false,
  isSelected: false,
  tooltip: null,
  position: [0, 0],
  onClick: () => {},
  isMoving: false,
  onDragStart: () => {},
  onDrag: () => {},
  onDragEnd: () => {},
  onDrop: () => {},
  draggable: false
}

const getOpenState = `
  visibility: visible;
  opacity: 1;
  transform: translateY(-24px);
  transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0s;
`

const PlanPointTooltipWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 15px;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  margin-left: -120px;
  width: 240px;
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  visibility: hidden;
  opacity: 0;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 112px;
    border-width: 8px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.white} transparent transparent
      transparent;
  }

  ${({ isOpen }) => isOpen && getOpenState};
`

const PlanPointTooltipItem = styled.div`
  & + & {
    margin-top: 12px;
  }
`

const PlanPointTooltipTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-hright: 14px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
  margin-bottom: 4px;
`

const getTextColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'danger':
      return theme.colors.red

    case 'warning':
      return theme.colors.orange

    case 'success':
      return theme.colors.green

    case 'muted':
      return rgba(theme.colors.blackText, 0.5)

    default:
      return rgba(theme.colors.blackText, 0.75)
  }
}

const PlanPointTooltipText = styled.div`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme, colorVariant }) =>
    getTextColorVariant(theme, colorVariant)};
`

const PlanPointTooltip = ({ tooltip, isOpen }) => (
  <PlanPointTooltipWrapper isOpen={isOpen}>
    {tooltip.map((tooltipItem) => (
      <PlanPointTooltipItem key={tooltipItem.label}>
        <PlanPointTooltipTitle>{tooltipItem.label}</PlanPointTooltipTitle>
        <PlanPointTooltipText colorVariant={tooltipItem.color}>
          {tooltipItem.text}
        </PlanPointTooltipText>
      </PlanPointTooltipItem>
    ))}
  </PlanPointTooltipWrapper>
)
