import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const getIcon = (icon) => {
  switch (icon) {
    case 'accident':
      return '\\e900'

    case 'alarm':
      return '\\e901'

    case 'archive':
      return '\\e902'

    case 'arrow-double-left':
      return '\\e903'

    case 'arrow-double-right':
      return '\\e904'

    case 'arrow-down':
      return '\\e905'

    case 'arrow-left':
      return '\\e906'

    case 'arrow-leftdown':
      return '\\e907'

    case 'arrow-right':
      return '\\e908'

    case 'arrow-up':
      return '\\e909'

    case 'attach':
      return '\\e90a'

    case 'attention':
      return '\\e90b'

    case 'bars':
      return '\\e90c'

    case 'building':
      return '\\e90d'

    case 'calendar-clear':
      return '\\e90e'

    case 'calendar':
      return '\\e90f'

    case 'camera-min':
      return '\\e910'

    case 'check':
      return '\\e911'

    case 'clock':
      return '\\e912'

    case 'close':
      return '\\e913'

    case 'cog':
      return '\\e914'

    case 'comment-full':
      return '\\e915'

    case 'comment':
      return '\\e916'

    case 'device':
      return '\\e917'

    case 'dots-horisontal':
      return '\\e918'

    case 'dots':
      return '\\e919'

    case 'download':
      return '\\e91a'

    case 'edit':
      return '\\e91b'

    case 'event':
      return '\\e91c'

    case 'eye-closed':
      return '\\e91d'

    case 'eye':
      return '\\e91e'

    case 'file-archive':
      return '\\e91f'

    case 'file-chart':
      return '\\e920'

    case 'file-code':
      return '\\e921'

    case 'file-csv':
      return '\\e922'

    case 'file-excel':
      return '\\e923'

    case 'file-image':
      return '\\e924'

    case 'file-pdf':
      return '\\e925'

    case 'file-txt':
      return '\\e926'

    case 'file-video':
      return '\\e927'

    case 'file-word':
      return '\\e928'

    case 'file':
      return '\\e929'

    case 'filter':
      return '\\e92a'

    case 'fullscreen':
      return '\\e92b'

    case 'gallery':
      return '\\e92c'

    case 'info':
      return '\\e92d'

    case 'lightbulb':
      return '\\e92e'

    case 'link':
      return '\\e92f'

    case 'list-opened':
      return '\\e930'

    case 'list-closed':
      return '\\e95d'

    case 'list':
      return '\\e931'

    case 'lock':
      return '\\e932'

    case 'map':
      return '\\e933'

    case 'minus':
      return '\\e934'

    case 'paperclip':
      return '\\e935'

    case 'plan':
      return '\\e936'

    case 'plus-settings':
      return '\\e937'

    case 'plus':
      return '\\e938'

    case 'refresh':
      return '\\e939'

    case 'reply':
      return '\\e93a'

    case 'save':
      return '\\e93b'

    case 'search':
      return '\\e93c'

    case 'shield':
      return '\\e93d'

    case 'stream':
      return '\\e93e'

    case 'timeselector':
      return '\\e93f'

    case 'tools':
      return '\\e940'

    case 'trash':
      return '\\e941'

    case 'unlink':
      return '\\e942'

    case 'upload':
      return '\\e943'

    case 'users':
      return '\\e944'

    case 'video':
      return '\\e945'

    case 'window':
      return '\\e946'

    case 'windows-2':
      return '\\e947'

    case 'windows-4':
      return '\\e948'

    case 'windows-1-5':
      return '\\e94a'

    case 'windows-1-7':
      return '\\e94b'

    case 'windows-16':
      return '\\e94c'

    case 'fullscreen-alt':
      return '\\e94d'

    case 'pause':
      return '\\e94e'

    case 'clock-alt':
      return '\\e94f'

    case 'adapter':
      return '\\e950'

    case 'copy':
      return '\\e951'

    case 'ethernet':
      return '\\e952'

    case 'devicegroup':
      return '\\e953'

    case 'fire':
      return '\\e954'

    case 'globe':
      return '\\e955'

    case 'license':
      return '\\e956'

    case 'scenario':
      return '\\e957'

    case 'settings-event':
      return '\\e958'

    case 'settings-role':
      return '\\e959'

    case 'smartgroup':
      return '\\e95a'

    case 'tag':
      return '\\e95b'

    case 'template':
      return '\\e95c'

    default:
      return ''
  }
}

const StyledIcon = styled.i`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: ${({ color }) => color || 'currentcolor'};
  font-size: ${({ size }) => size || '16'}px;

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `};

  &:before {
    content: '${({ icon }) => getIcon(icon)}';
  }
`

export const Icon = ({ icon, size, color, verticalAlign }) => (
  <StyledIcon
    icon={icon}
    size={size}
    color={color}
    verticalAlign={verticalAlign}
  />
)

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  verticalAlign: PropTypes.string
}

Icon.defaultProps = {
  icon: '',
  size: 16,
  color: 'currentcolor',
  verticalAlign: null
}

const getDeviceIcon = (icon) => {
  switch (icon) {
    case 'server':
      return '\\e900'

    case 'camera':
      return '\\e92a'

    case 'cctv':
      return '\\e901'

    case 'cctv-temp':
      return '\\e902'

    case 'cctv-switch':
      return '\\e904'

    case 'phone':
      return '\\e903'

    case 'settings':
      return '\\e905'

    case 'alarm':
      return '\\e90d'

    case 'alarm-alt':
      return '\\e906'

    case 'radio':
      return '\\e908'

    case 'turnstile':
      return '\\e907'

    case 'cog':
      return '\\e909'

    case 'water-pump':
      return '\\e90a'

    case 'motor':
      return '\\e90b'

    case 'cog-rotate':
      return '\\e90c'

    case 'barcode':
      return '\\e90e'

    case 'scan':
      return '\\e90f'

    case 'bolt':
      return '\\e910'

    case 'drop':
      return '\\e911'

    case 'drop-alt':
      return '\\e913'

    case 'temperature':
      return '\\e912'

    case 'manometer':
      return '\\e914'

    case 'fire':
      return '\\e915'

    case 'shield':
      return '\\e92d'

    case 'shield-neutral':
      return '\\e916'

    case 'shield-ok':
      return '\\e919'

    case 'shield-restrict':
      return '\\e91a'

    case 'door-open':
      return '\\e917'

    case 'door':
      return '\\e918'

    case 'toggle-on':
      return '\\e91b'

    case 'toggle-off':
      return '\\e91c'

    case 'car':
      return '\\e91d'

    case 'truck':
      return '\\e91e'

    case 'person':
      return '\\e91f'

    case 'count':
      return '\\e920'

    case 'bulb':
      return '\\e921'

    case 'schedule':
      return '\\e922'

    case 'panic':
      return '\\e923'

    case 'gas':
      return '\\e924'

    case 'sos':
      return '\\e925'

    case 'smoke-detector':
      return '\\e926'

    case 'contrast':
      return '\\e927'

    case 'heart':
      return '\\e928'

    case 'building':
      return '\\e929'

    case 'controlzone':
      return '\\e92b'

    case 'stopwatch':
      return '\\e92c'

    case 'user':
      return '\\e92e'
  }
}

const StyledDeviceIcon = styled.i`
  font-family: 'device-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: ${({ color }) => color || 'currentcolor'};
  font-size: ${({ size }) => size || '16'}px;

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `};

  &:before {
    content: '${({ icon }) => getDeviceIcon(icon)}';
  }
`

export const DeviceIcon = ({ icon, size, color, verticalAlign }) => (
  <StyledDeviceIcon
    icon={icon}
    size={size}
    color={color}
    verticalAlign={verticalAlign}
  />
)

DeviceIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  verticalAlign: PropTypes.string
}

DeviceIcon.defaultProps = {
  icon: '',
  size: 16,
  color: 'currentcolor',
  verticalAlign: null
}
