import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FaceRecognitionImgWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const FaceRecognitionContainerWrapper = styled.div`
  width: ${({ imgSize }) => imgSize + 32}px;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
`

const FaceRecognitionContainer = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
`

const FaceRecognitionContainerInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FaceRecognitionImgInner = styled.div`
  width: ${({ imgSize }) => imgSize + 24}px;
  height: ${({ imgSize }) => imgSize + 24}px;
  border-radius: 50%;
  padding: 6px;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  position: relative;
`

const FaceRecognitionImgSrc = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  border: 6px solid transparent;
  aspect-ratio: 1;
`

const getProgressColor = (theme, progress) => {
  switch (true) {
    case progress <= 33:
      return theme.colors.red

    case progress > 33 && progress <= 66:
      return theme.colors.yellow

    case progress > 66:
      return theme.colors.green

    default:
      return theme.colors.primary2
  }
}

const FaceRecognitionImgLabel = styled.div`
  margin-top: 20px;
  text-align: center;
  color: ${({ theme, progress }) => getProgressColor(theme, progress)};
`

const FaceRecognitionImgProgressRingSvg = styled.svg`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  max-width: 100%;
  transform: rotate(-90deg);

  & circle {
    stroke: ${({ theme, progress }) => getProgressColor(theme, progress)};
  }
`

const FaceRecognitionImgProgressRing = ({ imgSize, progress }) => {
  const radius = (imgSize + 24) / 2
  const stroke = 3
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <FaceRecognitionImgProgressRingSvg
      height='100%'
      width={radius * 2}
      progress={parseInt(progress, 10)}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      preserveAspectRatio='xMinYMin meet'
    >
      <circle
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </FaceRecognitionImgProgressRingSvg>
  )
}

export const FaceRecognitionImg = ({
  imgSrc,
  imgAlt,
  imgSize,
  progress,
  label
}) => {
  return (
    <FaceRecognitionImgWrapper>
      <FaceRecognitionContainerWrapper imgSize={imgSize}>
        <FaceRecognitionContainer>
          <FaceRecognitionContainerInner>
            <FaceRecognitionImgInner imgSize={imgSize}>
              <FaceRecognitionImgSrc src={imgSrc} alt={imgAlt} />
              <FaceRecognitionImgProgressRing
                imgSize={imgSize}
                progress={progress}
              />
            </FaceRecognitionImgInner>
          </FaceRecognitionContainerInner>
        </FaceRecognitionContainer>
      </FaceRecognitionContainerWrapper>

      {label && (
        <FaceRecognitionImgLabel progress={progress}>
          {label}
        </FaceRecognitionImgLabel>
      )}
    </FaceRecognitionImgWrapper>
  )
}

FaceRecognitionImg.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  imgSize: PropTypes.number,
  progress: PropTypes.number,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ])
}

FaceRecognitionImg.defaultProps = {
  imgSrc: '',
  imgAlt: '',
  imgSize: 160,
  progress: undefined,
  label: ''
}
