import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const NavbarWrapper = styled.div`
  width: 100%;
  min-width: 1024px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.primary1};
`

const NavbarInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 12px 16px;
`

export const Navbar = ({ children }) => (
  <NavbarWrapper>
    <NavbarInner>{children}</NavbarInner>
  </NavbarWrapper>
)

Navbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Navbar.defaultProps = {
  children: null
}

const NavbarLogoboxStyled = styled.div`
  margin-right: auto;
`

export const NavbarLogobox = ({ children }) => (
  <NavbarLogoboxStyled>{children}</NavbarLogoboxStyled>
)

NavbarLogobox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

NavbarLogobox.defaultProps = {
  children: null
}

const NavbarLinksStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`

export const NavbarLinks = ({ children }) => (
  <NavbarLinksStyled>{children}</NavbarLinksStyled>
)

NavbarLinks.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

NavbarLinks.defaultProps = {
  children: null
}

const NavbarToolboxStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const NavbarToolbox = ({ children }) => (
  <NavbarToolboxStyled>{children}</NavbarToolboxStyled>
)

NavbarToolbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

NavbarToolbox.defaultProps = {
  children: null
}
