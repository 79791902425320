import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Select } from '../Select'

export const DayMonthInput = ({
  label,
  value,
  onChange,
  disabled,
  error,
  verticalAlignment,
  horizontalAlignment,
  colorVariant,
  locale
}) => {
  const getLastDay = (month) => {
    const date = new Date(2021, 0, 1)
    date.setMonth(month + 1)
    return new Date(date.setDate(0)).getDate()
  }

  const getDays = () => {
    const { month } = value
    const lastDay = getLastDay(month)

    const days = Array.from(Array(lastDay).keys()).reduce((arr, d) => {
      const day = d + 1

      return [
        ...arr,
        {
          value: day,
          label: day
        }
      ]
    }, [])

    return days
  }

  const getMonths = () => {
    const date = new Date()

    const months = Array.from(Array(12).keys()).reduce((arr, m) => {
      date.setMonth(m)
      const month = date.toLocaleString(locale || 'default', { month: 'long' })

      return [
        ...arr,
        {
          value: m,
          label: month[0].toUpperCase() + month.slice(1)
        }
      ]
    }, [])

    return months
  }

  const changeHandler = (key, val) => {
    const newVal = { ...value }
    newVal[key] = val

    if (key === 'month') {
      const maxDay = getLastDay(val)
      if (value.day > maxDay) newVal.day = maxDay
    }

    onChange(newVal)
  }

  return (
    <DayMonthInputWrapper hasError={error}>
      {label && <DayMonthInputlabel>{label}</DayMonthInputlabel>}
      <DayMonthInputGroup>
        <Select
          value={value.day}
          disabled={disabled}
          verticalAlignment={verticalAlignment}
          horizontalAlignment={horizontalAlignment}
          onChange={(e) => changeHandler('day', e)}
          options={getDays()}
          width={68}
          colorVariant={colorVariant}
        />
        <Select
          value={value.month}
          disabled={disabled}
          verticalAlignment={verticalAlignment}
          horizontalAlignment={horizontalAlignment}
          onChange={(e) => changeHandler('month', e)}
          options={getMonths()}
          fullWidth
          colorVariant={colorVariant}
          isSibling
        />
      </DayMonthInputGroup>
      {error && <DayMonthInputError>{error}</DayMonthInputError>}
    </DayMonthInputWrapper>
  )
}

const DayMonthInputWrapper = styled.div`
  display: inline-block;

  ${({ hasError }) =>
    hasError &&
    css`
      & button {
        border-color: ${({ theme }) => theme.colors.red};
      }
    `}
`

const DayMonthInputGroup = styled.div`
  display: inline-flex;
`

const DayMonthInputlabel = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const DayMonthInputError = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

DayMonthInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    month: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right']),
  colorVariant: PropTypes.oneOf(['default', 'grey', 'ghost']),
  locale: PropTypes.string
}

DayMonthInput.defaultProps = {
  label: '',
  value: {
    day: 1,
    month: 0
  },
  onChange: () => {},
  disabled: false,
  error: '',
  verticalAlignment: 'bottom',
  horizontalAlignment: 'left',
  colorVariant: 'default',
  locale: 'en'
}
