import { rgba } from 'polished'
import React, { createContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export const TooltipContext = createContext({})

export const TooltipContextProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  const textRef = useRef()

  useEffect(() => {
    let toolTipX = x
    let toolTipY = y

    const width = textRef?.current?.offsetWidth
    const height = textRef?.current?.offsetHeight

    if (x + width >= window.innerWidth) {
      toolTipX = window.innerWidth - width
      setX(toolTipX - 15)
    }

    if (y + height >= window.innerHeight) {
      toolTipY = window.innerHeight - height
      setY(toolTipY - 15)
    }
  }, [show, x, y])

  const showTooltip = (show, text, x, y) => {
    setX(x + 15)
    setY(y + 15)
    setShow(show)
    setText(text)
  }

  return (
    <TooltipContext.Provider value={{ showTooltip }}>
      {children}
      <Tooltip ref={textRef} style={{ left: x, top: y }} show={show}>
        {text}
      </Tooltip>
    </TooltipContext.Provider>
  )
}

export const TooltipContextConsumer = ({ children }) => {
  return <TooltipContext.Consumer>{children}</TooltipContext.Consumer>
}

export function withTooltipContext(WrappedComponent) {
  return () => {
    return (
      <TooltipContextConsumer>
        {() => <WrappedComponent {...this.props} />}
      </TooltipContextConsumer>
    )
  }
}

const Tooltip = styled.div`
  display: block;
  position: absolute;
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 8px;
  max-width: 350px;
  white-space: pre-line;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s linear;
  z-index: 9999;
`
