import React, { useEffect, useCallback, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Text } from '../Text'

const DropdownWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth, width }) =>
    !fullWidth &&
    width &&
    css`
      width: ${({ width }) => `${width}px` || 'auto'};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const DropdownLabel = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const DropdownInner = styled.div`
  position: relative;
  display: ${({ width }) => (width ? 'block' : 'inline-block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      min-width: 100%;
    `}
`

const getButtonVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blue,
        color: theme.colors.white,
        borderColor: theme.colors.blue
      }

    case 'secondary':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: rgba(theme.colors.secondary, 0.25)
      }

    case 'ghost':
      return {
        backgroundColor: 'transparent',
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: 'transparent'
      }
  }
}

const getButtonVariantHover = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blueAlt,
        color: theme.colors.white,
        borderColor: theme.colors.blueAlt
      }

    case 'secondary':
      return {
        color: theme.colors.secondary,
        borderColor: rgba(theme.colors.secondary, 0.5)
      }

    case 'ghost':
      return {
        color: theme.colors.secondary
      }
  }
}

const getButtonVariantFocus = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: theme.colors.blueAlt,
        color: theme.colors.white,
        borderColor: theme.colors.blueAlt
      }

    case 'secondary':
      return {
        color: rgba(theme.colors.secondary, 0.75),
        borderColor: rgba(theme.colors.secondary, 0.25)
      }

    case 'ghost':
      return {
        color: rgba(theme.colors.secondary, 0.75)
      }
  }
}

const iconStyle = css`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const DropdownToggle = styled.button`
  position: relative;

  ${({ theme, toggleVariant }) => getButtonVariant(theme, toggleVariant)};

  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  width: 100%;
  height: 32px;
  cursor: pointer;
  margin: 0;
  vertical-align: middle;
  transition: background-color 0.16s linear, color 0.16s linear,
    border-color 0.16s linear;

  & span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${({ hasLabel }) =>
    hasLabel &&
    css`
      padding: 0 28px 0 12px;

      &:after {
        content: '\\e905';

        ${iconStyle}

        position: absolute;
        right: 8px;
        top: 50%;
        z-index: 1;

        margin-top: -7px;

        ${({ isOpen }) =>
          isOpen &&
          css`
            transform: scale(1, -1);
          `}

        color: ${({ isOpen, theme }) =>
          isOpen ? theme.colors.secondary : rgba(theme.colors.secondary, 0.5)};
        transition: color 0.16s linear;
      }
    `}

  ${({ hasLabel }) =>
    !hasLabel &&
    css`
      width: 32px;

      &:after {
        content: '${({ styleIcon }) => getIcon(styleIcon)}';
        font-size: 16px;

        ${iconStyle}

        position: absolute;
        right: 7px;
        top: 50%;
        z-index: 1;
        margin-top: -8px;
        color: ${({ isOpen, theme }) =>
          isOpen ? theme.colors.secondary : rgba(theme.colors.secondary, 0.75)};
        transition: color 0.16s linear;
      }
    `}


  @media (hover: hover) {
    &:hover {
      ${({ theme, toggleVariant }) =>
        getButtonVariantHover(theme, toggleVariant)};
    }

    &:active,
    &:focus {
      ${({ theme, toggleVariant }) =>
        getButtonVariantFocus(theme, toggleVariant)};
      outline: none;
    }
  }
`

const openState = (verticalAlignment) => {
  return `
    visibility: visible;
    opacity: 1;
    transform: translateY(${verticalAlignment === 'top' ? '-8px' : '8px'});
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  `
}

const getVerticalPosition = (verticalAlignment) => {
  switch (verticalAlignment) {
    case 'top':
      return `
        bottom: 100%;
      `

    case 'bottom':
      return `
        top: 100%;
      `
  }
}

const getHorizontalPosition = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case 'left':
      return `
        left: 0;
      `

    case 'right':
      return `
        right: 0;
      `
  }
}

const DropdownMenuWrapper = styled.div`
  position: absolute;

  ${({ verticalAlignment }) => getVerticalPosition(verticalAlignment)};

  ${({ horizontalAlignment }) => getHorizontalPosition(horizontalAlignment)};

  z-index: 10;
  transform: translateY(0);
  min-width: 190px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ isOpen, verticalAlignment }) => isOpen && openState(verticalAlignment)};
`

const DropdownMenu = styled.div`
  min-width: 100%;

  ${({ menuWidth }) =>
    menuWidth &&
    css`
      width: ${menuWidth};
    `};

  ${({ dynamicContent }) =>
    !dynamicContent &&
    css`
      max-height: 240px;
      overflow: auto;
    `};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.blackText};
  padding: ${({ dynamicContent }) => (dynamicContent ? '16px' : '8px 0')};
`

export const DropdownDivider = styled.div`
  margin: 6px 0;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.borderOnWhite};
`

const DropdownItemActions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  margin-left: auto;
  padding-left: 10px;
  transition: opacity 0.16s linear;

  & > button {
    padding: 0;
    width: 16px;
    height: 16px;
  }

  & > button + button {
    margin-left: 8px;
  }

  *:hover > &,
  *:focus > & {
    opacity: 1;
  }
`

const getItemVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        color: rgba(theme.colors.blue, 0.75)
      }

    case 'danger':
      return {
        color: rgba(theme.colors.red, 0.75)
      }

    case 'grey':
      return {
        color: rgba(theme.colors.blackText, 0.5)
      }

    default:
      return {
        color: rgba(theme.colors.blackText, 0.75)
      }
  }
}

const getItemVariantHover = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        color: theme.colors.blue
      }

    case 'danger':
      return {
        color: theme.colors.red
      }

    case 'grey':
      return {
        color: theme.colors.blue
      }

    default:
      return {
        color: theme.colors.blue
      }
  }
}

const getIcon = (icon) => {
  switch (icon) {
    case 'accident':
      return '\\e900'

    case 'alarm':
      return '\\e901'

    case 'archive':
      return '\\e902'

    case 'arrow-double-left':
      return '\\e903'

    case 'arrow-double-right':
      return '\\e904'

    case 'arrow-down':
      return '\\e905'

    case 'arrow-left':
      return '\\e906'

    case 'arrow-leftdown':
      return '\\e907'

    case 'arrow-right':
      return '\\e908'

    case 'arrow-up':
      return '\\e909'

    case 'attach':
      return '\\e90a'

    case 'attention':
      return '\\e90b'

    case 'bars':
      return '\\e90c'

    case 'building':
      return '\\e90d'

    case 'calendar-clear':
      return '\\e90e'

    case 'calendar':
      return '\\e90f'

    case 'camera-min':
      return '\\e910'

    case 'check':
      return '\\e911'

    case 'clock':
      return '\\e912'

    case 'close':
      return '\\e913'

    case 'cog':
      return '\\e914'

    case 'comment-full':
      return '\\e915'

    case 'comment':
      return '\\e916'

    case 'device':
      return '\\e917'

    case 'dots-horisontal':
      return '\\e918'

    case 'dots':
      return '\\e919'

    case 'download':
      return '\\e91a'

    case 'edit':
      return '\\e91b'

    case 'event':
      return '\\e91c'

    case 'eye-closed':
      return '\\e91d'

    case 'eye':
      return '\\e91e'

    case 'file-archive':
      return '\\e91f'

    case 'file-chart':
      return '\\e920'

    case 'file-code':
      return '\\e921'

    case 'file-csv':
      return '\\e922'

    case 'file-excel':
      return '\\e923'

    case 'file-image':
      return '\\e924'

    case 'file-pdf':
      return '\\e925'

    case 'file-txt':
      return '\\e926'

    case 'file-video':
      return '\\e927'

    case 'file-word':
      return '\\e928'

    case 'file':
      return '\\e929'

    case 'filter':
      return '\\e92a'

    case 'fullscreen':
      return '\\e92b'

    case 'gallery':
      return '\\e92c'

    case 'info':
      return '\\e92d'

    case 'lightbulb':
      return '\\e92e'

    case 'link':
      return '\\e92f'

    case 'list-opened':
      return '\\e930'

    case 'list-closed':
      return '\\e95d'

    case 'list':
      return '\\e931'

    case 'lock':
      return '\\e932'

    case 'map':
      return '\\e933'

    case 'minus':
      return '\\e934'

    case 'paperclip':
      return '\\e935'

    case 'plan':
      return '\\e936'

    case 'plus-settings':
      return '\\e937'

    case 'plus':
      return '\\e938'

    case 'refresh':
      return '\\e939'

    case 'reply':
      return '\\e93a'

    case 'save':
      return '\\e93b'

    case 'search':
      return '\\e93c'

    case 'shield':
      return '\\e93d'

    case 'stream':
      return '\\e93e'

    case 'timeselector':
      return '\\e93f'

    case 'tools':
      return '\\e940'

    case 'trash':
      return '\\e941'

    case 'unlink':
      return '\\e942'

    case 'upload':
      return '\\e943'

    case 'users':
      return '\\e944'

    case 'video':
      return '\\e945'

    case 'window':
      return '\\e946'

    case 'windows-2':
      return '\\e947'

    case 'windows-4':
      return '\\e948'

    case 'windows-1-5':
      return '\\e94a'

    case 'windows-1-7':
      return '\\e94b'

    case 'windows-16':
      return '\\e94c'

    case 'fullscreen-alt':
      return '\\e94d'

    case 'pause':
      return '\\e94e'

    case 'clock-alt':
      return '\\e94f'

    case 'adapter':
      return '\\e950'

    case 'copy':
      return '\\e951'

    case 'ethernet':
      return '\\e952'

    case 'devicegroup':
      return '\\e953'

    case 'fire':
      return '\\e954'

    case 'globe':
      return '\\e955'

    case 'license':
      return '\\e956'

    case 'scenario':
      return '\\e957'

    case 'settings-event':
      return '\\e958'

    case 'settings-role':
      return '\\e959'

    case 'smartgroup':
      return '\\e95a'

    case 'tag':
      return '\\e95b'

    case 'template':
      return '\\e95c'

    default:
      return '\\e919'
  }
}

const getItemVariantFocus = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        color: theme.colors.blue
      }

    case 'danger':
      return {
        color: theme.colors.red
      }

    case 'grey':
      return {
        color: theme.colors.blue
      }

    default:
      return {
        color: theme.colors.blue
      }
  }
}

const DropdownItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
  padding: ${({ withIcon }) => (withIcon ? '8px 24px 8px 32px' : '8px 24px')};
  margin: 0;
  background: none;
  border: none;
  width: 100%;
  white-space: ${({ hasWrap }) => (hasWrap ? 'normal' : 'nowrap')};
  cursor: pointer;
  transition: color 0.16s linear;

  ${({ theme, colorVariant }) => getItemVariant(theme, colorVariant)};

  ${({ as }) =>
    ['button', 'a'].includes(as) &&
    css`
      &:hover {
        ${({ theme, colorVariant }) =>
          getItemVariantHover(theme, colorVariant)};
      }

      &:active,
      &:focus {
        ${({ theme, colorVariant }) =>
          getItemVariantFocus(theme, colorVariant)};
      }
    `}

  &:active,
    &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.1;
  }
`

const DropdownItemInner = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`

export const DropdownItemComment = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
  margin-left: auto;
`

const DropdownItemIcon = styled.span`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
`

export const DropdownItem = ({
  children,
  withActions,
  forwardRef,
  as,
  tabIndex,
  colorVariant,
  onClick,
  comment,
  withIcon,
  hasWrap,
  ...rest
}) => (
  <DropdownItemWrapper
    as={as}
    withActions={withActions}
    ref={forwardRef}
    tabIndex={tabIndex}
    colorVariant={colorVariant}
    onClick={onClick}
    withIcon={withIcon}
    hasWrap={hasWrap}
    {...rest}
  >
    {withIcon && <DropdownItemIcon>{withIcon}</DropdownItemIcon>}
    <Text color={colorVariant ?? 'gray'} variant={'body-sm'}>
      {children}
    </Text>
    {comment && <DropdownItemComment>{comment}</DropdownItemComment>}
    {withActions && <DropdownItemActions>{withActions}</DropdownItemActions>}
  </DropdownItemWrapper>
)

DropdownItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  withActions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  as: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colorVariant: PropTypes.oneOf(['default', 'primary', 'danger', 'grey']),
  onClick: PropTypes.func,
  comment: PropTypes.string,
  withIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  hasWrap: PropTypes.bool
}

DropdownItem.defaultProps = {
  children: null,
  withActions: null,
  as: '',
  tabIndex: '0',
  colorVariant: 'default',
  onClick: () => {},
  comment: '',
  withIcon: null,
  hasWrap: false
}

export const Dropdown = ({
  styleIcon,
  children,
  dropdownLabel,
  toggleVariant,
  toggleLabel,
  verticalAlignment,
  horizontalAlignment,
  open,
  closeOnClickInside,
  isInline,
  fullWidth,
  width,
  disabled,
  dynamicContent,
  menuWidth,
  positionFixed
}) => {
  const [isOpen, setOpen] = useState(false)
  const dropdownRef = useRef(null)
  const childrenRef = useRef([])
  const menuRef = useRef(null)
  const toggleRef = useRef(null)
  let cursor = -1

  useEffect(() => {
    if (open !== isOpen) setOpen(open)
  }, [open])

  const handleKeyDown = (e) => {
    const elements = childrenRef.current.flat()

    if (e.keyCode === 38) {
      cursor = cursor > 0 ? cursor - 1 : elements.length - 1

      elements[cursor].focus()
    } else if (e.keyCode === 40) {
      cursor = cursor < elements.length - 1 ? cursor + 1 : 0

      elements[cursor].focus()
    }
  }

  const handleBodyClick = useCallback((event) => {
    if (
      closeOnClickInside ||
      (dropdownRef.current && !dropdownRef.current.contains(event.target))
    ) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, false)
      if (!dynamicContent)
        window.addEventListener('keydown', handleKeyDown, false)
    } else {
      document.body.removeEventListener('click', handleBodyClick, false)
      window.removeEventListener('keydown', handleKeyDown, false)
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, false)
      window.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [isOpen])

  useEffect(() => {
    if (!positionFixed) return

    const anchorPoint = dropdownRef.current.getBoundingClientRect()

    const newMenuTop =
      verticalAlignment === 'bottom'
        ? toggleRef.current.getBoundingClientRect().y +
          toggleRef.current.getBoundingClientRect().height
        : toggleRef.current.getBoundingClientRect().y -
          menuRef.current.getBoundingClientRect().height

    menuRef.current.style.top = newMenuTop + 'px'
    menuRef.current.style.bottom = 'auto'

    const newMenuLeft =
      horizontalAlignment === 'left'
        ? anchorPoint.x
        : anchorPoint.x +
          toggleRef.current.getBoundingClientRect().width -
          menuRef.current.getBoundingClientRect().width

    if (
      window.innerHeight - anchorPoint.y <
      menuRef.current.getBoundingClientRect().height
    ) {
      menuRef.current.style.bottom = 20 + 'px'
      menuRef.current.style.top = 'auto'
    } else {
      menuRef.current.style.top = newMenuTop + 'px'
      menuRef.current.style.bottom = 'auto'
    }

    menuRef.current.style.position = 'fixed'
    menuRef.current.style.left = newMenuLeft + 'px'

    if (horizontalAlignment === 'right') menuRef.current.style.right = 'auto'
  }, [positionFixed, isOpen])

  return (
    <DropdownWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
    >
      {dropdownLabel && <DropdownLabel>{dropdownLabel}</DropdownLabel>}
      <DropdownInner fullWidth={fullWidth} width={width} ref={dropdownRef}>
        <DropdownToggle
          isOpen={isOpen}
          toggleVariant={toggleVariant}
          onClick={() => setOpen(!isOpen)}
          tabIndex='0'
          type='button'
          styleIcon={styleIcon}
          hasLabel={!!toggleLabel.length}
          ref={toggleRef}
        >
          <span>{toggleLabel}</span>
        </DropdownToggle>

        <DropdownMenuWrapper
          isOpen={isOpen}
          verticalAlignment={verticalAlignment}
          horizontalAlignment={horizontalAlignment}
          ref={menuRef}
        >
          <DropdownMenu dynamicContent={dynamicContent} menuWidth={menuWidth}>
            {dynamicContent
              ? children
              : React.Children.map(children, (child, index) =>
                  React.cloneElement(child, {
                    forwardRef: (ref) => (childrenRef.current[index] = ref)
                  })
                )}
          </DropdownMenu>
        </DropdownMenuWrapper>
      </DropdownInner>
    </DropdownWrapper>
  )
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dropdownLabel: PropTypes.string,
  toggleVariant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
  toggleLabel: PropTypes.string,
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right']),
  open: PropTypes.bool,
  closeOnClickInside: PropTypes.bool,
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  dynamicContent: PropTypes.bool,
  menuWidth: PropTypes.string,
  positionFixed: PropTypes.bool
}

Dropdown.defaultProps = {
  children: null,
  dropdownLabel: '',
  toggleVariant: 'primary',
  toggleLabel: '',
  verticalAlignment: 'bottom',
  horizontalAlignment: 'left',
  open: false,
  closeOnClickInside: false,
  isInline: false,
  fullWidth: false,
  width: null,
  disabled: false,
  dynamicContent: false,
  menuWidth: undefined,
  positionFixed: false
}
