import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { TextField } from '../TextField'

export const ExpandableSearch = ({
  value,
  onChange,
  placeholder,
  width,
  disabled,
  isInline,
  colorVariant,
  error,
  onSubmit,
  name,
  id,
  inputProps,
  inHeader,
  ...rest
}) => {
  const inputRef = useRef(null)

  const [isOpen, setOpen] = useState(false)

  const handleToggle = () => {
    if (isOpen) onChange({ target: { value: '' } })
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (!isOpen || !inputRef.current) return
    inputRef.current.focus()
  }, [isOpen])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') onSubmit(value)
  }

  return (
    <ExpandableSearchWrapper
      colorVariant={colorVariant}
      isOpen={isOpen}
      inHeader={inHeader}
    >
      <ExpandableSearchInputContainer
        isOpen={isOpen}
        width={width}
        inHeader={inHeader}
      >
        <TextField
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          type='search'
          placeholder={placeholder}
          width={width}
          disabled={disabled}
          colorVariant={colorVariant}
          error={error}
          inputProps={{
            ...inputProps,
            ref: inputRef,
            onKeyPress: handleKeyPress
          }}
          {...rest}
        />
      </ExpandableSearchInputContainer>

      <ExpandableSearchToggle
        type='button'
        onClick={handleToggle}
        isOpen={isOpen}
      />
    </ExpandableSearchWrapper>
  )
}

ExpandableSearch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  width: PropTypes.number,
  placeholder: PropTypes.string,
  colorVariant: PropTypes.oneOf(['light', 'dark']),
  inHeader: PropTypes.bool
}

ExpandableSearch.defaultProps = {
  id: '',
  name: '',
  value: '',
  inputProps: null,
  onChange: () => {},
  disabled: false,
  error: '',
  onSubmit: () => {},
  width: null,
  placeholder: '',
  colorVariant: 'dark',
  inHeader: false
}

const ExpandableSearchWrapper = styled.div`
  position: relative;
  display: inline-flex;
  min-width: 32px;
  height: 32px;
  background-color: ${({ theme, colorVariant }) =>
    colorVariant === 'dark' ? theme.colors.primary3 : theme.colors.primary2};

  ${({ inHeader }) =>
    inHeader &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    `}

  ${({ inHeader }) =>
    inHeader &&
    css`
      width: 32px;
      transition: width 0.16s linear;
    `}

  ${({ isOpen, inHeader }) =>
    isOpen &&
    inHeader &&
    css`
      width: 100%;
    `}
`

const ExpandableSearchInputContainer = styled.div`
  overflow: hidden;
  max-width: 0;
  opacity: 0;
  transition: max-width 0.16s linear, opacity 0.16s linear;

  ${({ isOpen, width }) =>
    isOpen &&
    css`
      opacity: 1;
      max-width: ${width || '168'}px;
    `}

  ${({ isOpen, inHeader }) =>
    isOpen &&
    inHeader &&
    css`
      max-width: 100%;
      width: 100%;

      & > div {
        width: 100%;
      }
    `}
`

const ExpandableSearchToggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  cursor: pointer;
  font-size: 16px;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 0.16s linear;

  &:before {
    content: '${({ isOpen }) => (isOpen ? '\\e913' : '\\e93c')}';
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`
