import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

export const PlanPointViewAngle = ({
  angle,
  onChange,
  onAccept,
  onCancel,
  point,
  isEditing,
  position,
  colorVariant,
  isMuted
}) => {
  const pointRef = useRef()
  const [isRotating, setIsRotating] = useState(false)

  const rotate = (x, y) => {
    const rect = pointRef.current.getBoundingClientRect()

    const center = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2
    }

    return (Math.atan2(y - center.y, x - center.x) * 180) / Math.PI + 90
  }

  const onPointerMove = (e) => {
    if (!isRotating) return

    let nextAngle = rotate(e.pageX, e.pageY)

    if (nextAngle < 0) nextAngle = nextAngle + 360

    if (onChange) onChange(nextAngle)
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <PlanPointViewAngleWrapper
      style={{
        top: position[0],
        left: position[1]
      }}
      isMuted={isMuted}
      ref={pointRef}
      onPointerMove={isRotating ? onPointerMove : null}
    >
      <PlanPointViewAnglePoint>{point}</PlanPointViewAnglePoint>

      {isEditing ? (
        <PlanPointViewAngleContainer>
          <PlanPointViewAngleLabels />

          <PlanPointViewAngleIndicator
            isRotating={isRotating}
            style={{ transform: `rotate(${angle}deg)` }}
            onPointerDown={() => setIsRotating(true)}
            onPointerUp={() => setIsRotating(false)}
            onPointerLeave={() => setIsRotating(false)}
          >
            <PlanPointViewAngleDirection isEditing />
          </PlanPointViewAngleIndicator>

          <ActionBtnContainer>
            <ActionBtn actionType='accept' onClick={() => onAccept(angle)} />
            <ActionBtn actionType='cancel' onClick={handleCancel} />
          </ActionBtnContainer>
        </PlanPointViewAngleContainer>
      ) : (
        <PlanPointViewAnglePlaceholder
          style={{ transform: `rotate(${angle}deg)` }}
        >
          <PlanPointViewAngleDirection colorVariant={colorVariant} />
        </PlanPointViewAnglePlaceholder>
      )}
    </PlanPointViewAngleWrapper>
  )
}

PlanPointViewAngle.propTypes = {
  angle: PropTypes.number,
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  point: PropTypes.node,
  isEditing: PropTypes.bool,
  position: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  colorVariant: PropTypes.oneOf(['blue', 'green', 'red', 'white', 'orange'])
}

PlanPointViewAngle.defaultProps = {
  angle: 0,
  onChange: () => {},
  onAccept: () => {},
  onCancel: () => {},
  point: null,
  isEditing: false,
  position: [0, 0],
  colorVariant: 'green'
}

const PlanPointViewAngleLabels = () => (
  <span>
    <PlanPointViewAngleDegLabel>0°</PlanPointViewAngleDegLabel>
    <PlanPointViewAngleDegLabel>90°</PlanPointViewAngleDegLabel>
    <PlanPointViewAngleDegLabel>180°</PlanPointViewAngleDegLabel>
    <PlanPointViewAngleDegLabel>270°</PlanPointViewAngleDegLabel>
  </span>
)

const getAngleColorVariant = (colorVariant) => {
  switch (colorVariant) {
    case 'blue':
      return '#0D66FF'

    case 'green':
      return '#00C27C'

    case 'red':
      return '#FF2F54'

    case 'white':
      return '#FFFFFF'

    case 'yellow':
      return '#FF9900'
  }
}

const PlanPointViewAngleDirection = ({ isEditing, colorVariant }) => {
  const id = uuid()

  return (
    <svg
      width='55'
      height='56'
      viewBox='0 0 55 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.753906 7.25098C0.753906 7.25098 16.982 0.101511 28.5221 0.251346C39.0821 0.388457 54.2539 6.75098 54.2539 6.75098L28.5221 55.2513L0.753906 7.25098Z'
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1='27.5685'
          y1='1.60594'
          x2='27.5685'
          y2='55.2521'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor={
              isEditing ? '#005EFF' : getAngleColorVariant(colorVariant)
            }
            stopOpacity='0.1'
          />
          <stop
            offset='0.692708'
            stopColor={
              isEditing ? '#005EFF' : getAngleColorVariant(colorVariant)
            }
          />
          <stop
            offset='0.692808'
            stopColor={
              isEditing ? '#005EFF' : getAngleColorVariant(colorVariant)
            }
          />
        </linearGradient>
      </defs>
    </svg>
  )
}

PlanPointViewAngleDirection.propTypes = {
  isEditing: PropTypes.bool,
  colorVariant: PropTypes.oneOf(['blue', 'green', 'red', 'white', 'yellow'])
}

PlanPointViewAngleDirection.defaultProps = {
  isEditing: false,
  colorVariant: 'green'
}

const PlanPointViewAngleWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  user-select: none;

  ${({ isMuted }) =>
    isMuted &&
    css`
      opacity: 0.45;
    `}
`

const PlanPointViewAnglePoint = styled.div`
  position: relative;
  z-index: 2;
`

const PlanPointViewAngleContainer = styled.div`
  position: absolute;
  top: -56px;
  left: -56px;
  z-index: 1;
  width: 112px;
  height: 112px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const PlanPointViewAnglePlaceholder = styled.div`
  position: absolute;
  top: -54px;
  left: -28px;
  z-index: 1;
  width: 56px;
  height: 54px;
  transform-origin: bottom center;
`

const PlanPointViewAngleIndicator = styled.div`
  position: absolute;
  top: 0px;
  left: 27px;
  z-index: 99;
  width: 56px;
  height: 54px;
  transform-origin: bottom center;
  cursor: ${({ isRotating }) => (isRotating ? 'grabbing' : 'grab')};

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    left: 23px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`

const PlanPointViewAngleDegLabel = styled.span`
  position: absolute;
  font-size: 6px;
  padding: 8px;
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  &:nth-child(1) {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &:nth-child(2) {
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
  }

  &:nth-child(3) {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  &:nth-child(4) {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
  }
`

const ActionBtnContainer = styled.div`
  position: absolute;
  bottom: -32px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
`

const ActionBtnStyled = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  padding: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  background-color: ${({ actionType, theme }) =>
    actionType === 'accept' ? theme.colors.green : theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0;
  cursor: pointer;

  &:before {
    content: '${({ actionType }) =>
      actionType === 'accept' ? '\\e911' : '\\e913'}';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
`

const ActionBtn = ({ actionType, onClick }) => (
  <ActionBtnStyled type='button' onClick={onClick} actionType={actionType}>
    {actionType === 'accept' ? 'Accept' : 'Cancel'}
  </ActionBtnStyled>
)

ActionBtn.propTypes = {
  actionType: PropTypes.oneOf(['accept', 'cancel']),
  onClick: PropTypes.func
}

ActionBtn.defaultProps = {
  actionType: 'accept',
  onClick: () => {}
}
