import React, { useState, useRef, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import { datepickerStyle } from './style'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
import 'moment/locale/ru'
import uuid from 'react-uuid'
import { Select } from '../Select'

const DatepickerWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: ${({ width }) => width || '168'}px;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const DatepickerLabel = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const DatepickerToggleWrapper = styled.div`
  position: relative;
`

const iconStyle = css`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const getColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'light':
      return theme.colors.primary2
    case 'dark':
      return theme.colors.primary3
    case 'transparent':
      return 'transparent'
  }
}

const DatepickerToggle = styled.button`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 32px;
  background-color: ${({ theme, colorVariant }) =>
    getColorVariant(theme, colorVariant)};
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.secondary : rgba(theme.colors.secondary, 0.5)};

  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen
        ? rgba(theme.colors.secondary, 0.5)
        : rgba(theme.colors.secondary, 0.25)};

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
    `}

  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  padding: 6px 32px 6px 12px;
  margin: 0;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.16s linear, border-color 0.16s linear;

  & i {
    position: absolute;
    right: 8px;
    top: 50%;
    z-index: 1;
    margin-top: -8px;
    color: ${({ isOpen, hasValue, theme }) =>
      isOpen || hasValue
        ? theme.colors.secondary
        : rgba(theme.colors.secondary, 0.5)};
    transition: color 0.16s linear;
  }

  ${({ hasCustomIcon }) =>
    !hasCustomIcon &&
    css`
      &:after {
        content: '\\e905';

        ${iconStyle}

        position: absolute;
        right: 8px;
        top: 50%;
        z-index: 1;
        margin-top: -8px;

        ${({ isOpen }) =>
          isOpen &&
          css`
            transform: scale(1, -1);
          `}

        color: ${({ isOpen, hasValue, theme }) =>
          isOpen || hasValue
            ? theme.colors.secondary
            : rgba(theme.colors.secondary, 0.5)};
        transition: color 0.16s linear;
      }
    `}

  @media (hover: hover) {
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.secondary};
      border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
    }

    &:hover:after,
    &:hover i {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const openState = (verticalAlignment) => {
  return `
    visibility: visible;
    opacity: 1;
    transform: translateY(${verticalAlignment === 'top' ? '-8px' : '8px'});
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  `
}

const getVerticalPosition = (verticalAlignment) => {
  switch (verticalAlignment) {
    case 'top':
      return `
        bottom: 100%;
      `

    case 'bottom':
      return `
        top: 100%;
      `
  }
}

const getHorizontalPosition = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case 'left':
      return `
        left: 0;
      `

    case 'right':
      return `
        right: 0;
      `
  }
}

const DatepickerMenuWrapper = styled.div`
  position: absolute;

  ${({ verticalAlignment }) => getVerticalPosition(verticalAlignment)};

  ${({ horizontalAlignment }) => getHorizontalPosition(horizontalAlignment)};

  z-index: 10;
  transform: translateY(0);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ isOpen, verticalAlignment }) => isOpen && openState(verticalAlignment)};
`

const DatepickerMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.blackText};
  padding: 12px 10px 4px 10px;

  ${datepickerStyle}

  & .DayPicker {
    font-family: ${({ theme }) => theme.fonts.medium};
  }

  & .DayPicker-Weekday {
    color: ${({ theme }) => rgba(theme.colors.blackText, 0.25)};
  }

  & .DayPicker-Day--today {
    background-color: rgba(13, 22, 35, 0.1);
  }

  & .DayPicker-Day--outside {
    color: ${({ theme }) => rgba(theme.colors.grayscaleDark, 0.25)};
  }

  & .DayPicker-Day--disabled {
    color: ${({ theme }) => rgba(theme.colors.grayscaleDark, 0.25)};
  }

  & .DayPicker-Day--selected {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue};
  }

  & .DayPicker-Day--selected.DayPicker-Day--outside,
  & .DayPicker-Day--today.DayPicker-Day--outside {
    background-color: transparent;
  }

  &
    .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => rgba(theme.colors.blue, 0.1)};
  }
`

const DatepickerError = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 10, 0)
const toMonth = new Date(currentYear + 10, 11)

const DayPickerCaption = styled.div`
  height: 42px;
`

const DayPickerCaptionInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  padding: 0 6px 8px 6px;

  & > div + div {
    margin-left: 8px;
  }

  & button {
    text-transform: capitalize;
  }
`

const YearMonthForm = ({ date, localeUtils, onChange, locale }) => {
  const months = localeUtils.getMonths(locale)

  const years = []

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (val, key) => {
    if (key === 'month') onChange(new Date(date.getFullYear(), val))
    if (key === 'year') onChange(new Date(val, date.getMonth()))
  }

  return (
    <DayPickerCaption className='DayPicker-Caption'>
      <DayPickerCaptionInner>
        <Select
          value={date.getMonth()}
          onChange={(month) => handleChange(month, 'month')}
          options={months.map((month, i) => ({ value: i, label: month }))}
          width={108}
          isInline
          colorVariant='grey'
        />

        <Select
          value={date.getFullYear()}
          onChange={(year) => handleChange(year, 'year')}
          options={years.map((year, i) => ({ value: year, label: year }))}
          width={84}
          isInline
          colorVariant='grey'
        />
      </DayPickerCaptionInner>
    </DayPickerCaption>
  )
}

const DatepickerDayEvent = styled.span`
  position: absolute;
  bottom: 9px;
  left: 18px;
  z-index: 2;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};

  .DayPicker-Day--selected & {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const Datepicker = ({
  date,
  onChange,
  onIsOpen,
  label,
  placeholder,
  colorVariant,
  disabled,
  isInline,
  fullWidth,
  width,
  verticalAlignment,
  horizontalAlignment,
  error,
  inputFormat,
  withTime,
  customInputLabels,
  locale,
  timeFormat,
  customIcon,
  positionFixed,
  calendarProps,
  events
}) => {
  const [isOpen, setOpen] = useState(false)

  const [month, setMonth] = useState()

  const dropdownRef = useRef(null)

  const menuRef = useRef(null)

  const handleBodyClick = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (onIsOpen) {
        onIsOpen(true);
      }
      document.body.addEventListener('click', handleBodyClick, false)
    } else {
      if (onIsOpen) {
        onIsOpen(false);
      }
      document.body.removeEventListener('click', handleBodyClick, false)
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, false)
    }
  }, [isOpen])

  const handleDayClick = (day, { selected }) => {
    const newDay = selected ? undefined : day
    onChange(newDay)
  }

  useEffect(() => {
    if (!positionFixed) return

    const anchorPoint = dropdownRef.current.getBoundingClientRect()

    const newMenuTop = anchorPoint.y
    const newMenuLeft =
      horizontalAlignment === 'right'
        ? anchorPoint.x - menuRef.current.getBoundingClientRect().width - 16
        : anchorPoint.x + anchorPoint.width + 16

    if (
      window.innerHeight - anchorPoint.y <
      menuRef.current.getBoundingClientRect().height
    ) {
      menuRef.current.style.bottom = 20 + 'px'
      menuRef.current.style.top = 'auto'
    } else {
      menuRef.current.style.top = newMenuTop + 'px'
      menuRef.current.style.bottom = 'auto'
    }

    menuRef.current.style.position = 'fixed'
    menuRef.current.style.left = newMenuLeft + 'px'

    if (horizontalAlignment === 'right') menuRef.current.style.right = 'auto'
  }, [positionFixed, isOpen])

  const handleYearMonthChange = (month) => setMonth(month)

  const reducedEvents = events.map((ev) => new Date(ev).setHours(0, 0, 0, 0))

  const renderDay = (day) => {
    const date = day.getDate()
    const reducedDate = new Date(day).setHours(0, 0, 0, 0)
    return (
      <div>
        {date}
        {reducedEvents.includes(reducedDate) && <DatepickerDayEvent />}
      </div>
    )
  }

  return (
    <DatepickerWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
    >
      {label && <DatepickerLabel>{label}</DatepickerLabel>}
      <DatepickerToggleWrapper ref={dropdownRef}>
        <DatepickerToggle
          isOpen={isOpen}
          hasValue={date}
          colorVariant={colorVariant}
          hasError={error}
          onClick={() => setOpen(!isOpen)}
          tabIndex='0'
          type='button'
          hasCustomIcon={customIcon}
        >
          {date
            ? `${moment(date).format(inputFormat || 'D MMMM')}`
            : placeholder}
          {customIcon}
        </DatepickerToggle>

        <DatepickerMenuWrapper
          isOpen={isOpen}
          verticalAlignment={verticalAlignment}
          horizontalAlignment={horizontalAlignment}
          ref={menuRef}
        >
          <DatepickerMenu>
            <DayPicker
              numberOfMonths={1}
              selectedDays={date}
              onDayClick={handleDayClick}
              localeUtils={MomentLocaleUtils}
              locale={locale}
              month={month}
              fromMonth={fromMonth}
              toMonth={toMonth}
              renderDay={renderDay}
              captionElement={({ date, localeUtils }) => (
                <YearMonthForm
                  date={date}
                  locale={locale}
                  localeUtils={localeUtils}
                  onChange={handleYearMonthChange}
                />
              )}
              {...calendarProps}
            />

            {withTime && (
              <TimeSelector
                time={date}
                onChange={onChange}
                customInputLabels={customInputLabels}
                timeFormat={timeFormat}
              />
            )}
          </DatepickerMenu>
        </DatepickerMenuWrapper>
      </DatepickerToggleWrapper>
      {error && <DatepickerError>{error}</DatepickerError>}
    </DatepickerWrapper>
  )
}

Datepicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  colorVariant: PropTypes.oneOf(['light', 'dark', 'transparent']),
  disabled: PropTypes.bool,
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  width: PropTypes.number,
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right']),
  error: PropTypes.string,
  inputFormat: PropTypes.string,
  withTime: PropTypes.bool,
  customInputLabels: PropTypes.shape({
    hours: PropTypes.string,
    minutes: PropTypes.string
  }),
  locale: PropTypes.string,
  timeFormat: PropTypes.oneOf(['12h', '24h']),
  customIcon: PropTypes.node,
  positionFixed: PropTypes.bool,
  /**
   * Other props passed to Calendar component
   **/
  calendarProps: PropTypes.object,
  /**
   * List of dates having dot in calendar
   **/
  events: PropTypes.arrayOf(PropTypes.instanceOf(Date))
}

Datepicker.defaultProps = {
  date: null,
  onChange: () => {},
  label: '',
  placeholder: '',
  colorVariant: 'light',
  disabled: false,
  isInline: false,
  fullWidth: false,
  width: null,
  verticalAlignment: 'bottom',
  horizontalAlignment: 'left',
  error: '',
  inputFormat: 'D MMMM',
  withTime: false,
  customInputLabels: {
    hours: 'Hours',
    minutes: 'Minutes'
  },
  locale: 'ru',
  timeFormat: '24h',
  customIcon: null,
  positionFixed: false,
  calendarProps: null,
  events: []
}

const TimeRow = styled.div`
  display: flex;
`

const TimeItem = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  padding: 48px 0 12px 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -16px;
    bottom: 0;
    z-index;
    width: 1px;
    background-color: rgba(13, 22, 34, 0.1);
  }

  & + & {
    margin-left: 33px;
  }
`

const TimeSelector = ({ time, onChange, customInputLabels, timeFormat }) => {
  const handleTimeChange = (key, value) => {
    let newTime = time || moment()

    if (key === 'hours') newTime = moment(newTime).set({ hour: value })._d

    if (key === 'minutes') newTime = moment(newTime).set({ minute: value })._d

    onChange(newTime)
  }

  const handleFormatChange = (value) => {
    let newTime = time

    const oldHours = moment(time).format('HH')

    let newHours

    if (oldHours >= 12 && value === 'am') {
      newHours = parseInt(oldHours) - 12
    }

    if (oldHours <= 12 && value === 'pm') {
      newHours = parseInt(oldHours) + 12
    }

    newTime = moment(newTime).set({ hour: newHours })._d

    onChange(newTime)
  }

  const tf = timeFormat === '12h' ? 'hh' : 'HH'

  return (
    <TimeRow>
      <TimeItem>
        <TimeInput
          label={customInputLabels.hours || 'Hours'}
          value={moment(time).format(tf)}
          onChange={(e) => handleTimeChange('hours', e)}
          max={timeFormat === '12h' ? 12 : 23}
        />
        <TimeInput
          label={customInputLabels.minutes || 'Minutes'}
          value={moment(time).format('mm')}
          onChange={(e) => handleTimeChange('minutes', e)}
          max={59}
        />
        {timeFormat === '12h' && (
          <TimeFormatToggle
            value={moment(time).locale('en').format('a')}
            onChange={(e) => handleFormatChange(e)}
          />
        )}
      </TimeItem>
    </TimeRow>
  )
}

const TimeInputWrapper = styled.div`
  position: relative;
  display: inline-block;

  & + & {
    margin-left: 8px;
  }
`

const TimeInputLabel = styled.div`
  position: absolute;
  top: -18px;
  left: 0;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
`

const TimeInputControl = styled.input`
  min-width: 1%;
  width: 78px;
  height: 32px;
  padding: 0 12px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 32px;
  border: 1px solid transparent;
  background-color: #eaeaea;
  appearance: textfield;
  -moz-appearance: textfield;
  transition: border-color 0.16s linear;

  &:hover {
    border-color: ${({ theme }) => rgba(theme.colors.blackText, 0.25)};
  }

  &:focus {
    border-color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Svg = styled.svg`
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
`

const TimeselectorIcon = () => (
  <Svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.84383 13.9298L5.75995 11.3249C5.65519 11.194 5.74842 11 5.91612 11H10.0839C10.2516 11 10.3448 11.194 10.24 11.3249L8.15617 13.9298C8.07611 14.0299 7.92389 14.0299 7.84383 13.9298Z'
      fill='#0D1623'
    />
    <path
      d='M7.84383 2.19522L5.75995 4.80006C5.65519 4.93101 5.74842 5.125 5.91612 5.125H10.0839C10.2516 5.125 10.3448 4.93101 10.24 4.80006L8.15617 2.19522C8.07611 2.09514 7.92389 2.09514 7.84383 2.19522Z'
      fill='#0D1623'
    />
    <circle cx='8' cy='8' r='1' fill='#0D1623' />
  </Svg>
)

const TimeInput = ({ value, max, label, onChange }) => {
  const inputRef = useRef()

  const handleChange = (val) => {
    let newVal = val

    const inputEl = inputRef.current

    const position = inputEl.selectionEnd || 0

    const cursorCharacter = newVal[position - 1]

    if (newVal < 0) newVal = max

    if (parseInt(newVal) === parseInt(max + 1)) newVal = 0

    if (newVal > max + 1) {
      newVal = cursorCharacter
    }

    onChange(newVal)
  }

  return (
    <TimeInputWrapper>
      <TimeInputLabel>{label}</TimeInputLabel>
      <TimeInputControl
        placeholder='00'
        type='number'
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        ref={inputRef}
      />
      <TimeselectorIcon />
    </TimeInputWrapper>
  )
}

const TimeFormatToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`

const TimeFormatToggleItem = styled.label`
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & span {
    display: inline-block;

    height: 32px;
    width: 55px;

    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.16s linear;
  }

  & input:checked + span {
    background-color: rgba(13, 22, 35, 0.05);
  }

  @media (hover: hover) {
    & span:hover {
      background-color: rgba(13, 22, 35, 0.01);
    }
  }
`

const TimeFormatToggle = ({ value, onChange }) => {
  const key = uuid()

  return (
    <TimeFormatToggleWrapper>
      <TimeFormatToggleItem>
        <input
          name={key}
          type='radio'
          value='am'
          onChange={(e) => onChange(e.target.value)}
          checked={value === 'am'}
        />
        <span>AM</span>
      </TimeFormatToggleItem>

      <TimeFormatToggleItem>
        <input
          name={key}
          type='radio'
          value='pm'
          onChange={(e) => onChange(e.target.value)}
          checked={value === 'pm'}
        />
        <span>PM</span>
      </TimeFormatToggleItem>
    </TimeFormatToggleWrapper>
  )
}
