import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const LinearProgressWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  padding: 8px 0;
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  }
`

const getColorVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return theme.colors.blue

    case 'success':
      return theme.colors.green

    case 'warning':
      return theme.colors.yellow

    case 'danger':
      return theme.colors.red
  }
}

const LinearProgressBar = styled.div`
  height: 2px;
  background-color: ${({ theme, color }) => getColorVariant(theme, color)};
  transition: width 0.16s linear;
`

export const LinearProgress = ({ width, value, color, isInline }) => (
  <LinearProgressWrapper width={width} isInline={isInline}>
    <LinearProgressBar style={{ width: value + '%' }} color={color} />
  </LinearProgressWrapper>
)

LinearProgress.propTypes = {
  width: PropTypes.number,
  /**
   * Value from 0 to 100
   **/
  value: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  isInline: PropTypes.bool
}

LinearProgress.defaultProps = {
  width: null,
  value: 0,
  color: 'success',
  isInline: false
}
