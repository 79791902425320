import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'

const getBorderColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.secondary

    case 'info':
      return theme.colors.blueAlt

    case 'success':
      return theme.colors.green

    case 'warning':
      return theme.colors.yellow

    case 'danger':
      return theme.colors.red

    case 'muted':
      return rgba(theme.colors.secondary, 0.1)

    default:
      return theme.colors.secondary
  }
}

const getTitleColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.secondary

    case 'info':
      return theme.colors.blueAlt

    case 'success':
      return theme.colors.green

    case 'warning':
      return theme.colors.yellow

    case 'danger':
      return theme.colors.red

    case 'muted':
      return rgba(theme.colors.secondary, 0.25)

    default:
      return theme.colors.secondary
  }
}

const MilestoneBoxWrapper = styled.div`
  border: 1px solid
    ${({ theme, colorVariant }) => getBorderColorVariant(theme, colorVariant)};
  border-radius: 8px;
  width: 100%;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      pointer-events: none;
    `}

  ${({ colorVariant }) =>
    colorVariant === 'muted' &&
    css`
      & * {
        color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
      }
    `}
`

const MilestoneBoxHeader = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 16px;
  line-height: 1.25;
  color: ${({ theme, colorVariant }) =>
    getTitleColorVariant(theme, colorVariant)};
  padding: ${({ isOpen }) =>
    isOpen ? '16px 48px 0 16px' : '16px 48px 16px 16px'};
  cursor: pointer;
  user-select: none;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    `}
`

const MilestoneBoxHeaderIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
`

const MilestoneBoxBody = styled.div`
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.16s linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 100%;
      opacity: 1;
      visibility: visible;
    `}
`

const MilestoneBoxInner = styled.div`
  padding: 8px 16px 16px 16px;
`

export const MilestoneBox = ({
  children,
  heading,
  colorVariant,
  isDisabled,
  isOpen,
  isDone
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <MilestoneBoxWrapper colorVariant={colorVariant} isDisabled={isDisabled}>
      <MilestoneBoxHeader
        isOpen={open}
        onClick={() => setOpen(!open)}
        colorVariant={colorVariant}
        isDisabled={isDisabled}
      >
        {heading}
        {isDone && (
          <MilestoneBoxHeaderIcon>
            <Icon icon='check' size={16} />
          </MilestoneBoxHeaderIcon>
        )}
      </MilestoneBoxHeader>

      <MilestoneBoxBody isOpen={open}>
        <MilestoneBoxInner>{children}</MilestoneBoxInner>
      </MilestoneBoxBody>
    </MilestoneBoxWrapper>
  )
}

MilestoneBox.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  colorVariant: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'muted'
  ]),
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isDone: PropTypes.bool
}

MilestoneBox.defaultProps = {
  children: null,
  heading: '',
  colorVariant: 'primary',
  isDisabled: false,
  isOpen: false,
  isDone: false
}

export const MilestoneBoxItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`
