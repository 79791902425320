import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const getAlign = (align) => {
  switch (align) {
    case 'top':
      return css`
        align-items: flex-start;
      `

    case 'middle':
      return css`
        align-items: center;
      `

    case 'bottom':
      return css`
        align-items: flex-end;
      `
  }
}

const getJustify = (justify) => {
  switch (justify) {
    case 'left':
      return css`
        justify-content: flex-start;
      `

    case 'center':
      return css`
        justify-content: center;
      `

    case 'right':
      return css`
        justify-content: flex-end;
      `

    case 'between':
      return css`
        justify-content: space-between;
      `
  }
}

const RowStyled = styled.div`
  display: flex;
  ${({ align }) => getAlign(align)};
  ${({ justify }) => getJustify(justify)};
  ${({ marginTop }) =>
    marginTop && `margin-top: ${marginTop}${!isNaN(marginTop) ? 'px' : ''}`};
  ${({ marginRight }) =>
    marginRight &&
    `margin-right: ${marginRight}${!isNaN(marginRight) ? 'px' : ''}`};
  ${({ marginBottom }) =>
    marginBottom &&
    `margin-bottom: ${marginBottom}${!isNaN(marginBottom) ? 'px' : ''}`};
  ${({ marginLeft }) =>
    marginLeft &&
    `margin-left: ${marginLeft}${!isNaN(marginLeft) ? 'px' : ''}`};
  ${({ rowWidth }) =>
    rowWidth && `width: ${rowWidth}${isNaN(rowWidth) ? '' : 'px'}`};
  ${({ rowWrap }) =>
    rowWrap &&
    css`
      flex-wrap: wrap;
    `};
`

export const Row = ({
  children,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  justify,
  align,
  width,
  rowWrap
}) => (
  <RowStyled
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    justify={justify}
    align={align}
    rowWidth={width}
    rowWrap={rowWrap}
  >
    {children}
  </RowStyled>
)

Row.propTypes = {
  children: PropTypes.node,
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.oneOf(['left', 'center', 'right', 'between']),
  align: PropTypes.oneOf(['top', 'middle', 'bottom']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowWrap: PropTypes.bool
}

Row.defaultProps = {
  children: null,
  marginTop: null,
  marginRight: null,
  marginBottom: null,
  marginLeft: null,
  justify: null,
  align: null,
  width: undefined,
  rowWrap: false
}
