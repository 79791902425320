import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SpaceStyled = styled.span`
  display: inline-block;
  width: ${({ isDouble }) => (isDouble ? '0.75ch' : '0.4ch')};
`

export const Space = ({ isDouble }) => <SpaceStyled isDouble={isDouble} />

Space.propTypes = {
  isDouble: PropTypes.bool
}

Space.defaultProps = {
  isDouble: false
}
