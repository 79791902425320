import React, { useEffect, useCallback, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import { Text } from '../Text'

const SelectWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};
  white-space: normal;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: ${({ width }) => width || '168'}px;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:focus,
  &:active {
    outline: none;
  }
`

const SelectToggleWrapper = styled.div`
  position: relative;
`

const SelectLabel = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const iconStyle = css`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const getToggleVariant = (theme, colorVariant, hasValue, isOpen) => {
  switch (colorVariant) {
    case 'default':
      return css`
        background-color: transparent;
        color: ${({ hasValue }) =>
          hasValue
            ? theme.colors.secondary
            : rgba(theme.colors.secondary, 0.5)};
        border-color: ${({ isOpen }) =>
          isOpen
            ? rgba(theme.colors.secondary, 0.5)
            : rgba(theme.colors.secondary, 0.25)};

        &:after {
          color: ${({ isOpen }) =>
            isOpen
              ? theme.colors.secondary
              : rgba(theme.colors.secondary, 0.5)};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.secondary};
            border-color: ${rgba(theme.colors.secondary, 0.5)};
          }

          &:hover:after {
            color: ${theme.colors.secondary};
          }
        }
      `

    case 'grey':
      return css`
        background-color: #eaeaea;
        color: ${({ hasValue }) =>
          hasValue
            ? theme.colors.blackText
            : rgba(theme.colors.blackText, 0.75)};
        border-color: ${({ isOpen }) =>
          isOpen ? rgba(theme.colors.blackText, 0.5) : '#eaeaea'};

        &:after {
          color: ${theme.colors.blackText};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.blackText};
            border-color: ${rgba(theme.colors.blackText, 0.25)};
          }

          &:hover:after {
            color: ${theme.colors.blackText};
          }
        }
      `

    case 'ghost':
      return css`
        background-color: transparent;
        color: ${({ hasValue }) =>
          hasValue
            ? theme.colors.secondary
            : rgba(theme.colors.secondary, 0.5)};
        border-color: transparent;

        &:after {
          color: ${({ isOpen }) =>
            isOpen
              ? theme.colors.secondary
              : rgba(theme.colors.secondary, 0.5)};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.secondary};
          }

          &:hover:after {
            color: ${theme.colors.secondary};
          }
        }
      `

    case 'ghost-success':
      return css`
        background-color: transparent;
        color: ${({ hasValue }) =>
          hasValue ? theme.colors.green : rgba(theme.colors.green, 0.5)};
        border-color: transparent;

        &:after {
          color: ${({ isOpen }) =>
            isOpen
              ? theme.colors.secondary
              : rgba(theme.colors.secondary, 0.5)};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.green};
          }

          &:hover:after {
            color: ${theme.colors.secondary};
          }
        }
      `

    case 'ghost-warning':
      return css`
        background-color: transparent;
        color: ${({ hasValue }) =>
          hasValue ? theme.colors.yellow : rgba(theme.colors.yellow, 0.5)};
        border-color: transparent;

        &:after {
          color: ${({ isOpen }) =>
            isOpen
              ? theme.colors.secondary
              : rgba(theme.colors.secondary, 0.5)};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.yellow};
          }

          &:hover:after {
            color: ${theme.colors.secondary};
          }
        }
      `

    case 'ghost-danger':
      return css`
        background-color: transparent;
        color: ${({ hasValue }) =>
          hasValue ? theme.colors.red : rgba(theme.colors.red, 0.5)};
        border-color: transparent;

        &:after {
          color: ${({ isOpen }) =>
            isOpen
              ? theme.colors.secondary
              : rgba(theme.colors.secondary, 0.5)};
        }

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.red};
          }

          &:hover:after {
            color: ${theme.colors.secondary};
          }
        }
      `
  }
}

const getToggleHighlightColor = (theme, highlightColor) => {
  switch (highlightColor) {
    case 'success':
      return css`
        color: ${rgba(theme.colors.green, 0.75)};

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.green};
          }
        }
      `
    case 'warning':
      return css`
        color: ${rgba(theme.colors.yellow, 0.75)};

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.yellow};
          }
        }
      `

    case 'danger':
      return css`
        color: ${rgba(theme.colors.red, 0.75)};

        @media (hover: hover) {
          &:hover,
          &:focus {
            color: ${theme.colors.red};
          }
        }
      `
  }
}

const SelectToggle = styled.button`
  position: relative;
  display: inline-block;
  min-width: 100%;
  max-width: 100%;
  min-height: ${({ noPadding }) => (noPadding ? '0' : '32px')};
  border-width: 1px;
  border-style: solid;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme, colorVariant, hasValue, isOpen }) =>
    getToggleVariant(theme, colorVariant, hasValue, isOpen)}

  ${({ theme, highlightColor }) =>
    getToggleHighlightColor(theme, highlightColor)};

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
    `}

  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  padding: ${({ noPadding }) =>
    noPadding ? '0 32px 0 0' : '6px 32px 6px 12px'};
  margin: 0;
  text-align: left;
  cursor: pointer;
  transition: color 0.16s linear, border-color 0.16s linear;

  &:after {
    content: '\\e905';

    ${iconStyle}

    position: absolute;
    right: 8px;
    top: 50%;
    z-index: 1;
    margin-top: -8px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: scale(1, -1);
      `}

    transition: color 0.16s linear;
  }

  ${({ hasValue, multiple }) =>
    hasValue &&
    multiple &&
    css`
      white-space: normal;
    `}

  @media (hover: hover) {
    &:hover,
    &:focus {
      outline: none;
    }
  }

  ${({ isSibling }) =>
    isSibling &&
    css`
      ${({ isOpen }) => (isOpen ? '' : 'border-left-color: transparent;')};
    `}
`

const SelectError = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

const openState = (verticalAlignment) => {
  return `
    visibility: visible;
    opacity: 1;
    transform: translateY(${verticalAlignment === 'top' ? '-8px' : '8px'});
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  `
}

const getVerticalPosition = (verticalAlignment) => {
  switch (verticalAlignment) {
    case 'top':
      return `
        bottom: 100%;
      `

    case 'bottom':
      return `
        top: 100%;
      `
  }
}

const getHorizontalPosition = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case 'left':
      return `
        left: 0;
      `

    case 'right':
      return `
        right: 0;
      `
  }
}

const SelectMenuWrapper = styled.div`
  position: absolute;

  ${({ verticalAlignment }) => getVerticalPosition(verticalAlignment)};

  ${({ horizontalAlignment }) => getHorizontalPosition(horizontalAlignment)};

  z-index: 10;
  transform: translateY(0);
  min-width: 190px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  max-width: 100%;

  ${({ isOpen, verticalAlignment }) => isOpen && openState(verticalAlignment)};
`

const SelectMenu = styled.div`
  min-width: 100%;
  max-height: 240px;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.blackText};
  padding: 8px 0;
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  scrollbar-width: thin;
  scrollbar-color: rgba(13, 22, 34, 0.25) transparent;
  white-space: normal;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(13, 22, 34, 0.25);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(13, 22, 34, 0.25);
  }
`

const SelectItemGroup = styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.borderOnWhite};
    margin-top: 6px;
    padding-top: 6px;
  }
`

const SelectAccordionGroupWrapper = styled.div`
  padding: 0 12px;
`

const SelectAccordionGroupToggle = styled.button`
  position: relative;
  display: inline-block;
  background: none;
  padding: 0 0 0 18px;
  margin: 6px 0;
  border: none;
  color: ${({ theme }) => theme.colors.blackText};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
  transition: opacity 0.16s linear, color 0.16s linear;

  &:before {
    content: '${({ isOpen }) => (isOpen ? '\\e930' : '\\e931')}';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const SelectAccordionGroupList = styled.div`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: opacity 0.16s linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 100%;
      opacity: 1;
    `}
`

const SelectAccordionGroup = ({ children, label }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <SelectAccordionGroupWrapper>
      <SelectAccordionGroupToggle
        type='button'
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
      </SelectAccordionGroupToggle>
      <SelectAccordionGroupList isOpen={isOpen}>
        {children}
      </SelectAccordionGroupList>
    </SelectAccordionGroupWrapper>
  )
}

const getItemColor = (theme, highlightColor) => {
  switch (highlightColor) {
    case 'success':
      return css`
        color: ${rgba(theme.colors.green, 0.75)};
      `
    case 'warning':
      return css`
        color: ${rgba(theme.colors.yellow, 0.75)};
      `

    case 'danger':
      return css`
        color: ${rgba(theme.colors.red, 0.75)};
      `

    default:
      return css`
        color: ${rgba(theme.colors.blackText, 0.75)};
      `
  }
}

const SelectItem = styled.button`
  position: relative;
  padding: ${({ multiple }) => (multiple ? '8px 32px' : '8px 24px')};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  ${({ theme, highlightColor }) => getItemColor(theme, highlightColor)};
  margin: 0;
  background: none;
  border: none;
  width: 100%;
  white-space: nowrap;
  text-align: left;

  ${({ multiple, isSelected }) =>
    !multiple &&
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.blue};
    `}

  ${({ multiple, isSelected }) =>
    multiple &&
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.blackText};

      &:before {
        content: '\\e911';

        ${iconStyle}

        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
    `}

  transition: color 0.16s linear;

  @media (hover: hover) {
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.blue};
      outline: none;
    }
  }
`

export const Select = ({
  label,
  value,
  multiple,
  options,
  onChange,
  width,
  fullWidth,
  isInline,
  disabled,
  error,
  placeholder,
  verticalAlignment,
  horizontalAlignment,
  colorVariant,
  noPadding,
  isSibling,
  isAccordion
}) => {
  const [isOpen, setOpen] = useState(false)

  const refsArray = []
  let cursor = -1

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      e.preventDefault()
      e.stopPropagation()

      cursor = cursor > 0 ? cursor - 1 : refsArray.length - 1
      refsArray[cursor] && refsArray[cursor].focus()
    } else if (e.keyCode === 40) {
      e.preventDefault()
      e.stopPropagation()

      cursor = cursor < refsArray.length - 1 ? cursor + 1 : 0
      refsArray[cursor] && refsArray[cursor].focus()
    }
  }

  const dropdownRef = useRef(null)

  const handleOpen = () => {
    setOpen(!isOpen)
  }

  const handleBodyClick = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, false)
    } else {
      document.body.removeEventListener('click', handleBodyClick, false)
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, false)
    }
  }, [isOpen])

  const handleSelect = (e, val) => {
    e.preventDefault()
    e.stopPropagation()

    if (multiple) {
      if (value.includes(val)) {
        const newValue = [...value].filter((o) => o !== val)

        onChange(newValue)
      } else {
        const newValue = [...value, val]

        onChange(newValue)
      }
    } else {
      onChange(val)
      setOpen(false)
    }
  }

  const getSingleValueLabel = () => {
    let selectedOption = ''

    if (isAccordion) {
      const reducedOptions = options.flat().reduce((acc, item) => {
        if (item.items) acc.push(...item.items)

        return acc
      }, [])

      selectedOption = reducedOptions.find((o) => o.value === value)
    } else {
      selectedOption = options.flat().find((o) => o.value === value)
    }

    return selectedOption ? selectedOption.label : placeholder
  }

  const getValueLabelHighlight = () => {
    const selectedOption = options.flat().find((o) => o.value === value)

    return selectedOption ? selectedOption.color : 'default'
  }

  const getMultipleValueLabel = () => {
    let valueLabel = []

    value.map((v) => {
      const l = options.flat().find((o) => o.value === v).label
      return (valueLabel = [...valueLabel, l])
    })

    return valueLabel.length ? valueLabel.join(', ') : placeholder
  }

  const isSelected = (val) => {
    return multiple ? value.includes(val) : value === val
  }

  useEffect(() => {
    refsArray.map((ref, i) => {
      if (ref.getAttribute('data-selected') === 'selected') {
        const parentNode = ref.parentNode
        parentNode.scrollTop = ref.offsetTop
        cursor = i
      }
      return ref
    })
  }, [refsArray])

  return (
    <SelectWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
      tabIndex='-1'
      onKeyDown={handleKeyDown}
      isSibling={isSibling}
    >
      {label && <SelectLabel>{label}</SelectLabel>}
      <SelectToggleWrapper ref={dropdownRef}>
        <SelectToggle
          isOpen={isOpen}
          hasValue={
            (multiple && value.length) ||
            (value !== null && value.toString().length)
          }
          placeholder={placeholder}
          hasError={error}
          onClick={handleOpen}
          tabIndex='0'
          multiple={multiple}
          type='button'
          colorVariant={colorVariant}
          noPadding={noPadding}
          highlightColor={getValueLabelHighlight()}
          isSibling={isSibling}
        >
          {multiple ? getMultipleValueLabel() : getSingleValueLabel()}
        </SelectToggle>

        <SelectMenuWrapper
          isOpen={isOpen}
          verticalAlignment={verticalAlignment}
          horizontalAlignment={horizontalAlignment}
        >
          <SelectMenu>
            {isAccordion
              ? options.map((group, i) => (
                  <SelectAccordionGroup
                    key={group.label || uuid()}
                    label={group.label}
                  >
                    {group.items?.map((option) => (
                      <SelectItem
                        key={option.value || i}
                        onClick={(e) => handleSelect(e, option.value)}
                        isSelected={isSelected(option.value)}
                        multiple={multiple}
                        tabIndex='0'
                        ref={(r) => (refsArray[refsArray.length] = r)}
                        type='button'
                        data-selected={isSelected(option.value) && 'selected'}
                        highlightColor={option.color}
                      >
                        <Text variant={'body-sm'} color={'gray'}>
                          {option.label}
                        </Text>
                      </SelectItem>
                    ))}
                  </SelectAccordionGroup>
                ))
              : options.map((option, i) =>
                  Array.isArray(option) ? (
                    <SelectItemGroup key={uuid()}>
                      {option.map((opt, j) => (
                        <SelectItem
                          key={opt.value || j}
                          onClick={(e) => handleSelect(e, opt.value)}
                          isSelected={isSelected(opt.value)}
                          multiple={multiple}
                          tabIndex='0'
                          ref={(r) => (refsArray[refsArray.length] = r)}
                          type='button'
                          data-selected={isSelected(opt.value) && 'selected'}
                          highlightColor={opt.color}
                        >
                          <Text variant={'body-sm'} color={'gray'}>
                            {opt.label}
                          </Text>
                        </SelectItem>
                      ))}
                    </SelectItemGroup>
                  ) : (
                    <SelectItem
                      key={option.value || i}
                      onClick={(e) => handleSelect(e, option.value)}
                      isSelected={isSelected(option.value)}
                      multiple={multiple}
                      tabIndex='0'
                      ref={(r) => (refsArray[refsArray.length] = r)}
                      type='button'
                      data-selected={isSelected(option.value) && 'selected'}
                      highlightColor={option.color}
                    >
                      <Text variant={'body-sm'} color={'gray'}>
                        {option.label}
                      </Text>
                    </SelectItem>
                  )
                )}
          </SelectMenu>
        </SelectMenuWrapper>
      </SelectToggleWrapper>
      {error && <SelectError>{error}</SelectError>}
    </SelectWrapper>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  multiple: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  isInline: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right']),
  colorVariant: PropTypes.oneOf([
    'default',
    'grey',
    'ghost',
    'ghost-success',
    'ghost-warning',
    'ghost-danger'
  ]),
  noPadding: PropTypes.bool,
  isSibling: PropTypes.bool,
  isAccordion: PropTypes.bool
}

Select.defaultProps = {
  label: '',
  value: '',
  multiple: false,
  options: [],
  onChange: () => {},
  width: null,
  fullWidth: false,
  isInline: false,
  disabled: false,
  error: '',
  placeholder: '',
  verticalAlignment: 'bottom',
  horizontalAlignment: 'left',
  colorVariant: 'default',
  noPadding: false,
  isSibling: false,
  isAccordion: false
}
