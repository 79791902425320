import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ColorBarWrapperStyled = styled.div`
  display: flex;
  height: ${({ barHeight }) =>
    barHeight ? `${barHeight}${!isNaN(barHeight) ? 'px' : ''}` : '4px'};
  width: ${({ barWidth }) =>
    barWidth ? `${barWidth}${!isNaN(barWidth) ? 'px' : ''}` : '100%'};
`

export const ColorBarWrapper = ({ children, barHeight, barWidth }) => (
  <ColorBarWrapperStyled barHeight={barHeight} barWidth={barWidth}>
    {children}
  </ColorBarWrapperStyled>
)

ColorBarWrapper.propTypes = {
  children: PropTypes.node,
  barHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ColorBarWrapper.defaultProps = {
  children: null,
  barHeight: '',
  barWidth: ''
}

const getColorVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return theme.colors.blue

    case 'success':
      return theme.colors.green

    case 'warning':
      return theme.colors.yellow

    case 'danger':
      return theme.colors.red
  }
}

const ColorBarSegmentStyled = styled.div`
  height: 100%;
  ${({ segmentWidth }) =>
    segmentWidth ? `width: ${segmentWidth}%` : 'flex: 1'};
  background-color: ${({ theme, variant }) => getColorVariant(theme, variant)};

  & + & {
    margin-left: 4px;
  }
`

export const ColorBarSegment = ({ variant, segmentWidth }) => (
  <ColorBarSegmentStyled variant={variant} segmentWidth={segmentWidth} />
)

ColorBarSegment.propTypes = {
  variant: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  segmentWidth: PropTypes.number
}

ColorBarSegment.defaultProps = {
  variant: 'primary',
  segmentWidth: ''
}
