import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'

import InputMask from 'react-input-mask'

const TextFieldWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: ${({ width }) => width || '168'}px;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`

const TextFieldInner = styled.div`
  position: relative;

  & i {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  & input:focus ~ i {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const TextFieldLabelWrapper = styled.div`
  display: flex;
`

const TextFieldLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const TextFieldLabelComment = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  margin-left: auto;
  padding-left: 6px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
`

const TextFieldInput = styled.input`
  min-width: 1%;
  width: 100%;
  min-height: 32px;
  background-color: ${({ theme, colorVariant }) =>
    colorVariant === 'dark' ? theme.colors.primary3 : theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.colors.red : 'transparent')};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  padding: 6px 12px;
  margin: 0;
  appearance: none;
  resize: vertical;
  -moz-appearance: textfield;

  ${({ withIcon, withUnits }) =>
    (withIcon || withUnits) &&
    css`
      padding-right: 32px;
    `}

  ${({ withPassToggle }) =>
    withPassToggle &&
    css`
      padding-right: 32px;
    `}

  transition: background-color 0.16s linear, border-color 0.16s linear;

  &::-webkit-search-decoration {
    display: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &::placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blueAlt};
    outline: none;
  }
`

const TextFieldError = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

const TextFieldPassToggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 100%;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  & i {
    top: 50%;
    margin-top: -8px;
  }
`

const UnitsLabel = styled.span`
  position: absolute;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  top: 8px;
  right: 8px;
  z-index: 1;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
`

export const TextField = ({
  id,
  name,
  value,
  label,
  labelComment,
  inputProps,
  inputFilter,
  onChange,
  autoComplete,
  autoFocus,
  disabled,
  error,
  width,
  fullWidth,
  placeholder,
  readOnly,
  required,
  type,
  isInline,
  icon,
  colorVariant,
  mask,
  maskChar,
  alwaysShowMask,
  withPassToggle,
  units
}) => {
  const [inputType, setInputType] = useState('text')
  const [input, setInput] = useState(value ?? '')

  useEffect(() => {
    setInputType(type)
  }, [type])

  useEffect(() => {
    setInput(value)
  }, [value])

  const handlePassToggle = () => {
    const newType = inputType === 'password' ? 'text' : 'password'
    setInputType(newType)
  }

  const onChangeInvoke = (e) => {
    let text = e.target.value ?? "";

    if(inputFilter){
      text = inputFilter(text);
    }

    switch (inputType) {
      case "int":
      case "uint":
        text = text.replace(/[^0-9]^[-, +]/g, "");
        if (inputType === "uint") {
          text = text.replace("-", "");
        }

        if (!text) {
          setInput("");
          onChange({
            target: {
              value: "",
            },
            rawEvent: e,
          });
          break;
        }

        if (text === "+" || text === "-") {
          setInput(text);
          break;
        }

        let value = parseInt(text);
        if (isNaN(value)) {
          break;
        }

        setInput(value);
        onChange({
          target: {
            value: value,
          },
          rawEvent: e,
        });
        break;

      default:
        setInput(text);
        onChange({
          target: {
            value: text,
          },
          rawEvent: e,
        });
        break;
    }
  }


  return (
    <TextFieldWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
    >
      {(label || labelComment) && (
        <TextFieldLabelWrapper>
          {label && <TextFieldLabel>{label}</TextFieldLabel>}
          {labelComment && (
            <TextFieldLabelComment>{labelComment}</TextFieldLabelComment>
          )}
        </TextFieldLabelWrapper>
      )}

      <TextFieldInner>
        {mask ? (
          <InputMask
            mask={mask}
            maskChar={maskChar}
            alwaysShowMask={alwaysShowMask}
            value={input}
            onChange={onChangeInvoke}
            disabled={disabled}
            readOnly={readOnly}
          >
            {() => (
              <TextFieldInput
                id={id}
                name={name}
                type={(inputType === 'int' || inputType === 'uint') ? 'text' : inputType}
                autoComplete={autoComplete.toString()}
                autoFocus={autoFocus}
                placeholder={placeholder}
                required={required}
                colorVariant={colorVariant}
                withIcon={icon}
                withUnits={units}
                hasError={error}
                withPassToggle={withPassToggle}
                {...inputProps}
              />
            )}
          </InputMask>
        ) : (
          <TextFieldInput
            id={id}
            name={name}
            type={(inputType === 'int' || inputType === 'uint') ? 'text' : inputType}
            value={input}
            onChange={onChangeInvoke}
            autoComplete={autoComplete.toString()}
            autoFocus={autoFocus}
            placeholder={placeholder}
            required={required}
            colorVariant={colorVariant}
            withIcon={icon}
            withUnits={units}
            disabled={disabled}
            readOnly={readOnly}
            hasError={error}
            withPassToggle={withPassToggle}
            {...inputProps}
          />
        )}
        {withPassToggle && (
          <TextFieldPassToggle type='button' onClick={handlePassToggle}>
            <Icon icon={inputType !== 'password' ? 'eye-closed' : 'eye'} />
          </TextFieldPassToggle>
        )}
        {icon && <Icon icon={icon} />}
        {units && <UnitsLabel>{units}</UnitsLabel>}
      </TextFieldInner>
      {error && <TextFieldError>{error}</TextFieldError>}
    </TextFieldWrapper>
  )
}

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelComment: PropTypes.string,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'number',
    'int',
    'uint',
    'search',
    'password',
    'email',
    'date',
    'month',
    'tel',
    'time',
    'url',
    'week'
  ]),
  isInline: PropTypes.bool,
  icon: PropTypes.string,
  colorVariant: PropTypes.oneOf(['light', 'dark']),
  /**
   * Masking works using 'react-input-mask' library. For more info about using masks, please visit https://github.com/sanniassin/react-input-mask
   */
  mask: PropTypes.string,
  /**
   * Placeholder to cover unfilled parts of the mask
   */
  maskChar: PropTypes.string,
  /**
   * Whether mask prefix and placeholder should be displayed when input is empty and has no focus
   */
  alwaysShowMask: PropTypes.bool,
  withPassToggle: PropTypes.bool,
  units: PropTypes.string
}

TextField.defaultProps = {
  id: '',
  name: '',
  value: '',
  label: '',
  labelComment: '',
  inputProps: null,
  onChange: () => {},
  autoComplete: false,
  autoFocus: false,
  disabled: false,
  error: '',
  width: null,
  fullWidth: false,
  placeholder: '',
  readOnly: false,
  required: false,
  type: 'text',
  isInline: false,
  icon: '',
  colorVariant: 'dark',
  mask: '',
  maskChar: '_',
  alwaysShowMask: false,
  withPassToggle: false,
  units: ''
}
