import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  getIcon,
  iconStyle,
  getHorizontalPosition,
  getOpenState,
  getMarkAlign
} from './helpers'
import { Text } from '../Text/Text'

const VideoWrapperStyled = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  height: ${({ height }) => (height ? height + 'px' : '100%')};

  &:-webkit-full-screen {
    width: 100vw;
    height: 100vh;
  }
  &:-moz-full-screen {
    width: 100vw;
    height: 100vh;
  }
  &:-ms-fullscreen {
    width: 100vw;
    height: 100vh;
  }
  &:fullscreen {
    width: 100vw;
    height: 100vh;
  }
`

const VideoControls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ showControls }) =>
    showControls &&
    css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0.1s linear, transform 0.1s linear,
        visibility 0s linear 0s;
    `}

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 63px;
    background: linear-gradient(
      180deg,
      rgba(13, 22, 35, 0) 0%,
      rgba(13, 22, 35, 0.4) 50%,
      ${({ theme }) => theme.colors.blackText} 100%
    );
    opacity: 0.5;
  }
`

const VideoControlsInner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  padding: 0 8px 8px 8px;
`

const VideoControlsMain = styled.div`
  display: flex;
  align-items: center;
`

const VideoControlsTools = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const VideoWrapper = ({
  videoElement,
  progress,
  mainControls,
  toolbox,
  alwaysShowControls,
  withFullscreen,
  width,
  height,
  fullscreenToggleTitle
}) => {
  const [showControls, setShowControls] = useState(alwaysShowControls)

  const [inFullscreen, setInFullscreen] = useState(false)

  const wrapperRef = useRef()

  const handleShowControls = (state) => {
    if (alwaysShowControls) return

    setShowControls(state)
  }

  const activateFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }

  const handleFullscreen = () => {
    if (inFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
      setInFullscreen(false)
    } else {
      activateFullscreen(wrapperRef.current)
      setInFullscreen(true)
    }
  }

  const listenFullscreenChange = () => {
    const isFullscreen = window.innerHeight === window.screen.height
    setInFullscreen(isFullscreen)
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', listenFullscreenChange, false)

    return () => {
      document.removeEventListener(
        'fullscreenchange',
        listenFullscreenChange,
        false
      )
    }
  }, [])

  return (
    <VideoWrapperStyled
      ref={wrapperRef}
      onMouseEnter={() => handleShowControls(true)}
      onMouseLeave={() => handleShowControls(false)}
      width={width}
      height={height}
    >
      {videoElement}
      <VideoControls showControls={showControls}>
        {progress}
        <VideoControlsInner>
          <VideoControlsMain>{mainControls}</VideoControlsMain>

          <VideoControlsTools>
            {toolbox}

            {withFullscreen && (
              <VideoControlBtn
                icon={inFullscreen ? 'smallsize' : 'fullsize'}
                onClick={handleFullscreen}
                title={fullscreenToggleTitle}
                type='button'
              />
            )}
          </VideoControlsTools>
        </VideoControlsInner>
      </VideoControls>
    </VideoWrapperStyled>
  )
}

VideoWrapper.propTypes = {
  videoElement: PropTypes.node,
  progress: PropTypes.node,
  mainControls: PropTypes.node,
  toolbox: PropTypes.node,
  alwaysShowControls: PropTypes.bool,
  withFullscreen: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fullscreenToggleTitle: PropTypes.string
}

VideoWrapper.defaultProps = {
  videoElement: null,
  progress: null,
  mainControls: null,
  toolbox: null,
  alwaysShowControls: false,
  withFullscreen: false,
  width: null,
  height: null,
  fullscreenToggleTitle: null
}

const BtnBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0 4px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.white};

  ${iconStyle};

  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.16s linear, background-color 0.16s linear;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.blue};
    `}

  &:disabled {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const VideoControlBtnStyled = styled(BtnBase)`
  &:before {
    content: '${({ icon }) => getIcon(icon)}';
  }
`

export const VideoControlBtn = ({ icon, onClick, ...rest }) => (
  <VideoControlBtnStyled icon={icon} onClick={onClick} {...rest} />
)

VideoControlBtn.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func
}

VideoControlBtn.defaultProps = {
  icon: '',
  onClick: () => {}
}

export const VideoControlsDivider = styled.div`
  margin: 2px 4px;
  width: 1px;
  height: 16px;
  background-color: rgba(255, 255, 255, 0.5);
`

const VideoControlsMenuWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const VideoControlsMenuToggle = styled(BtnBase)`
  &:before {
    content: '${({ icon }) => getIcon(icon)}';
  }
`

const VideoControlsMenuList = styled.div`
  position: absolute;
  bottom: 100%;

  ${({ align }) => getHorizontalPosition(align)};

  z-index: 10;
  transform: translateY(0);
  min-width: 96px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ isOpen }) => isOpen && getOpenState};
`

export const VideoControlsMenu = ({ children, align, icon, toggleTitle }) => {
  const [isOpen, setOpen] = useState(false)

  const handleBodyClick = useCallback((e) => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, false)
    } else {
      document.body.removeEventListener('click', handleBodyClick, false)
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, false)
    }
  }, [isOpen])

  return (
    <VideoControlsMenuWrapper>
      <VideoControlsMenuToggle
        isSelected={isOpen}
        icon={icon}
        type='button'
        onClick={() => setOpen(!isOpen)}
        title={toggleTitle}
      />
      <VideoControlsMenuList align={align} isOpen={isOpen}>
        {children}
      </VideoControlsMenuList>
    </VideoControlsMenuWrapper>
  )
}

VideoControlsMenu.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.string,
  toggleTitle: PropTypes.string
}

VideoControlsMenu.defaultProps = {
  children: null,
  align: 'left',
  icon: '',
  toggleTitle: null
}

const VideoControlsMenuTitleStyled = styled.div`
  background-color: rgba(13, 22, 35, 0.5);
  color: ${({ theme }) => theme.colors.white};
  min-height: 20px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 6px;
  margin-bottom: 1px;
`

export const VideoControlsMenuTitle = ({ title }) => (
  <VideoControlsMenuTitleStyled>{title}</VideoControlsMenuTitleStyled>
)

VideoControlsMenuTitle.propTypes = {
  title: PropTypes.string
}

const VideoControlsMenuItemStyled = styled.button`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  min-height: 20px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 4px 6px;
  margin-bottom: 1px;
  cursor: pointer;
  transition: background-color 0.16s linear;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.blue};
    `}

  @media(hover:hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.blueAlt};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

export const VideoControlsMenuItem = ({ label, onClick, isSelected }) => (
  <VideoControlsMenuItemStyled onClick={onClick} isSelected={isSelected}>
    {label}
  </VideoControlsMenuItemStyled>
)

VideoControlsMenuItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
}

VideoControlsMenuItem.defaultProps = {
  label: '',
  onClick: () => {},
  isSelected: false
}

const VideoControlsVolumeWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const VideoControlsVolumeToggle = styled(BtnBase)`
  &:before {
    content: '${({ isMuted }) =>
      isMuted ? getIcon('volume-off') : getIcon('volume-up')}';
  }
`

const VideoControlsVolumeBar = styled.div`
  position: absolute;
  bottom: 100%;
  left: 4px;
  z-index: 10;
  transform: translateY(0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 84px;
  background-color: ${({ theme }) => theme.colors.blackText};
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ isOpen }) => isOpen && getOpenState};
`

const videoControlsVolumeInputThumb = (theme) => {
  return `
      border: 2px solid rgba(255, 255, 255, 0.5);
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: ${theme.colors.blackText};
      cursor: pointer;
    `
}

const videoControlsVolumeInputTrack = `
  width: 64px;
  height: 2px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0;
`

const VideoControlsVolumeInput = styled.input`
  position: relative;
  z-index: 2;
  writing-mode: bt-lr;
  -webkit-appearance: none;
  width: 64px;
  height: 12px;
  padding: 0;
  margin: 0;
  background: transparent;
  transform: rotate(270deg);
  transform-origin: 50% 50%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -5px;

    ${({ theme }) => videoControlsVolumeInputThumb(theme)};
  }

  &::-moz-range-thumb {
    ${({ theme }) => videoControlsVolumeInputThumb(theme)};

    height: 8px;
    width: 8px;
  }

  &::-ms-thumb {
    ${({ theme }) => videoControlsVolumeInputThumb(theme)};
  }

  &::-webkit-slider-runnable-track {
    ${videoControlsVolumeInputTrack};
  }

  &::-moz-range-track {
    ${videoControlsVolumeInputTrack};
  }

  &::-ms-track {
    ${videoControlsVolumeInputTrack};
  }
`

const VideoControlsVolumeProgress = styled.progress`
  appearance: none;
  position: absolute;
  bottom: 8px;
  left: 50%;
  z-index: 1;
  margin-left: -1px;
  transform: rotate(270deg);
  transform-origin: 0% 0%;
  border: none;
  width: 64px;
  height: 2px;
  background-color: transparent;
  vertical-align: top;
  transition: height 0.16s linear;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    box-shadow: none;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-moz-progress-value {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const VideoControlsVolume = ({ value, onChange }) => {
  const [isOpen, setOpen] = useState(false)

  const handleVolumeToggle = () => {
    const newValue = value === 0 ? 0.1 : 0

    onChange(parseFloat(newValue))
  }

  const handleChange = (e) => {
    const { value } = e.target

    onChange(parseFloat(value))
  }

  return (
    <VideoControlsVolumeWrapper
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <VideoControlsVolumeToggle
        onClick={handleVolumeToggle}
        isMuted={value === 0}
        isSelected={isOpen}
      />
      <VideoControlsVolumeBar isOpen={isOpen}>
        <VideoControlsVolumeProgress min={0} max={1} value={value} />

        <VideoControlsVolumeInput
          type='range'
          value={value}
          min={0}
          max={1}
          step={0.1}
          onChange={handleChange}
        />
      </VideoControlsVolumeBar>
    </VideoControlsVolumeWrapper>
  )
}

VideoControlsVolume.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}

VideoControlsVolume.defaultProps = {
  value: 0,
  onChange: () => {}
}

const VideoControlsTimecodeStyled = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 4px;
`

export const VideoControlsTimecode = ({ text }) => (
  <VideoControlsTimecodeStyled>{text}</VideoControlsTimecodeStyled>
)

VideoControlsTimecode.propTypes = {
  text: PropTypes.string
}

VideoControlsTimecode.defaultProps = {
  text: ''
}

export const VideoCameraControls = ({
  mainControls,
  secondaryControls,
  toggleTitle
}) => {
  const [isOpen, setOpen] = useState(false)
  const dropdownRef = useRef()

  const handleBodyClick = useCallback((e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))
      setOpen(false)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', handleBodyClick, false)
    } else {
      document.body.removeEventListener('click', handleBodyClick, false)
    }

    return () => {
      document.body.removeEventListener('click', handleBodyClick, false)
    }
  }, [isOpen])

  return (
    <VideoControlsMenuWrapper ref={dropdownRef}>
      <VideoControlsMenuToggle
        isSelected={isOpen}
        icon='control-camera'
        type='button'
        onClick={() => setOpen(!isOpen)}
        title={toggleTitle}
      />

      <VideoCameraControlMenuList isOpen={isOpen}>
        <VideoCameraControlMenuMain>{mainControls}</VideoCameraControlMenuMain>
        <VideoCameraControlMenuSecondary>
          {secondaryControls}
        </VideoCameraControlMenuSecondary>
      </VideoCameraControlMenuList>
    </VideoControlsMenuWrapper>
  )
}

VideoCameraControls.propTypes = {
  mainControls: PropTypes.node,
  secondaryControls: PropTypes.node,
  toggleTitle: PropTypes.string
}

VideoCameraControls.defaultProps = {
  mainControls: null,
  secondaryControls: null,
  toggleTitle: null
}

const VideoCameraControlMenuList = styled(VideoControlsMenuList)`
  left: -17px;
  display: flex;
  align-items: flex-end;
`

const VideoCameraControlMenuMain = styled.div`
  width: 62px;
`

const VideoCameraControlMenuSecondary = styled.div`
  width: 126px;
  margin-left: 1px;
`

export const VideoCameraControlsBtn = ({ label, onClick, align }) => (
  <VideoCameraControlsBtnStyled type='button' onClick={onClick} align={align}>
    {label}
  </VideoCameraControlsBtnStyled>
)

VideoCameraControlsBtn.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

VideoCameraControlsBtn.defaultProps = {
  label: '',
  onClick: () => {},
  align: 'center'
}

const VideoCameraControlsBtnStyled = styled.button`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  min-height: 20px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: ${({ align }) => align || 'center'};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px 6px;
  margin-bottom: 1px;
  cursor: pointer;
  transition: background-color 0.16s linear, opacity 0.16s linear;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.blue};
    `}

  @media(hover:hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

export const VideoCameraControlsDirections = ({ directions, onClick }) => (
  <VideoCameraControlsDirectionWrapper>
    {[
      'up-left',
      'up',
      'up-right',
      'left',
      'home',
      'right',
      'down-left',
      'down',
      'down-right'
    ].map((d) =>
      directions.includes(d) ? (
        <VideoCameraControlsDirectionBtn
          key={d}
          icon={d}
          type='button'
          onClick={() => onClick(d)}
        />
      ) : (
        <VideoCameraControlsPlaceholder />
      )
    )}
  </VideoCameraControlsDirectionWrapper>
)

VideoCameraControlsDirections.propTypes = {
  directions: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func
}

VideoCameraControlsDirections.defaultProps = {
  directions: [
    'up-left',
    'up',
    'up-right',
    'left',
    'home',
    'right',
    'down-left',
    'down',
    'down-right'
  ],
  onClick: () => {}
}

const VideoCameraControlsDirectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: space-between;
  width: 62px;
  height: 63px;
`

const VideoCameraControlsDirectionBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blackText};

  ${iconStyle};
  font-size: 16px;

  cursor: pointer;
  transition: opacity 0.16s linear, background-color 0.16s linear;

  &:before {
    content: '${({ icon }) => getIcon('arrow-' + icon)}';
  }

  &:focus,
  &:active {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

const VideoCameraControlsPlaceholder = styled.div`
  width: 20px;
  height: 20px;
`

export const VideoCameraControlsZoom = ({
  label,
  value,
  onChange,
  min,
  max,
  step
}) => (
  <VideoCameraControlsZoomWrapper>
    <VideoCameraControlsZoomLabel>{label}</VideoCameraControlsZoomLabel>
    <VideoCameraControlsZoomBtnOut
      onClick={() => onChange(parseFloat(value - step))}
      disabled={value === min}
    />

    <VideoCameraControlsZoomInputWrapper>
      <VideoCameraControlsZoomProgress value={value} min={min} max={max} />

      <VideoCameraControlsZoomInput
        type='range'
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
    </VideoCameraControlsZoomInputWrapper>

    <VideoCameraControlsZoomBtnIn
      onClick={() => onChange(parseFloat(value + step))}
      disabled={value === max}
    />
  </VideoCameraControlsZoomWrapper>
)

VideoCameraControlsZoom.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
}

VideoCameraControlsZoom.defaultProps = {
  label: '',
  value: 1,
  onChange: () => {},
  min: 1,
  max: 10,
  step: 1
}

const VideoCameraControlsZoomWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blackText};
  width: 126px;
  height: 20px;
  padding: 0 6px;
  margin-bottom: 1px;
`

const VideoCameraControlsZoomLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 14px;
`

const VideoCameraControlsZoomBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  ${iconStyle};

  font-size: 12px;
  transition: opacity 0.16s linear;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

const VideoCameraControlsZoomBtnOut = styled(VideoCameraControlsZoomBtn)`
  &:before {
    content: '\\e929';
  }
`

const VideoCameraControlsZoomBtnIn = styled(VideoCameraControlsZoomBtn)`
  &:before {
    content: '\\e928';
  }
`

const VideoCameraControlsZoomInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 54px;
  height: 12px;
  margin: 0 4px;
`

const VideoCameraControlsZoomProgress = styled.progress`
  appearance: none;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
  border: none;
  width: 100%;
  height: 2px;
  background-color: transparent;
  vertical-align: top;
  transition: height 0.16s linear;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    box-shadow: none;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-moz-progress-value {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const videoCameraControlsZoomInputThumb = (theme) => {
  return `
    border: 2px solid ${theme.colors.white};
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: ${theme.colors.blackText};
    cursor: pointer;
  `
}

const videoCameraControlsZoomInputTrack = `
  width: 54px;
  height: 2px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0;
`

const VideoCameraControlsZoomInput = styled.input`
  appearance: none;
  width: 100%;
  height: 12px;
  padding: 0;
  margin: 0;
  background: transparent;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -3px;

    ${({ theme }) => videoCameraControlsZoomInputThumb(theme)};
  }

  &::-moz-range-thumb {
    ${({ theme }) => videoCameraControlsZoomInputThumb(theme)};

    height: 5px;
    width: 5px;
  }

  &::-ms-thumb {
    ${({ theme }) => videoCameraControlsZoomInputThumb(theme)};
  }

  &::-webkit-slider-runnable-track {
    ${videoCameraControlsZoomInputTrack};
  }

  &::-moz-range-track {
    ${videoCameraControlsZoomInputTrack};
  }

  &::-ms-track {
    ${videoCameraControlsZoomInputTrack};
  }
`

export const VideoCameraControlsPresets = ({
  presets,
  currentPreset,
  onSelect,
  handleDelete,
  handleSave,
  placeholder,
  isChanged
}) => {
  const [isOpen, setOpen] = useState(false)

  const getToggleLabel = () => {
    if (currentPreset) {
      return presets.find((p) => p.id === currentPreset).label
    }

    return placeholder
  }

  const handleSelect = (id) => {
    onSelect(id)
    setOpen(false)
  }

  return (
    <VideoCameraControlsPresetsWrapper>
      <VideoCameraControlsPresetsList isOpen={isOpen}>
        {presets.map((preset) => (
          <VideoCameraControlsPresetsItem key={preset.id}>
            <VideoCameraControlsPresetsItemLabel
              onClick={() => handleSelect(preset.id)}
            >
              <Text variant={'description'}>{preset.label}</Text>
            </VideoCameraControlsPresetsItemLabel>
            <VideoCameraControlsPresetsItemActions>
              <VideoCameraControlsPresetsItemSave
                onClick={() => handleSave(preset.id)}
              />
              <VideoCameraControlsPresetsItemDelete
                onClick={() => handleDelete(preset.id)}
              />
            </VideoCameraControlsPresetsItemActions>
          </VideoCameraControlsPresetsItem>
        ))}
      </VideoCameraControlsPresetsList>

      <VideoCameraControlsPresetsToggle
        isOpen={isOpen}
        onClick={() => setOpen(!isOpen)}
        isChanged={isChanged}
      >
        {getToggleLabel()}
      </VideoCameraControlsPresetsToggle>
    </VideoCameraControlsPresetsWrapper>
  )
}

VideoCameraControlsPresets.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  currentPreset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  placeholder: PropTypes.string,
  isChanged: PropTypes.bool
}

VideoCameraControlsPresets.defaultProps = {
  presets: [],
  currentPreset: null,
  onSelect: () => {},
  handleDelete: () => {},
  handleSave: () => {},
  placeholder: '',
  isChanged: false
}

const VideoCameraControlsPresetsWrapper = styled.div``

const VideoCameraControlsPresetsToggle = styled.button`
  position: relative;
  display: block;
  padding: 0 20px 0 6px;
  margin: 0 0 1px 0;
  width: 100%;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 10px;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.16s linear;

  ${({ isChanged }) =>
    isChanged &&
    css`
      padding-right: 28px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        right: 20px;
        z-index: 1;
        background-color: ${({ theme }) => theme.colors.blueAlt};
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    `}

  &:after {
    content: '\\e905';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    position: absolute;
    right: 4px;
    top: 50%;
    z-index: 1;
    margin-top: -6px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: scale(1, -1);
      `}

    transition: color 0.16s linear;
  }

  &:focus,
  &:active {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

const VideoCameraControlsPresetsList = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

const VideoCameraControlsPresetsItem = styled.div`
  display: flex;
  align-items: center;
  width: 126px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 6px;
  margin-bottom: 1px;
`

const VideoCameraControlsPresetsItemLabel = styled.button`
  display: block;
  flex: 1;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: opacity 0.16s linear;

  &:focus,
  &:active {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

const VideoCameraControlsPresetsItemActions = styled.div`
  display: flex;
  margin-left: auto;
`

const VideoCameraControlsPresetsItemBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.75);
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:before {
    ${iconStyle};
    font-size: 12px;
  }

  &:focus,
  &:active {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  & + & {
    margin-left: 4px;
  }
`

const VideoCameraControlsPresetsItemSave = styled(
  VideoCameraControlsPresetsItemBtn
)`
  &:before {
    content: '\\e919';
  }
`

const VideoCameraControlsPresetsItemDelete = styled(
  VideoCameraControlsPresetsItemBtn
)`
  &:before {
    content: '\\e922';
  }
`

const ProgressScaledWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  transform: translateY(-6px);
  display: flex;
  height: 32px;
`

const ProgressScaledBar = styled.progress`
  appearance: none;
  border: none;
  width: 100%;
  height: 32px;
  background-color: transparent;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 0;
    box-shadow: none;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.blue};
  }

  &::-moz-progress-value {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`

const progressScaledInputThumb = `
  height: 32px;
  width: 4px;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
`

const progressScaledInputTrack = `
  background: transparent;
  width: 100%;
  height: 100%;
`

const ProgressScaledInput = styled.input`
  appearance: none;
  height: 32px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${progressScaledInputThumb};
  }

  &::-moz-range-thumb {
    ${progressScaledInputThumb};
  }

  &::-ms-thumb {
    ${progressScaledInputThumb};
  }

  &::-webkit-slider-runnable-track {
    ${progressScaledInputTrack};
  }

  &::-moz-range-track {
    ${progressScaledInputTrack};
  }

  &::-ms-track {
    ${progressScaledInputTrack};
  }
`

const ProgressScaledTimestamp = styled.span`
  position: absolute;
  top: -15px;
  z-index: 10;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`

const ProgressScaledTimestampStart = styled(ProgressScaledTimestamp)`
  left: 0;
`

const ProgressScaledTimestampEnd = styled(ProgressScaledTimestamp)`
  right: 0;
`

const ProgressScaled = ({
  initStart,
  scale,
  duration,
  value,
  onSeekTo,
  marks,
  timestamp,
  startsAt,
  endsAt
}) => {
  const wrapperRef = useRef()

  return (
    <ProgressScaledWrapper ref={wrapperRef}>
      {timestamp && (
        <ProgressScaledTimestampStart>
          {moment(timestamp)
            .add(initStart, 'seconds')
            .format('DD.MM.YYYY hh:mm:ss')}
        </ProgressScaledTimestampStart>
      )}

      {timestamp && (
        <ProgressScaledTimestampEnd>
          {moment(timestamp)
            .add(parseInt(initStart) + parseInt(scale), 'seconds')
            .format('DD.MM.YYYY hh:mm:ss')}
        </ProgressScaledTimestampEnd>
      )}

      {startsAt && (
        <ProgressBarBackdrop total={scale} max={startsAt - initStart} />
      )}

      <ProgressBarDuration
        total={scale}
        startsAt={startsAt > initStart ? startsAt - initStart : 0}
        endsAt={endsAt < initStart ? endsAt - initStart : 0}
      />

      <ProgressScaledBar
        max={scale}
        value={parseInt(value) - parseInt(initStart)}
      />

      {endsAt && (
        <ProgressBarBackdrop
          total={scale}
          min={initStart < endsAt ? endsAt - initStart : 0}
        />
      )}

      <ProgressScaledInput
        type='range'
        min={initStart}
        max={initStart + scale}
        step={1}
        value={value}
        onChange={(e) => onSeekTo(parseInt(e.target.value))}
      />

      {marks &&
        marks.map(
          (m) =>
            m.timecode > initStart &&
            m.timecode < parseInt(initStart) + parseInt(scale) && (
              <ProgressEventMark
                key={m.id}
                position={((m.timecode - parseInt(initStart)) / scale) * 100}
                wrapperRef={wrapperRef}
                onClick={m.onClick}
              >
                {m.data.map((dataItem) => (
                  <ProgressEventMarkTooltipItem key={dataItem.label}>
                    <ProgressEventMarkTooltipTitle>
                      {dataItem.label}
                    </ProgressEventMarkTooltipTitle>
                    <ProgressEventMarkTooltipText>
                      {dataItem.value}
                    </ProgressEventMarkTooltipText>
                  </ProgressEventMarkTooltipItem>
                ))}
              </ProgressEventMark>
            )
        )}
    </ProgressScaledWrapper>
  )
}

const ProgressWrapper = styled.div`
  position: absolute;
  bottom: 36px;
  left: 14px;
  right: 14px;
  z-index: 2;
  height: 4px;
  transition: height 0.16s linear;

  &:before {
    content: '';
    height: 48px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &:hover {
    height: 8px;
  }

  ${({ isCropping }) =>
    isCropping &&
    css`
      &:hover {
        height: 4px;
      }
    `}
`

const ProgressBar = styled.progress`
  position: relative;
  width: 100%;
  appearance: none;
  border: none;
  height: 100%;
  background-color: transparent;
  vertical-align: top;
  transition: height 0.16s linear;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 0;
    box-shadow: none;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.blue};
  }

  &::-moz-progress-value {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`

const progressInputThumb = (theme) => {
  return `
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${theme.colors.white};
    border: none;
    box-shadow: none;
    cursor: pointer;
  `
}

const progressInputTrack = `
  background: transparent;
  width: 100%;
  height: 100%;
`

const ProgressInput = styled.input`
  appearance: none;
  height: 4px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: opacity 0.16s linear, height 0.16s linear;

  *:hover > & {
    height: 8px;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -2px;

    ${({ theme }) => progressInputThumb(theme)};
  }

  &::-moz-range-thumb {
    ${({ theme }) => progressInputThumb(theme)};
  }

  &::-ms-thumb {
    ${({ theme }) => progressInputThumb(theme)};
  }

  &::-webkit-slider-runnable-track {
    ${progressInputTrack};
  }

  &::-moz-range-track {
    ${progressInputTrack};
  }

  &::-ms-track {
    ${progressInputTrack};
  }
`

const ProgressBarScaledOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
`

const ProgressBarDuration = styled.div`
  position: absolute;
  top: 0;
  left: ${({ startsAt, total }) =>
    startsAt ? `${(startsAt / total) * 100}%` : '0'};
  right: ${({ endsAt, total }) =>
    endsAt ? `${((total - endsAt) / total) * 100}%` : '0'};
  z-index: -1;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.25)};
  transition: height 0.16s linear;
`

const ProgressBarBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: ${({ min, total }) => (min ? `${(min / total) * 100}%` : '0')};
  right: ${({ max, total }) =>
    max ? `${((total - max) / total) * 100}%` : '0'};
  z-index: -1;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.blackText, 0.125)};
  vertical-align: top;
  transition: height 0.16s linear;
`

export const Progress = ({
  duration,
  currentTime,
  onSeekTo,
  marks,
  scale,
  timestamp,
  isCropping,
  onCrop,
  setCroppingState,
  lacunas
}) => {
  const [showScale, setShowScale] = useState(false)

  const [scaledStart, setScaledStart] = useState(0)

  const wrapperRef = useRef()

  const handleScale = () => {
    setShowScale(true)
  }

  const handleChange = (e) => {
    const { value } = e.target

    if (scale) {
      const delta = scale / 2

      let offset = 0

      if (parseInt(value) - delta < 0) {
        offset = (parseInt(value) - delta) * -1
      }

      if (parseInt(value) + delta > duration) {
        offset = duration - parseInt(value) - delta
      }

      const initStart = parseInt(value) - delta + offset

      setScaledStart(initStart)
    }

    onSeekTo(parseInt(value))
  }

  useEffect(() => {
    setShowScale(false)
  }, [isCropping])

  const handleMouseLeave = (e) => {
    e.stopPropagation()

    setShowScale(false)
  }

  return (
    <ProgressWrapper
      isCropping={isCropping}
      ref={wrapperRef}
      onMouseLeave={handleMouseLeave}
    >
      {!!scale && showScale && (
        <ProgressScaled
          initStart={scaledStart}
          duration={duration}
          scale={scale}
          value={currentTime}
          onSeekTo={onSeekTo}
          marks={marks}
          timestamp={timestamp}
        />
      )}

      <ProgressBarBackdrop total={duration} />

      <ProgressBar min='0' max={duration} value={currentTime} />

      {!!scale && showScale && (
        <ProgressBarScaledOverlay
          style={{
            left: `${(scaledStart / duration) * 100}%`,
            width: `${(scale / duration) * 100}%`
          }}
        />
      )}

      {!isCropping && (
        <ProgressInput
          type='range'
          min='0'
          max={duration}
          step='1'
          value={currentTime}
          onChange={handleChange}
          onClick={handleScale}
        />
      )}

      {!!lacunas &&
        lacunas.map((l) => (
          <ProgressBarDuration
            key={l.id ?? ''}
            total={duration}
            startsAt={l.fromPr}
            endsAt={l.toPr}
          />
        ))}

      {!isCropping && (
        <ProgressInput
          type='range'
          min='0'
          max={duration}
          step='1'
          value={currentTime}
          onChange={handleChange}
          onClick={handleScale}
        />
      )}

      {isCropping && (
        <CroppingBar
          duration={duration}
          onCrop={onCrop}
          setCroppingState={setCroppingState}
        />
      )}

      {marks &&
        marks.map((m) => (
          <ProgressEventMark
            key={m.id}
            position={m.position}
            wrapperRef={wrapperRef}
            onClick={m.onClick}
          >
            {m.data.map((dataItem) => (
              <ProgressEventMarkTooltipItem key={dataItem.label}>
                <ProgressEventMarkTooltipTitle>
                  {dataItem.label}
                </ProgressEventMarkTooltipTitle>
                <ProgressEventMarkTooltipText>
                  {dataItem.value}
                </ProgressEventMarkTooltipText>
              </ProgressEventMarkTooltipItem>
            ))}
          </ProgressEventMark>
        ))}
    </ProgressWrapper>
  )
}

Progress.propTypes = {
  duration: PropTypes.number,
  /**
   * Progress start offset
   **/
  startsAt: PropTypes.number,
  /**
   * Progress end offset
   **/
  endsAt: PropTypes.number,
  currentTime: PropTypes.number,
  onSeekTo: PropTypes.func,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      timecode: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.any
        })
      )
    })
  ),
  scale: PropTypes.number,
  timestamp: PropTypes.instanceOf(Date),
  isCropping: PropTypes.bool,
  onCrop: PropTypes.func,
  setCroppingState: PropTypes.func
}

Progress.defaultProps = {
  duration: 0,
  startsAt: undefined,
  endsAt: undefined,
  currentTime: 0,
  onSeekTo: () => {},
  marks: [],
  scale: null,
  timestamp: undefined,
  isCropping: false,
  onCrop: () => {},
  setCroppingState: () => {}
}

const ProgressEventMark = ({ children, position, wrapperRef, onClick }) => {
  const [isOpen, setOpen] = useState(false)
  const [align, setAlign] = useState('center')

  const markRef = useRef()

  useEffect(() => {
    const wrapperBCR = wrapperRef.current.getBoundingClientRect()
    const markBCR = markRef.current.getBoundingClientRect()

    let newAlign = 'center'
    if (markBCR.x - wrapperBCR.x < 120) newAlign = 'left'
    if (wrapperBCR.x + wrapperBCR.width - 120 < markBCR.x) newAlign = 'right'

    setAlign(newAlign)
  }, [position])

  return (
    <ProgressEventMarkStyled
      style={{ left: position + '%' }}
      onClick={onClick}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      ref={markRef}
    >
      <ProgressEventMarkTooltip isOpen={isOpen} align={align}>
        {children}
      </ProgressEventMarkTooltip>
    </ProgressEventMarkStyled>
  )
}

const ProgressEventMarkStyled = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.75)};
`

const ProgressEventMarkTooltip = styled.div`
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  width: 240px;
  margin-bottom: 24px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    border-width: 8px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.white} transparent transparent
      transparent;
  }

  ${({ align }) => getMarkAlign(align)};

  ${({ isOpen }) => isOpen && getOpenState};
`

const ProgressEventMarkTooltipItem = styled.div`
  & + & {
    margin-top: 12px;
  }
`

const ProgressEventMarkTooltipTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-hright: 14px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.5)};
  margin-bottom: 4px;
`

const ProgressEventMarkTooltipText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
`

const ProgressSlideshowWrapper = styled(ProgressWrapper)``

const ProgressSlideshowMarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const ProgressSlideshowMark = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.75)};
`

const ProgressSlideshowInput = styled(ProgressInput)`
  z-index: 10;
`

export const ProgressSlideshow = ({ count, currentProgress, onSeekTo }) => (
  <ProgressSlideshowWrapper>
    <ProgressBarDuration total={1} />

    <ProgressBar min={0} max={1} value={currentProgress} />

    <ProgressSlideshowMarkContainer>
      {Array.from(Array(count).keys()).map((c, i) => (
        <ProgressSlideshowMark key={i} />
      ))}
    </ProgressSlideshowMarkContainer>

    <ProgressSlideshowInput
      type='range'
      min={0}
      max={1}
      step={0.01}
      value={currentProgress}
      onChange={(e) => onSeekTo(parseFloat(e.target.value))}
    />
  </ProgressSlideshowWrapper>
)

ProgressSlideshow.propTypes = {
  count: PropTypes.number,
  currentProgress: PropTypes.number,
  onSeekTo: PropTypes.func
}

ProgressSlideshow.defaultProps = {
  count: 1,
  currentProgress: 0,
  onSeekTo: () => {}
}

const CroppingBar = ({ duration, onCrop, setCroppingState }) => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(duration)

  const handleConfirm = () => {
    onCrop({ start, end })
    setCroppingState(false)
  }

  const handleCancel = () => {
    setCroppingState(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'start') {
      if (value > end) return
      setStart(value)
    }

    if (name === 'end') {
      if (value < start) return
      setEnd(value)
    }
  }

  return (
    <CroppingBarWrapper>
      <CroppingBarActions>
        <CroppingBarConfirm onClick={handleConfirm} />
        <CroppingBarCancel onClick={handleCancel} />
      </CroppingBarActions>

      <CroppingBarInputContainer>
        <CroppingBarInputStart
          type='range'
          name='start'
          min={0}
          max={duration}
          value={start}
          onChange={handleChange}
        />
        <CroppingBarInputEnd
          type='range'
          name='end'
          min={0}
          max={duration}
          value={end}
          onChange={handleChange}
        />
      </CroppingBarInputContainer>
    </CroppingBarWrapper>
  )
}

const CroppingBarWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 20;
`

const CroppingBarActions = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
  z-index: 1;
  display: flex;
`

const CroppingBarBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 0 1px;
  border: none;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.16s linear;

  &:before {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }

  &:focus,
  &:active {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }
`

const CroppingBarConfirm = styled(CroppingBarBtn)`
  width: 40px;

  &:before {
    content: '\\e911';
  }
`

const CroppingBarCancel = styled(CroppingBarBtn)`
  width: 20px;

  &:before {
    content: '\\e913';
  }
`

const CroppingBarInputContainer = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
`

const croppingBarInputThumb = `
  height: 16px;
  width: 16px;
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  pointer-events: auto;
`

const croppingBarInputTrack = `
  background: transparent;
  width: 100%;
  height: 100%;
`

const CroppingBarInput = styled.input`
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  background: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -2px;

    ${croppingBarInputThumb};
  }

  &::-moz-range-thumb {
    ${croppingBarInputThumb};
  }

  &::-ms-thumb {
    ${croppingBarInputThumb};
  }

  &::-webkit-slider-runnable-track {
    ${croppingBarInputTrack};
  }

  &:focus::-webkit-slider-runnable-track {
    ${croppingBarInputTrack};
  }

  &::-moz-range-track {
    ${croppingBarInputTrack};
  }

  &::-ms-track {
    ${croppingBarInputTrack};
  }
`

const croppingBarInputStartBg =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00Ljc0MDc0IDBIMS4xODUxOUMwLjg3MDg1NSAwIDAuNTY5Mzk4IDAuMTI0ODY3IDAuMzQ3MTMzIDAuMzQ3MTMzQzAuMTI0ODY3IDAuNTY5Mzk4IDAgMC44NzA4NTUgMCAxLjE4NTE5TDAgMTQuODE0OEMwIDE1LjEyOTEgMC4xMjQ4NjcgMTUuNDMwNiAwLjM0NzEzMyAxNS42NTI5QzAuNTY5Mzk4IDE1Ljg3NTEgMC44NzA4NTUgMTYgMS4xODUxOSAxNkg0Ljc0MDc0QzQuODk3OTEgMTYgNS4wNDg2NCAxNS45Mzc2IDUuMTU5NzcgMTUuODI2NEM1LjI3MDkgMTUuNzE1MyA1LjMzMzM0IDE1LjU2NDYgNS4zMzMzNCAxNS40MDc0VjE0LjgxNDhDNS4zMzMzNCAxNC42NTc2IDUuMjcwOSAxNC41MDY5IDUuMTU5NzcgMTQuMzk1OEM1LjA0ODY0IDE0LjI4NDcgNC44OTc5MSAxNC4yMjIyIDQuNzQwNzQgMTQuMjIyMkgxLjc3Nzc4VjEuNzc3NzhINC43NDA3NEM0Ljg5NzkxIDEuNzc3NzggNS4wNDg2NCAxLjcxNTM1IDUuMTU5NzcgMS42MDQyMUM1LjI3MDkgMS40OTMwOCA1LjMzMzM0IDEuMzQyMzUgNS4zMzMzNCAxLjE4NTE5VjAuNTkyNTkzQzUuMzMzMzQgMC40MzU0MjggNS4yNzA5IDAuMjg0Njk5IDUuMTU5NzcgMC4xNzM1NjZDNS4wNDg2NCAwLjA2MjQzMzcgNC44OTc5MSAwIDQuNzQwNzQgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo='

const croppingBarInputEndBg =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjI1OTI2IDBINC44MTQ4MUM1LjEyOTE0IDAgNS40MzA2IDAuMTI0ODY3IDUuNjUyODcgMC4zNDcxMzNDNS44NzUxMyAwLjU2OTM5OCA2IDAuODcwODU1IDYgMS4xODUxOUw2IDE0LjgxNDhDNiAxNS4xMjkxIDUuODc1MTMgMTUuNDMwNiA1LjY1Mjg3IDE1LjY1MjlDNS40MzA2IDE1Ljg3NTEgNS4xMjkxNCAxNiA0LjgxNDgxIDE2SDEuMjU5MjZDMS4xMDIwOSAxNiAwLjk1MTM2MiAxNS45Mzc2IDAuODQwMjMgMTUuODI2NEMwLjcyOTA5NiAxNS43MTUzIDAuNjY2NjYzIDE1LjU2NDYgMC42NjY2NjMgMTUuNDA3NFYxNC44MTQ4QzAuNjY2NjYzIDE0LjY1NzYgMC43MjkwOTYgMTQuNTA2OSAwLjg0MDIzIDE0LjM5NThDMC45NTEzNjIgMTQuMjg0NyAxLjEwMjA5IDE0LjIyMjIgMS4yNTkyNiAxNC4yMjIySDQuMjIyMjJWMS43Nzc3OEgxLjI1OTI2QzEuMTAyMDkgMS43Nzc3OCAwLjk1MTM2MiAxLjcxNTM1IDAuODQwMjMgMS42MDQyMUMwLjcyOTA5NiAxLjQ5MzA4IDAuNjY2NjYzIDEuMzQyMzUgMC42NjY2NjMgMS4xODUxOVYwLjU5MjU5M0MwLjY2NjY2MyAwLjQzNTQyOCAwLjcyOTA5NiAwLjI4NDY5OSAwLjg0MDIzIDAuMTczNTY2QzAuOTUxMzYyIDAuMDYyNDMzNyAxLjEwMjA5IDAgMS4yNTkyNiAwWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=='

const CroppingBarInputStart = styled(CroppingBarInput)`
  &::-webkit-slider-thumb {
    background-image: url(${croppingBarInputStartBg});
    background-position: left;
  }

  &::-moz-range-thumb {
    background-image: url(${croppingBarInputStartBg});
    background-position: left;
  }

  &::-ms-thumb {
    background-image: url(${croppingBarInputStartBg});
    background-position: left;
  }
`

const CroppingBarInputEnd = styled(CroppingBarInput)`
  &::-webkit-slider-thumb {
    background-image: url(${croppingBarInputEndBg});
    background-position: right;
  }

  &::-moz-range-thumb {
    background-image: url(${croppingBarInputEndBg});
    background-position: right;
  }

  &::-ms-thumb {
    background-image: url(${croppingBarInputEndBg});
    background-position: right;
  }
`
