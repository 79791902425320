import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const getColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.secondary

    case 'danger':
      return theme.colors.red

    case 'warning':
      return theme.colors.yellow

    case 'success':
      return theme.colors.green

    case 'info':
      return theme.colors.blueAlt

    default:
      return theme.colors.secondary
  }
}

const CounterStyled = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 10px;
  line-height: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  background-color: ${({ theme, colorVariant }) =>
    getColorVariant(theme, colorVariant)};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0 6px;
  text-align: center;
  vertical-align: middle;
`

export const Counter = ({ colorVariant, label }) => (
  <CounterStyled colorVariant={colorVariant}>{label}</CounterStyled>
)

Counter.propTypes = {
  colorVariant: PropTypes.oneOf(['danger', 'warning', 'success', 'info']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Counter.defaultProps = {
  colorVariant: 'success',
  label: ''
}
