import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const getIcon = (icon) => {
  switch (icon) {
    case 'success':
      return '\\e911'

    case 'danger':
      return '\\e913'

    default:
      return ''
  }
}

const StyledIcon = styled.i`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: currentcolor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 8px;
  font-size: 16px;

  &:before {
    content: '${({ status }) => getIcon(status)}';
  }
`

const getVariant = (theme, status) => {
  switch (status) {
    case 'success':
      return {
        backgroundColor: rgba(theme.colors.green, 0.1),
        color: theme.colors.green
      }

    case 'danger':
      return {
        backgroundColor: rgba(theme.colors.red, 0.1),
        color: theme.colors.red
      }
  }
}

const StatusLabelStyled = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;

  ${({ theme, status }) => getVariant(theme, status)};
`

export const StatusLabel = ({ label, status }) => (
  <StatusLabelStyled status={status}>
    <StyledIcon status={status} />
    <span>{label}</span>
  </StatusLabelStyled>
)

StatusLabel.propTypes = {
  label: PropTypes.string,
  status: PropTypes.oneOf(['success', 'danger'])
}

StatusLabel.defaultProps = {
  label: '',
  status: 'success'
}
