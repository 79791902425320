import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Tooltip } from '../Tooltip'
import './Checkbox.css'
import { TooltipContextConsumer } from '../TooltipContext/TooltipContext'

const getColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.secondary

    case 'danger':
      return theme.colors.red

    case 'warning':
      return theme.colors.yellow

    case 'success':
      return theme.colors.green

    case 'info':
      return theme.colors.blueAlt

    default:
      return theme.colors.secondary
  }
}

const getIndicatorColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.blueAlt

    case 'danger':
      return theme.colors.red

    case 'warning':
      return theme.colors.yellow

    case 'success':
      return theme.colors.green

    case 'info':
      return theme.colors.blueAlt

    default:
      return theme.colors.blueAlt
  }
}

const getIndicatorBorderColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return theme.colors.secondary

    case 'danger':
      return theme.colors.red

    case 'warning':
      return theme.colors.yellow

    case 'success':
      return theme.colors.green

    case 'info':
      return theme.colors.blueAlt

    default:
      return theme.colors.secondary
  }
}

const CheckboxLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  transition: opacity 0.16s linear;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
`

const CheckboxIndicator = styled.span`
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};

  ${({ theme, colorVariant }) =>
    colorVariant &&
    css`
      border-color: ${getIndicatorBorderColorVariant(theme, colorVariant)};
    `}

  transition: background-color 0.16s linear, border-color 0.16s linear, opacity 0.16s linear;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    height: 2px;
    background-color: transparent;
  }

  &:before {
    top: 6px;
    left: 2px;
    width: 5px;
    transform: rotate(45deg);
  }

  &:after {
    top: 5px;
    left: 4px;
    width: 7px;
    transform: rotate(-45deg);
  }

  ${({ denied }) =>
    denied &&
    css`
      &:before,
      &:after {
        width: 12px;
        transform-origin: center;
      }

      &:before {
        top: 50%;
        left: 0;
        margin-top: -1px;
        transform: rotate(45deg);
      }

      &:after {
        top: 50%;
        left: 0;
        margin-top: -1px;
        transform: rotate(-45deg);
      }
    `}

  input:checked ~ &:before,
  input:checked ~ &:after,
  input:indeterminate ~ &:before {
    background-color: ${({ theme }) => theme.colors.primary3};
  }

  input:indeterminate ~ &:before {
    top: 5px;
    left: 2px;
    width: 8px;
    transform: rotate(0);
  }
`

const CheckboxWrapper = styled.label`
  position: relative;
  display: inline-block;
  padding-left: 24px;
  cursor: pointer;
  max-width: 100%;

  ${({ theme, colorVariant }) => css`
    color: ${getColorVariant(theme, colorVariant)};
  `}

  & input ~ span {
    opacity: 0.75;
  }

  & input:checked ~ span,
  & input:indeterminate ~ span {
    opacity: 1;
  }

  &
    input:checked
    ~ ${CheckboxIndicator},
    &
    input:indeterminate
    ~ ${CheckboxIndicator} {
    background-color: ${({ theme, colorVariant }) =>
      getIndicatorColorVariant(theme, colorVariant)};

    border-color: ${({ theme, colorVariant }) =>
      getIndicatorColorVariant(theme, colorVariant)};
  }

  @media (hover: hover) {
    &:hover span {
      opacity: 1;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.25;
      cursor: default;
      pointer-events: none;
    `};

  ${({ denied }) =>
    denied &&
    css`
      & input:checked ~ ${CheckboxIndicator} {
        background-color: ${({ theme }) => theme.colors.red};
        border-color: ${({ theme }) => theme.colors.red};
      }
    `}
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Checkbox = ({
  id,
  name,
  label,
  value,
  checked,
  disabled,
  indeterminate,
  inputProps,
  onChange,
  required,
  readOnly,
  colorVariant,
  onLabelClick,
  denied
}) => {
  const textRef = useRef(null)
  const [allowTooltip, setAllowTooltip] = useState(false)

  useEffect(() => {
    if (
      !allowTooltip &&
      textRef?.current?.offsetWidth < textRef?.current?.scrollWidth
    ) {
      setAllowTooltip(true)
    }
  }, [textRef?.current, label])

  return (
    <CheckboxWrapper
      isDisabled={disabled}
      colorVariant={colorVariant}
      denied={denied}
    >
      <CheckboxInput
        id={id}
        name={name}
        type='checkbox'
        value={value}
        checked={checked}
        disabled={disabled}
        ref={(el) => el && (el.indeterminate = indeterminate)}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        {...inputProps}
      />
      <CheckboxIndicator colorVariant={colorVariant} denied={denied} />
      <TooltipContextConsumer>
        {({ showTooltip }) => (
          <CheckboxLabel
            ref={textRef}
            onMouseEnter={(event) => {
              if (allowTooltip) {
                showTooltip(true, label, event.clientX, event.clientY)
              }
            }}
            onMouseLeave={() => showTooltip(false)}
            onClick={onLabelClick}
          >
            {label}
          </CheckboxLabel>
        )}
      </TooltipContextConsumer>
    </CheckboxWrapper>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  colorVariant: PropTypes.oneOf([
    '',
    'primary',
    'danger',
    'warning',
    'success',
    'info'
  ]),
  onLabelClick: PropTypes.func,
  denied: PropTypes.bool
}

Checkbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  checked: false,
  disabled: false,
  indeterminate: false,
  inputProps: null,
  onChange: () => {},
  required: false,
  readOnly: false,
  colorVariant: 'primary',
  onLabelClick: () => {},
  denied: false
}
