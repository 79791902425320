import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const getVariant = (theme, variant) => {
  switch (variant) {
    case 'success':
      return css`
        background-color: ${theme.colors.green};
        color: ${theme.colors.white};
      `

    case 'danger':
      return css`
        background-color: ${theme.colors.red};
        color: ${theme.colors.white};
      `

    case 'info':
      return css`
        background-color: ${theme.colors.blue};
        color: ${theme.colors.white};
      `

    default:
      return ''
  }
}

const ToastStyled = styled.div`
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  width: 282px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  white-space: pre-line;

  ${({ theme, variant }) => getVariant(theme, variant)};
`

export const Toast = ({ text, variant }) => (
  <ToastStyled variant={variant}>{text}</ToastStyled>
)

Toast.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['info', 'success', 'danger'])
}

Toast.defaultProps = {
  text: '',
  variant: 'info'
}
