import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { rgba } from 'polished'
import { Icon } from '../Icon'

const FileInputWrapper = styled.div`
  display: ${({ fullWidth }) => (fullWidth ? 'block' : 'inline-block')};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`

const FileInputLabel = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const FileInputDropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '224px')};
  min-height: 80px;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  border: 1px dashed ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
  cursor: pointer;
  transition: border-color 0.16s linear;

  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
    }
  }
`

const FileInputDropzonePlaceholder = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
`

const FileInputComment = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 6px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const FileItemError = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

const FileList = styled.div`
  padding: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '224px')};
`

const FileItem = styled.div`
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const FileItemInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  padding: 8px 0;
  font-size: 14px;
  line-height: 16px;

  ${({ hasError, theme }) =>
    hasError &&
    css`
      color: ${theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red};
    `}

  & i {
    margin-right: 6px;
  }
`

const FileItemName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.secondary};
`

const iconStyle = css`
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const FileItemClearBtn = styled.button`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: auto;
  background: none;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.16s linear;

  &:before {
    content: '\\e913';

    ${iconStyle};
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const FileListMoreBtn = styled.button`
  display: inline-block;
  margin-top: 8px;
  padding: 0;
  background: none;
  border: none;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  transition: color 0.16s linear;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const FilePreview = styled.div`
  display: flex;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '258px')};
  height: ${({ height }) => (height ? `${height}px` : '146px')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 48px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(13, 22, 35, 0) 0%,
      rgba(13, 22, 35, 0.4) 50%,
      #0d1623 100%
    );
    opacity: 0;
    transition: opacity 0.16s linear;
  }

  &:hover:after {
    opacity: 0.5;
  }

  & + & {
    margin-top: 16px;
  }
`

const FilePreviewDelete = styled.button`
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  bottom: 8px;
  z-index: 2;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.16s linear;

  &:before {
    content: '\\e941';

    ${iconStyle};
  }

  *:hover > & {
    opacity: 1;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

export const FileInput = ({
  onChange,
  label,
  addMoreLabel,
  placeholder,
  comment,
  withPreview,
  disabled,
  multiple,
  validTypes,
  validSize,
  errorLabels,
  fullWidth,
  height
}) => {
  const fileInputRef = useRef()

  const [selectedFiles, setSelectedFiles] = useState([])
  const [validFiles, setValidFiles] = useState([])

  useEffect(() => {
    const filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name)

      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])

    setValidFiles([...filteredArr])
    onChange([...filteredArr])

    fileInputRef.current.value = null
  }, [selectedFiles])

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const fileDrop = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length) {
      handleFiles(files)
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
    }
  }

  const fileInputClicked = () => {
    fileInputRef.current.click()
  }

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      const isValidType = validateFile(files[i])
      const isValidSize = validateSize(files[i])

      if (isValidType && isValidSize) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]])
      } else {
        files[i].invalid = true

        setSelectedFiles((prevArray) => [...prevArray, files[i]])

        if (!isValidType)
          files[i].error = errorLabels.typeError || 'File type not permitted'

        if (!isValidSize)
          files[i].error = errorLabels.sizeError || 'File is too large'
      }
    }
  }

  const validateFile = (file) => {
    if (!!validTypes.length && validTypes.indexOf(file.type) === -1) {
      return false
    }

    return true
  }

  const validateSize = (file) => {
    if (validSize && file.size / 1000 > validSize) {
      return false
    }

    return true
  }

  const getPreviewUrl = (data) => {
    return URL.createObjectURL(data)
  }

  const getIcon = (file) => {
    const fileType = file.type || ''
    const fileName = file.name || ''
    const ext =
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || ''

    if (fileType.includes('image/')) return 'file-image'

    if (fileType.includes('video/')) return 'file-video'

    switch (ext) {
      case 'txt':
        return 'file-txt'

      case 'xls':
      case 'xlsx':
        return 'file-excel'

      case 'doc':
      case 'docx':
        return 'file-word'

      case 'ppt':
      case 'pptx':
        return 'file-chart'

      case 'csv':
        return 'file-csv'

      case 'pdf':
        return 'file-pdf'

      default:
        return 'file'
    }
  }

  const handleRemove = (name) => {
    const index = validFiles.findIndex((e) => e.name === name)
    const index2 = selectedFiles.findIndex((e) => e.name === name)

    validFiles.splice(index, 1)
    selectedFiles.splice(index2, 1)

    setValidFiles([...validFiles])
    setSelectedFiles([...selectedFiles])
  }

  return (
    <FileInputWrapper isDisabled={disabled} fullWidth={fullWidth}>
      <input
        type='file'
        ref={fileInputRef}
        multiple={multiple}
        onChange={filesSelected}
        disabled={disabled}
      />

      {label && <FileInputLabel>{label}</FileInputLabel>}

      {!validFiles.length && (
        <div>
          <FileInputDropzone
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
            fullWidth={fullWidth}
            height={height}
          >
            {placeholder && (
              <FileInputDropzonePlaceholder>
                {placeholder}
              </FileInputDropzonePlaceholder>
            )}
          </FileInputDropzone>
          {comment && <FileInputComment>{comment}</FileInputComment>}
        </div>
      )}

      {!withPreview && !!validFiles.length && (
        <FileList fullWidth={fullWidth}>
          {validFiles.map((data, i) => (
            <FileItem key={i}>
              <FileItemInner hasError={data.invalid}>
                <Icon icon={getIcon(data)} size={16} />
                <FileItemName>{data.name}</FileItemName>
                <FileItemClearBtn
                  type='button'
                  onClick={() => handleRemove(data.name)}
                />
              </FileItemInner>
              {data.invalid && <FileItemError>{data.error}</FileItemError>}
            </FileItem>
          ))}
          {multiple && (
            <FileListMoreBtn type='button' onClick={fileInputClicked}>
              {addMoreLabel}
            </FileListMoreBtn>
          )}
        </FileList>
      )}

      {withPreview &&
        validFiles.map((data, i) =>
          data.invalid ? (
            <FileItem key={i}>
              <FileItemInner hasError={data.invalid}>
                <Icon icon={getIcon(data)} size={16} />
                <FileItemName>{data.name}</FileItemName>
                <FileItemClearBtn
                  type='button'
                  onClick={() => handleRemove(data.name)}
                />
              </FileItemInner>
              {data.invalid && <FileItemError>{data.error}</FileItemError>}
            </FileItem>
          ) : (
            <FilePreview
              key={i}
              style={{ backgroundImage: `url('${getPreviewUrl(data)}')` }}
              fullWidth={fullWidth}
              height={height}
            >
              <FilePreviewDelete
                type='button'
                onClick={() => handleRemove(data.name)}
              />
            </FilePreview>
          )
        )}

      {multiple && !!validFiles.length && withPreview && (
        <FileListMoreBtn type='button' onClick={fileInputClicked}>
          {addMoreLabel}
        </FileListMoreBtn>
      )}
    </FileInputWrapper>
  )
}

FileInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  addMoreLabel: PropTypes.string,
  placeholder: PropTypes.string,
  comment: PropTypes.string,
  withPreview: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  validTypes: PropTypes.arrayOf(PropTypes.string),
  validSize: PropTypes.number,
  errorLabels: PropTypes.shape({
    typeError: PropTypes.string,
    sizeError: PropTypes.string
  }),
  fullWidth: PropTypes.bool,
  height: PropTypes.number
}

FileInput.defaultProps = {
  onChange: () => {},
  label: '',
  addMoreLabel: 'Add more...',
  placeholder: '',
  comment: '',
  withPreview: false,
  disabled: false,
  multiple: false,
  validTypes: [],
  validSize: undefined,
  errorLabels: {},
  fullWidth: false,
  height: null
}
