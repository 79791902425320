import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const PromptWrapper = styled.div`
  position: fixed;
  top: 24px;
  left: 50%;
  z-index: 99;
  width: 480px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  border-radius: 8px;
  margin-left: -240px;
  padding: 16px 40px 20px 24px;
`

const PromptCloseBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 12px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.blackText};
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.16s linear;

  &:before {
    content: '\\e913';

    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }

    &:focus,
    &:active {
      opacity: 0.75;
      outline: none;
    }
  }
`

const PromptText = styled.div`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
`

const PromptFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

const FooterBtn = styled.button`
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: opacity 0.16s linear;

  @media (hover: hover) {
    &:hover,
    &:focus,
    &:active {
      opacity: 0.75;
      outline: none;
    }
  }
`

const getConfirmVariant = (theme, variant) => {
  switch (variant) {
    case 'primary':
      return {
        color: theme.colors.blue
      }

    case 'danger':
      return {
        color: theme.colors.red
      }
  }
}

const PromptConfirmBtn = styled(FooterBtn)`
  ${({ theme, confirmVariant }) => getConfirmVariant(theme, confirmVariant)};
`

const PromptCancelBtn = styled(FooterBtn)`
  margin-left: 24px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
`

export const Prompt = ({
  text,
  confirmLabel,
  confirmVariant,
  cancelLabel,
  onConfirm,
  onCancel,
  closeHandler
}) => (
  <PromptWrapper>
    <PromptCloseBtn type='button' onClick={closeHandler}></PromptCloseBtn>
    <PromptText>{text}</PromptText>
    <PromptFooter>
      <PromptConfirmBtn
        type='button'
        onClick={onConfirm}
        confirmVariant={confirmVariant}
      >
        {confirmLabel}
      </PromptConfirmBtn>
      <PromptCancelBtn type='button' onClick={onCancel}>
        {cancelLabel}
      </PromptCancelBtn>
    </PromptFooter>
  </PromptWrapper>
)

Prompt.propTypes = {
  text: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmVariant: PropTypes.oneOf(['primary', 'danger']),
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  closeHandler: PropTypes.func
}

Prompt.defaultProps = {
  text: '',
  confirmLabel: 'Accept',
  confirmVariant: 'primary',
  cancelLabel: 'Cancel',
  onConfirm: () => {},
  onCancel: () => {},
  closeHandler: () => {}
}
