import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

import TimeField from 'react-simple-timefield'

const TimeInputWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      width: ${({ width }) => width || '168'}px;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`

const TimeInputInner = styled.div`
  position: relative;
`

const TimeInputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`

const TimeInputControll = styled.input`
  min-width: 1%;
  width: 100%;
  min-height: 32px;
  background-color: ${({ theme, colorVariant }) =>
    colorVariant === 'dark' ? theme.colors.primary3 : theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.colors.red : 'transparent')};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 16px;
  padding: 6px 12px;
  margin: 0;
  appearance: none;
  resize: none;
  -moz-appearance: textfield;
  transition: background-color 0.16s linear, border-color 0.16s linear;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &::placeholder {
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blueAlt};
    outline: none;
  }
`

const TimeInputError = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
`

export const TimeInput = ({
  id,
  name,
  value,
  label,
  inputProps,
  onChange,
  autoFocus,
  disabled,
  error,
  width,
  fullWidth,
  readOnly,
  required,
  isInline,
  colorVariant
}) => {
  const inputRef = useRef(null)

  return (
    <TimeInputWrapper
      width={width}
      fullWidth={fullWidth}
      isInline={isInline}
      isDisabled={disabled}
    >
      {label && <TimeInputLabel>{label}</TimeInputLabel>}
      <TimeInputInner>
        <TimeField
          value={value}
          onChange={onChange}
          input={
            <TimeInputControll
              id={id}
              name={name}
              type='text'
              autoFocus={autoFocus}
              required={required}
              colorVariant={colorVariant}
              disabled={disabled}
              readOnly={readOnly}
              hasError={error}
              ref={inputRef}
              {...inputProps}
            />
          }
          inputRef={(ref) => inputRef}
          colon=':'
        />
      </TimeInputInner>
      {error && <TimeInputError>{error}</TimeInputError>}
    </TimeInputWrapper>
  )
}

TimeInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isInline: PropTypes.bool,
  colorVariant: PropTypes.oneOf(['light', 'dark'])
}

TimeInput.defaultProps = {
  id: '',
  name: '',
  value: '',
  label: '',
  inputProps: null,
  onChange: () => {},
  autoFocus: false,
  disabled: false,
  error: '',
  width: null,
  fullWidth: false,
  readOnly: false,
  required: false,
  isInline: false,
  colorVariant: 'dark'
}
