import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const getColorVariant = (theme, colorVariant) => {
  switch (colorVariant) {
    case 'primary':
      return css`
        border-color: ${theme.colors.secondary};
        color: ${theme.colors.secondary};
      `

    case 'primary-fill':
      return css`
        background-color: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};
        color: ${theme.colors.primary1};
      `

    case 'info':
      return css`
        border-color: ${theme.colors.blueAlt};
        color: ${theme.colors.blueAlt};
      `

    case 'info-fill':
      return css`
        background-color: ${theme.colors.blueAlt};
        border-color: ${theme.colors.blueAlt};
        color: ${theme.colors.primary1};
      `

    case 'success':
      return css`
        border-color: ${theme.colors.green};
        color: ${theme.colors.green};
      `

    case 'success-fill':
      return css`
        background-color: ${theme.colors.green};
        border-color: ${theme.colors.green};
        color: ${theme.colors.primary1};
      `

    case 'warning':
      return css`
        border-color: ${theme.colors.yellow};
        color: ${theme.colors.yellow};
      `

    case 'warning-fill':
      return css`
        background-color: ${theme.colors.yellow};
        border-color: ${theme.colors.yellow};
        color: ${theme.colors.primary1};
      `

    case 'danger':
      return css`
        border-color: ${theme.colors.red};
        color: ${theme.colors.red};
      `

    case 'danger-fill':
      return css`
        background-color: ${theme.colors.red};
        border-color: ${theme.colors.red};
        color: ${theme.colors.primary1};
      `
  }
}

const TagStyled = styled.div`
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  padding: 0 6px;
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  ${({ theme, colorVariant }) => getColorVariant(theme, colorVariant)};

  ${({ isMuted }) =>
    isMuted &&
    css`
      opacity: 0.5;
    `}
`

export const Tag = ({ label, colorVariant, isMuted, ...rest }) => (
  <TagStyled colorVariant={colorVariant} isMuted={isMuted} {...rest}>
    {label}
  </TagStyled>
)

Tag.propTypes = {
  label: PropTypes.string,
  colorVariant: PropTypes.oneOf([
    'primary',
    'primary-fill',
    'info',
    'info-fill',
    'success',
    'success-fill',
    'warning',
    'warning-fill',
    'danger',
    'danger-fill'
  ]),
  isMuted: PropTypes.bool
}

Tag.defaultProps = {
  label: '',
  colorVariant: 'primary',
  isMuted: false
}

const getToggleColorVariant = (theme, colorVariant, isActive) => {
  switch (colorVariant) {
    case 'primary':
      return css`
        background-color: ${({ isActive }) =>
          isActive ? theme.colors.secondary : 'transparent'};
        border-color: ${theme.colors.secondary};
        color: ${({ isActive }) =>
          isActive ? theme.colors.primary1 : theme.colors.secondary};
      `

    case 'info':
      return css`
        background-color: ${({ isActive }) =>
          isActive ? theme.colors.blueAlt : 'transparent'};
        border-color: ${theme.colors.blueAlt};
        color: ${({ isActive }) =>
          isActive ? theme.colors.primary1 : theme.colors.blueAlt};
      `

    case 'success':
      return css`
        background-color: ${({ isActive }) =>
          isActive ? theme.colors.green : 'transparent'};
        border-color: ${theme.colors.green};
        color: ${({ isActive }) =>
          isActive ? theme.colors.primary1 : theme.colors.green};
      `

    case 'warning':
      return css`
        background-color: ${({ isActive }) =>
          isActive ? theme.colors.yellow : 'transparent'};
        border-color: ${theme.colors.yellow};
        color: ${({ isActive }) =>
          isActive ? theme.colors.primary1 : theme.colors.yellow};
      `

    case 'danger':
      return css`
        background-color: ${({ isActive }) =>
          isActive ? theme.colors.red : 'transparent'};
        border-color: ${theme.colors.red};
        color: ${({ isActive }) =>
          isActive ? theme.colors.primary1 : theme.colors.red};
      `
  }
}

const TagToggleStyled = styled.button`
  display: inline-block;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 0 6px;
  margin: 0;
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;

  ${({ theme, colorVariant, isActive }) =>
    getToggleColorVariant(theme, colorVariant, isActive)};

  transition: border-color 0.16s linear, color 0.16s linear,
    opacity 0.16s linear;

  @media (hover: hover) {
    &:hover {
      opacity: 0.75;
    }
  }

  ${({ isMuted }) =>
    isMuted &&
    css`
      opacity: 0.5;
    `}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:active,
  &:focus {
    outline: none;
  }
`

export const TagToggle = ({
  label,
  colorVariant,
  isActive,
  isMuted,
  ...rest
}) => (
  <TagToggleStyled
    colorVariant={colorVariant}
    isActive={isActive}
    isMuted={isMuted}
    {...rest}
  >
    {label}
  </TagToggleStyled>
)

TagToggle.propTypes = {
  label: PropTypes.string,
  colorVariant: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger'
  ]),
  isActive: PropTypes.bool,
  isMuted: PropTypes.bool
}

TagToggle.defaultProps = {
  label: '',
  colorVariant: 'info',
  isActive: false,
  isMuted: false
}

const TagGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px;

  & ${TagStyled}, & ${TagToggleStyled} {
    margin: 4px;
  }
`

export const TagGroup = ({ children }) => (
  <TagGroupWrapper>{children}</TagGroupWrapper>
)

TagGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

TagGroup.defaultProps = {
  children: null
}
