import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'

const PaginationWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.25;
      pointer-events: none;
    `}
`

const PaginationNav = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
  transition: opacity 0.16s linear, color 0.16s linear;

  &:disabled {
    opacity: 0.25;
    cursor: default;
  }

  @media (hover: hover) {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.secondary};
      outline: none;
    }
  }
`

const PaginationList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 8px;
  padding: 0;
  list-style: none;
`

const PaginationItem = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 8px;
  border: none;
  background-color: ${({ isCurrent, theme }) =>
    isCurrent ? rgba(theme.colors.secondary, 0.1) : 'transparent'};
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.secondary : rgba(theme.colors.secondary, 0.75)};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  transition: background-color 0.16s linear, color 0.16s linear;

  @media (hover: hover) {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.secondary};
      outline: none;
    }
  }
`

export const Pagination = ({ page, count, offset, isDisabled, onChange }) => {
  const getCount = (count) => {
    return count >= 5 ? 5 : count
  }

  return (
    <PaginationWrapper isDisabled={isDisabled}>
      <PaginationNav disabled={page === 1} onClick={() => onChange(page - 1)}>
        <Icon icon='arrow-double-left' />
      </PaginationNav>

      <PaginationList>
        {Array.from(Array(getCount(count)).keys()).map((e, i) => (
          <li key={i}>
            <PaginationItem
              isCurrent={page === offset + i + 1}
              onClick={() => onChange(offset + i + 1)}
            >
              {offset + i + 1}
            </PaginationItem>
          </li>
        ))}
      </PaginationList>

      <PaginationNav
        disabled={page === count}
        onClick={() => onChange(page + 1)}
      >
        <Icon icon='arrow-double-right' />
      </PaginationNav>
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  /**
   Current page number
  */
  page: PropTypes.number,
  /**
   Total number of pages.
  */
  count: PropTypes.number,
  /**
   Pagination offset. I.e. set offset = 10 when showing pages 10...15.
  */
  offset: PropTypes.number,
  isDisabled: PropTypes.bool,
  /**
   Page change handler. Returns clicked page number.
  */
  onChange: PropTypes.func
}

Pagination.defaultProps = {
  page: 1,
  count: 5,
  offset: 0,
  isDisabled: false,
  onChange: () => {}
}
