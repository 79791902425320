import React, { useState } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import PropTypes from 'prop-types'

const TooltipWrapper = styled.span`
  position: relative;
  cursor: default;
`

const TooltipRoot = styled.div`
  display: flex;
  position: absolute;
  z-index: 9999;
  transform: translateY(0);
  min-width: 190px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s linear, transform 0.1s linear,
    visibility 0s linear 0.1s;

  ${({ verticalAlignment }) => getVerticalPosition(verticalAlignment)};

  ${({ horizontalAlignment }) => getHorizontalPosition(horizontalAlignment)};

  ${({ isOpen, verticalAlignment }) => isOpen && openState(verticalAlignment)};
`

const openState = (verticalAlignment) => {
  return `
    visibility: visible;
    opacity: 1;
    transform: translateY(${verticalAlignment === 'top' ? '-8px' : '8px'});
    transition: opacity 0.1s linear, transform 0.1s linear,
      visibility 0s linear 0s;
  `
}

const getVerticalPosition = (verticalAlignment) => {
  switch (verticalAlignment) {
    case 'top':
      return `
        bottom: 100%;
      `

    case 'bottom':
      return `
        top: 100%;
      `
  }
}

const getHorizontalPosition = (horizontalAlignment) => {
  switch (horizontalAlignment) {
    case 'left':
      return `
        left: 0;

        justify-content: flex-start;
      `

    case 'right':
      return `
        right: 0;
        justify-content: flex-end;
      `

    case 'center':
      return `
        right: 50%;
        left: 50%;
        margin-left: -100px;
        justify-content: center;
      `
  }
}

const TooltipInner = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 12px rgba(13, 22, 35, 0.1);
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => rgba(theme.colors.blackText, 0.75)};
  padding: 6px 8px;
  max-width: 350px;
  white-space: pre-line;
  word-wrap: break-word;
`

export const Tooltip = ({
  children,
  tooltip,
  verticalAlignment,
  horizontalAlignment
}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <TooltipWrapper
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {children}
      <TooltipRoot
        isOpen={isOpen}
        verticalAlignment={verticalAlignment}
        horizontalAlignment={horizontalAlignment}
      >
        <TooltipInner>{tooltip}</TooltipInner>
      </TooltipRoot>
    </TooltipWrapper>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'right', 'center'])
}

Tooltip.defaultProps = {
  children: null,
  tooltip: '',
  verticalAlignment: 'top',
  horizontalAlignment: 'center'
}
